import { useEffect, useState } from "react";
import { DASHBOARD_URLS } from "../../../api/Admin/Dashboard";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { SmartSoftSelect } from "soft_digi";
import {
  changeDateTimeZone,
  changeDateTimeZoneFormat,
} from "../../../services/core/CommonService";

const MarketSlots = () => {
  const [tabData, setTabData] = useState(null);
  const { setLoading } = useSiteContext();
  const [sector, setSector] = useState({ value: "1", label: "Top Ranking" });
  const [dated, setDate] = useState(new Date());

  const loadTableData = (mode) => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setTabData([]);
      setLoading(false);
    };
    const url = DASHBOARD_URLS.GET_ADMIN_MARKET_SLOT;
    const formated_date_test = changeDateTimeZone(dated);
    let year_check = formated_date_test.split("-")?.[2];
    let month_check = formated_date_test.split("-")?.[1];
    console.log("fomrated date ", formated_date_test);
    // const year=1
    const subscription = post(
      url,
      { year: year_check, month: month_check,mode:mode },
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData({ value: "1", label: "Top Ranking" });
  }, [dated]);

  const handleSectorChange = (value) => {
    loadTableData(value)
    // Add any other logic here
  };

  const tileContent = ({ date, view, activeStartDate }) => {
    if (view === "month") {
      const formated_date = changeDateTimeZoneFormat(date, "YYYY-MM-DD");

      //const today = new Date();
      if (date.getMonth() !== activeStartDate.getMonth()) {
        return <div className="title-content date-disabled"></div>;
      } else if (tabData[formated_date]) {
        let class_name = "title-content date-" + tabData[formated_date];
        return (
          <div className={class_name}>
            <span>{date.getDate()}</span>
          </div>
        );
      } else {
        return (
          <div className="title-content">
            <span>{date.getDate()}</span>
          </div>
        );
      }
    }
  };
  const options = [
    { value: "1", label: "Top Ranking" },
    { value: "2", label: "Promos" },
  ];

  const getMonthYearDisplay = () => {
    const formated_date_test = changeDateTimeZoneFormat(dated, "YYYY-MMMM");
    let year_check = formated_date_test.split("-")?.[0];
    let month_check = formated_date_test.split("-")?.[1];
    return month_check + " - " + year_check;
  };

  const topDisplay = () => {
    return (
      <div className="columns mt-5 is-multiline">
        <div className="column is-6">
          <SmartSoftSelect
            options={options}
            // onChange={(value) => setSector(value)}
            onChange={(value) => {
              setSector(value);  // Update the sector state
              handleSectorChange(value);
            }}
            value={sector}
            // label="Sector"
            inputType="BORDER_LABEL"
          />
        </div>
        <div className="column is-size-3 is-6 has-text-right admin-market-offer-table">
          <i
            className="fa fa-arrow-circle-o-left mr-3 pointer"
            aria-hidden="true"
            onClick={prevMonth}
          ></i>
          <i
            className="fa fa-arrow-circle-o-right ml-3 pointer"
            aria-hidden="true"
            onClick={nextMonth}
          ></i>
        </div>
        <div className="column is-12 has-text-centered p-0">
         <span className="market-slot-text">{getMonthYearDisplay()}</span> 
        </div>
      </div>
    );
  };

  const nextMonth = () => {
    setDate(new Date(dated.getFullYear(), dated.getMonth() + 1, 1));
  };

  const prevMonth = () => {
    setDate(new Date(dated.getFullYear(), dated.getMonth() - 1, 1));
  };

  return (
    <>
      {topDisplay()}
      {tabData && (
        <div className="market-calender">
          <Calendar
            onChange={setDate}
            value={dated}
            tileContent={tileContent}
            locale="en-US"
            calendarType="gregory"
            showNavigation={false}
          />
        </div>
      )}
    </>
  );
};

export default MarketSlots;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { ADMIN_OPERATIONS_SECTOR } from "../../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";
import SectorForm from "./SectorForm";
// import { PROFILE_PIC } from "../../../../services/ImageService";
import SmartImageDisplay from "../../../../components/site/FormElements/SmartImageDisplay";

const SectorTable = () => {
  const navigate = useNavigate();

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const [rowData, setRowData] = useState({});
  // this is api to the table data
  const loadTableData = () => {
    setLoading(true, "Loading.... Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setTabData([]);
      setLoading(false);
    };
    const subscription = get(
      ADMIN_OPERATIONS_SECTOR.GET_ALL,
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      //console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const edit_buttons = [
    {
      label: "",
      leftIcon: "fa-pencil",
      type: "icon",
      classList: ["smart-edit-icon"],
      onClick: (data) => {
        viewEditForm(data["ID"]);
      },
    },
  ];
  const delete_buttons = [
    {
      label: "",
      leftIcon: "fa-trash",
      type: "icon",
      classList: ["smart-delete-icon"],
      onClick: (data) => {
        openDeleteModal(data["ID"]);
        //  viewEditData(data["ID"],"VIEW");
      },
    },
  ];

  const deleteData = (id) => {
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = post(
      ADMIN_OPERATIONS_SECTOR.DELETE,
      { id: id },
      handleError
    ).subscribe((response) => {
      showAlertAutoClose("Deleted Successfully...", "success");
      closeModal();
      loadTableData();
      // setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const openDeleteModal = (id, name) => {
    let modelObject = {
      title: "Sector Deletion!",
      body: "Do you wish to delete this sector? This action cannot be reverted",
      okFunction: () => {
        deleteData(id);
      },
      cancelFunction: () => {
        closeModal();
        // console.log("cancel function")
      },
    };
    openModal(modelObject);
  };

  const viewEditForm = (id, action_name) => {
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = post(
      ADMIN_OPERATIONS_SECTOR.GET_ONE,
      { id: id },
      handleError
    ).subscribe((response) => {
      // console.log("response ", response);
      setRowData(response.data);
      // openNewForm(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const purchase_image = (data) => {
    let url = ADMIN_OPERATIONS_SECTOR.GET_IMAGE_PURCHASE;
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <SmartImageDisplay
        srcType="URL"
        url={url}
        id={id}
        imageClass="is-24x24 mx-3"
      />
    );
  };

  const redeem_image = (data) => {
    let url = ADMIN_OPERATIONS_SECTOR.GET_IMAGE_REDEEM;
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <SmartImageDisplay
        srcType="URL"
        url={url}
        id={id}
        imageClass="is-24x24 mx-3"
      />
    );
  };

  const columns = [
    {
      title: "EARN ICON",
      index: "icon_earn",
      // width: "20",
      valueFunction: purchase_image,
    },
    {
      title: "REDEMPTION ICON",
      index: "icon_redeem",
      // width: "20",
      valueFunction: redeem_image,
    },
    // {
    //   title: "PICKUP & DELIVERY ",
    //   index: "pickup_delivery",
    //   width: "20",
    // },
    // {
    //   title: "OPERATING HOURS",
    //   index: "operating_hours",
    //   width: "20",
    // },
    {
      title: "SECTOR",
      index: "sector_name",
      //  width: "25"
    },
    {
      title: "GST (%)",
      index: "gst_sector",
      //  width:"40"
    },
    { title: "EDIT", index: "edit", type: "buttons", buttons: edit_buttons },
    {
      title: "DELETE",
      index: "delete",
      type: "buttons",
      buttons: delete_buttons,
    },
  ];
  // const data = [];

  return (
    <>
      <div className="smart-crop-admin-container">
        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="columns is-vcentered">
                <div className="column is-1 crop-admin-table-back">
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="column is-10 crop-admin-table-title">
                  Sector
                </div>
              </div>

              {/* <div className="columns ">
              <div className="column has-text-centered">Sector</div>
              </div> */}
              <div className="columns">
                <div className="column SectorTable-border-removed is-7">
                  <SmartSoftTable
                    columns={columns}
                    data={tabData}
                    tableProps={{ className: "crop-table-layout" }}
                  />
                </div>
                <div className="column is-5 holiday-update-form crop-admin-holiday-form">
                  <SectorForm
                    loadTableData={loadTableData}
                    dataIn={rowData}
                    key={rowData?.ID}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectorTable;

import { useState } from "react";

const SmartAccordion = (props) => {
  const { data, class_name = "",icons= {open:"fa-angle-down",close:"fa-angle-right"} } = props;
  const [open, setOpen] = useState(0);

  const singleAccordDisplay = (item,index) => {
    return (
      <>
        {cardHeader(item,index)}
        {open ===index && <div className="card-content">
          <div className="content">{cardBody(item)}</div>
        </div>
       }
      </>
    );
  };

  const cardBody = (item) => {
    if (item.bodyFunction) {
      return item.bodyFunction instanceof Function
        ? item.bodyFunction()
        : item.bodyFunction;
    } else {
      return item?.body;
    }
  };

  const tabClick=(index)=>{
    if(index===open){
      setOpen(null);
    }else{
      setOpen(index)
    }
  }

  const getIcon=(index)=>{
    if(index===open){
       return icons?.open
    }else{
      return icons?.close;
    }
  }

  const cardHeader = (item,index) => {
    return (
      <header className="card-header" onClick={()=>tabClick(index)}>
        <p className="card-header-title is-clickable">{item?.title}</p>
        <button className="card-header-icon" aria-label="more options">
          <span className="icon">
            <i className={"fa " + getIcon(index) } aria-hidden="true" onClick={()=>tabClick(index)}></i>
          </span>
        </button>
      </header>
    );
  };

  return data.map((item, index) => (
    <div className={"card " + class_name} key={index}>
      {singleAccordDisplay(item,index)}
    </div>
  ));
};

export default SmartAccordion;

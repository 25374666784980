import {
  // Router,
  BrowserRouter as Route,
  // Link,
  Routes,
} from "react-router-dom";
// import CropDetails from '../../pages/Admin/AccountManagement/CropDetails';
import {
  CropsTable,
  //  InvoiceDetails,
  TransactionDetails,
} from "../../pages/Admin";
import InvoiceTable from "../../pages/Admin/Database/InvoiceTable";
import BusinessCROPsDetails from "./../../pages/Admin/AccountManagement/BusinessCROPsDetails";

const AccountManagementRoute = () => {
  return (
    <Routes>
      <Route path="/crops-table/:id" element={<CropsTable />} />
      <Route path="/transaction-list" element={<TransactionDetails />} />
      <Route path="/invoice-table/:id" element={<InvoiceTable />} />
      <Route path="/businesscropsdetails" element={<BusinessCROPsDetails />} />
    </Routes>
  );
};

export default AccountManagementRoute;

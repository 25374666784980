import { Link, useLocation, useNavigate } from "react-router-dom";
import { SmartSoftButton } from "soft_digi";
import { ADMIN_USER_LOGO } from "../../services/ImageService";
// import { ADMIN_PROFILE_URLS } from "../../api/Admin/AurhUrls";

import { SERVICES_NOTIFICATION_URLS } from "../../api/Services/ServiceUrls";
import SmartImageDisplay from "../../components/site/FormElements/SmartImageDisplay";
import Toggle from "../../components/site/Toggle";
import { useSiteContext } from "../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../services/notifyService";
import { post } from "../../services/smartApiService";
import AdminNotifications from "./AdminNotifications";

const AdminHeader = ({
  handleLogout,
  toggleSidebar,
  setIsDark,
  isDark,
  user,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading, refreshUser } = useSiteContext();
  const { pathname } = location;
  // let img_url = ADMIN_PROFILE_URLS.GET_PROFILE_IMAGE;
  const getTitle = () => {
    if (pathname.includes("/operations")) {
      return "Operations Management";
    } else if (pathname.includes("/dashboard")) {
      return "Dashboard";
    } else if (pathname.includes("/account-settings")) {
      return "Profile";
    } else if (pathname.includes("/notification")) {
      return "Notifications";
    } else if (pathname.includes("/account-management")) {
      return "Account Management";
    } else if (pathname.includes("/database")) {
      return "Database Management";
    } else if (pathname.includes("/commercial")) {
      return "Commercials";
    } else if (pathname.includes("/marcom")) {
      return "Marcom";
    } else {
      return "";
    }
  };
  const handleLogoutAdmin = () => {
    navigate("/");
    handleLogout();
  };
  //account-management
  // admin/database  account-settings

  const handleFormSubmit = () => {
    //console.log("formdata", formData);
    // setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      // showNotification("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Updating....Please Wait");
    let url = SERVICES_NOTIFICATION_URLS.MARK_ALL_READ;
    const subscription = post(url, {type:"ADMIN"}, handleError).subscribe((response) => {
      showAlertAutoClose(response.data.msg, "success");
      setLoading(false);
      //loadProfileData();
      refreshUser();
      // navigate("/");
    });
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };

  const NotificationButton = () => {
    let total =
      user?.notification && user?.notification?.total
        ? user?.notification?.total
        : 0;
    // const viewprofile = () => {
    //   const viewprofiledata = [
    //     {
    //       title: "Congratulation Flora!",
    //       titletwo: "Won the monthly Best Seiler badge",
    //     },
    //     {
    //       title: "New user registered",
    //       titletwo: "5 hours ago",
    //     },
    //     {
    //       title: "New Request/Complaint received",
    //       titletwo: "you have 10 unread messages",
    //     },
    //     {
    //       title: "Stripe",
    //       titletwo: "Received Payment",
    //     },
    //   ];
    //   return (
    //     <>
    //       <div className="columns ">
    //         <div className="column ">
    //           <div className="card ">
    //             {viewprofiledata.map((item,index) => (
    //               <div  className="nknnknk has-text-left is-flex">
    //                 <span className="has-text-left ">
    //                   <img
    //                     className="NotificationButton-viewprofile-image has-text-left m-2"
    //                     src={PROFILE_PIC}
    //                     alt=""
    //                   />
    //                 </span>
    //                 <span className="has-text-weight-medium mt-2 has-text-left">
    //                   <p key={item.title} className="is-size-6">{item.title}</p>

    //                   <p key={index} className="is-size-7">{item.titletwo}</p>
    //                 </span>
    //               </div>
    //             ))}
    //             ;
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   );
    // };

    return (
      <>
        <div className="column  crop-AdminHeader-alarm-icon dropdown is-clickable is-hoverable is-right">
          <span className="admin-bell-icon">
            <i className="fa fa-bell is-size-2 mt-1" aria-hidden="true"></i>
            {/* <span className="notification-bade">{user?.notification?.total}</span> */}
          </span>
          {/* <span className="notification-bade"></span> */}
          <div className="dropdown-menu">
            <div className="dropdown-content pt-0">
          
              <div className="is-flex is-justify-content-space-between  AdminHeader-NotificationButton-header-content p-0">
                <p className="dropdown-item AdminHeader-NotificationButton-notification pl-5 pt-0 has-text-weight-medium is-size-4 ">
                  Notification
                </p>
                <button className="AdminHeader-NotificationButton-right-button  is-rounded pl-4 pr-4  mr-3 mt-2 mb-2">
                  {total}
                </button>
              </div>
              <div className="bottom-border"></div>
              <div className="dropdown-item p-0"><AdminNotifications noteData={user?.notification} /></div>
              {total > 0 && <div className="has-text-centered">
                <SmartSoftButton
                  label="Read All Notification"
                  classList={[
                    "smart-crop-admin-action-button",
                    "px-6 ",
                    "mt-3",
                    "mb-5",
                  ]}
                  onClick={() => handleFormSubmit()}
                />
              </div>
              }
              {total < 1 && <div className="has-text-centered">
                <p className="is-size-6">No Notifications</p>
              </div>
              }
            </div>
          </div>
        </div>
      </>
    );
  };

  const HeaderProfile = () => {
    return (
      <>
        <div className="column p-0 dropdown is-clickable is-hoverable is-right">
          {/* <SmartImageDisplay
                        srcType="URL_GET"
                        url={img_url}
                        imageClass="is-64x64 is-rounded"
                        default_img={ADMIN_USER_LOGO}
              
              <fi/> */}
          <figure className="image is-64x64 Admin-User-image-rounded ">
            <SmartImageDisplay
              srcType="DATA"
              // url={img_url}
              data={user?.profile_image}
              imageClass="is-64x64 is-rounded"
              default_img={ADMIN_USER_LOGO}
            />
            {/* <img
              className="is-rounded is-64x64"
              src={ADMIN_USER_LOGO}
              alt=""
            /> */}
            {/* <SmartImageDisplay
                        srcType="DATA"
                       // url={img_url}
                        data={user?.profile_image}
                        imageClass="is-64x64 is-rounded"
                        default_img={ADMIN_USER_LOGO}
                      /> */}
          </figure>

          {/*         
          <img
            src={BUSSINESS_HEAD_TOP_RIGHT_lOGO}
            alt="Business Head Top Right Logo"
          /> */}
          <div className=" dropdown-menu profile-drop-down">
            <header className="AdminHeader-NotificationButton-header-content">
              <p className=" has-text-centered has-text-weight-medium is-size-4 ">
                Profile
              </p>
            </header>
            <div className=" dropdown-content pb-3">
              <div className="dropdown-item">
                <div className="">
                  <div className="columns">
                    <div className="column is-flex">
                      <span className="has-text-left">
                        <SmartImageDisplay
                          srcType="DATA"
                          // url={img_url}
                          data={user?.profile_image}
                          imageClass="is-64x64 is-rounded Admin-User-image-rounded-two"
                          default_img={ADMIN_USER_LOGO}
                        />
                        {/* <img
                          className="NotificationButton-viewprofile-image has-text-left ml-3 mt-3"
                          src={PROFILE_PIC}
                          alt=""
                        /> */}
                      </span>
                      <span className="has-text-weight-medium mt-2 has-text-left">
                        <p className="is-size-6 ml-3">{user?.ename}</p>
                        <p className="is-size-7 ml-3">Admin</p>
                      </span>
                    </div>
                  </div>
                </div>
                <Link to="/account-settings/account">
                  <p
                    className="smart-crop-action-button my-4 p-2 has-text-centered"
                    //  onClick={() => navigateLink("home/my-profile-form")}
                  >
                    <i
                      className="fa fa-user-circle-o mr-1"
                      aria-hidden="true"
                    ></i>
                    Profile
                  </p>
                </Link>

                {/* <Link to=""> */}
                <div
                  className="smart-crop-action-button my-4 p-2 has-text-centered"
                  onClick={handleLogoutAdmin}
                >
                  <i className="fa fa-sign-out mr-1" aria-hidden="true"></i>
                  Logout
                </div>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="columns smart-head-nav-crop is-vcentered">
      <div className="column is-8 is-flex">
        <div
          onClick={() => toggleSidebar()}
          className="smart-header-icon-admin mt-1"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
        <span className="crop-admin-header  ml-5">{getTitle()}</span>
      </div>
      <div className="column is-4 has-text-right pr-6 is-flex is-justify-content-space-between is-align-content-center">
        <span className="crop-admin-alarm-icon pointer"></span>
        <Toggle isChecked={isDark} handleChange={setIsDark} />
        <span className="crop-admin-alarm-icon mt-5">
          {/* {NotificationButton()} */}
          <AdminNotifications noteData={user?.notification} />
          
          
        </span>

        <span className="crop-admin-profile-image">{HeaderProfile()}</span>
      </div>
    </div>
  );
};

export default AdminHeader;

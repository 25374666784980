import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { DATABASE_URLS, INVOICE_URLS } from "../../../api/Admin/AurhUrls";
import NoResultComponents from "../../../components/site/NoResultComponents";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { downloadPDF } from "../../../services/core/FileService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { decrypt_data } from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";

const InvoiceTable = () => {
  const { id } = useParams();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  // const [category, setCategory] = useState("2");
  const [tabData, setTabData] = useState([]);
  const { setLoading, } = useSiteContext();
  const navigate = useNavigate();

  const baseNav = "/database";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const loadTableData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = {
      id: decrypted_id,
      type: 'BUSINESS',
    };
    let url = DATABASE_URLS.GET_ALL_ORDERS;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        // console.log(response.data);
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const downloadReport = (id) => {
    // console.log("id = " , id);
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { id: id };
    const subscription = post(
      INVOICE_URLS.GET_ORDER_INVOICE,
      post_data,
      handleError
    ).subscribe((response) => {
      // console.log(response);
      if (response.data.content) {
        downloadPDF(response.data.content, "invoice.pdf");
      }
      // setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };


  const viweline_button = [
    {
      label: " ",
      type: "icon",
      classList: ["smart-edit-icon"],
      leftIcon: "fa fa-file-text ",
    },
  ];
  const downloadline_button = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-file-text ",
      classList: ["smart-delete-icon"],
      onClick: (data) => {
        downloadReport(data["ID"]);
        // console.log("Download" , data)
      }
    },
  ];
  const dateValue = (row) => {
    let value = row.complaint_action_date
    let formatValue = row.complaint_action_date != null ? moment(value).format('DD-MM-YYYY') : '-'
    return (
      <div className="">
        {formatValue}
      </div>
    );
  };

  const columns = [
    {
      title: "", index: "", width:"5",
    },
    {
      title: "DATE", index: "order_date",
      dateFormat: "DD-MM-YYYY", type: "date",
      valueFunction: dateValue,
    },
    //{ title: "OFFER", index: "offerline" },
    // { title: "PRICE", index: "priceline" },
    //{ title: "QUANTITY", index: "quantityline" },
    { title: "ORDER NUMBER", index: "order_id" },
    { title: "ORDER TYPE", index: "order_type" },
    // { title: "Invoice Number", index: "invoice_no" },
    { title: "TOTAL", index: "total_price" },
    { title: "CROPs", index: "order_crops" },   
    {
      title: "DOWNLOAD",
      index: "downloadline",
      type: "buttons",
      buttons: downloadline_button,
    },
    // { title: "ORDER NUMBER", index: "order_no" },
    // { title: "INVOICE NUMBER", index: "invoceline" },
  ];

  const MyOfferSwitch = () => {
    // const [formData, setFormData] = useState({});
    // const Customer_Details = [{ value: "1", label: "Customer Order Details" }];
    // const Customer_Purchase_Details = [
    //   { value: "2", label: "Customer CROPs Details" },
    // ];

    // const handleInputChange = (name, value) => {
    //   setFormData((prev) => ({ ...prev, [name]: value }));
    // };
    return (
      <>
        <div className="is-flex">
          {/* <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_Details}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true, }}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div> */}
          {/* <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Customer_Purchase_Details}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true, }}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div> */}
          <div>Orders</div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="smart-crop-admin-container">
        <div key={"InvoiceTablecolumnsone"} className="columns">
          <div key={"InvoiceTablecolumnone"} className="column">
            <div key={"InvoiceTablecardone"} className="card">
              <div
                key={"InvoiceTablecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"InvoiceTablecolumntwo"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span
                    className="icon"
                    onClick={() => navigateLink("database_management-list")}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"InvoiceTablecolumnthree"}
                  className="column is-8 crop-admin-table-title"
                >
                  {MyOfferSwitch()}
                </div>

                <div
                  key={"InvoiceTablecolumnfour"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                  <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={tabData}
                tableProps={{ className: "crop-table-layout" }}
              />
              {tabData&&tabData.length<1&&( <NoResultComponents title={"No Results Found"}  />)} 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;

const SERVICES_REQUEST_URLS = {
    INSERT:"/services/request/insert",
    GET_ALL_ADMIN:"/services/request/get_all/admin",
    GET_ALL_USER:"/services/request/get_all/user",
    UPDATE:"/services/request/update",
    GET_ONE:"/services/request/get_one",
}

const SERVICES_COMPLAINT_URLS = {
    INSERT:"/services/complain/insert",
    GET_ALL_ADMIN:"/services/complain/get_all/admin",
    GET_ALL_USER:"/services/complain/get_all_user",
    UPDATE:"/services/complain/update",
    GET_ONE:"/services/complain/get_one",
}
const SERVICES_NOTIFICATION_URLS = {
    GET_ALL:"/services/notification/get_all", 
    MARK_ALL_READ:"/services/notification/update_by_user_id",  
}

export {
    SERVICES_COMPLAINT_URLS,
    SERVICES_REQUEST_URLS,
    SERVICES_NOTIFICATION_URLS
}
import { useEffect, useState } from "react";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { ADMIN_OPERATIONS_LOYALTY } from "../../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { CROP_ADMIN_LOYALITY } from "../../../../services/ImageService";
import {
  showAlertAutoClose,
 
} from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";


import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";

const LoyalityForm = ({ loadTableData, dataIn }) => {
  // console.log("Data popup:" + dataIn);
  const [formData, setFormData] = useState(dataIn ? dataIn : {});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  // const [roles, setRoles] = useState([]);
  //const [type, setType] = useState("password");
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData,LoyaltyElement)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setLoading(false);
    };
    setLoading(true, "Details Submitting....Please Wait");
    let url = ADMIN_OPERATIONS_LOYALTY.INSERT;
    if (formData.ID !== undefined) {
      formData["id"] = formData.ID;
      url = ADMIN_OPERATIONS_LOYALTY.UPDATE;
    }

    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        //console.log("response form ", response.data);
        loadTableData();
        showAlertAutoClose("Data Saved Successfully", "success");
        closeModal();
        // setUser(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
const UpdatepopupFormValidations = {
  Loyaltyname: [SmartValid.required("Loyalty Name is Required")],
};
  useEffect(() => {}, []);
  
    const LoyaltyElement = [
      {
        type: "TEXT_BOX",
        width: "12",
        name: "loyalty_name",
        element: {
          label: "Loyalty Name",
          validations: UpdatepopupFormValidations.Loyaltyname,
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
        
        },
      },
    ];
  return (
    <>
      <div key={"LoyalityFormFirstcolumns"} className="columns">
        <div
          key={"LoyalityFormFirstcolumn"}
          className=" column is-10 card crop-admin-modal-content"
        >
          <div
            key={"LoyalityFormSecondcolumns"}
            className="columns is-multiline"
          >
            <div className="column is-12 has-text-centered" key="1">
              <p className="crop-admin-form-title">
                {" "}
                {dataIn && dataIn.ID !== undefined
                  ? "Update Loyalty"
                  : "Add Loyalty"}{" "}
              </p>
            </div>
            <div className="column is-12 crop-admin-form" key="2">
              {/* <SmartSoftInput
                label="Loyalty Name"
                value={formData?.loyalty_name || ""}
                inputType="BORDER_LABEL"
                placeHolder="Emirates Skiward"
                onChange={(value) => handleInputChange("loyalty_name", value)}
                errorEnable={formSubmit}
                pattern={ALLOW_ALPHABET}
                max={255}
                errorUpdate={(value) => handleErrorChange("euserid", value)}
                validations={UpdatepopupFormValidations.Loyaltyname}
              /> */}
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={LoyaltyElement}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
            </div>
            <div
              key={"LoyalityFormSecondcolumn"}
              className="column is-8 is-offset-2 has-text-centered"
            >
              <SmartSoftButton
                key="save"
                label={dataIn && dataIn.ID !== undefined ? "UPDATE" : "SAVE"}
                // leftIcon="fa-save"
                classList={["is-rounded crop-admin-action-button"]}
                onClick={handleSubmit}
              />
            </div>
            <div
              key={"LoyalityFormThirdcolumn"}
              className="column is-12 has-text-centered"
            >
              <img src={CROP_ADMIN_LOYALITY} alt="" />
            </div>
          </div>
        </div>
        <div key={"LoyalityFormFourthcolumn"} className="column is-2">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoyalityForm;

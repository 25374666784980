import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftCheckRadioSwitch, SmartSoftTable } from "soft_digi";
import { INVOICE_URLS, USER_URLS } from "../../../api/Admin/AurhUrls";
import { ADMIN_OPERATIONS_LOYALTY } from "../../../api/Admin/OperationsUrl";
import SmartImageDisplay from "../../../components/site/FormElements/SmartImageDisplay";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { C_PIC } from "../../../services/ImageService";
import {
  showAlertAutoClose,
} from "../../../services/notifyService";
import { encrypt_data } from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";

const datatest = [
  {
    customerline: "Prodeep Kumar Swain",
    cropidline: "1000004",
    cropsline: "1085.20",
  },
  {
    customerline: " Kumar ",
    cropidline: "1000004",
    cropsline: "1085.20",
  },
  {
    customerline: " Arun ",
    cropidline: "1000004",
    cropsline: "1085.20",
  },
];

const TransactionDetails = () => {
  const [tab, setTab] = useState("CROP");
  const [category, setCategory] = useState("2");
  const [data, setTabData] = useState(datatest);
  const { setLoading } = useSiteContext();
  const navigate = useNavigate();
  // these are the tabs for the component

  const tabs = [
    { index: "CROP", label: "CROP" },
    //  { index: "PROP", label: "PROP" },
    // { index: "INVOICE", label: "INVOICE" },
  ];

  const handleTabs = (index) => {
    setTab(index);
  };

  const baseNav = "/account-management";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const get_url = () => {
    switch (tab) {
      case "CROP":
        return USER_URLS.GET_ALL_ADMIN;
      case "PROP":
        return ADMIN_OPERATIONS_LOYALTY.GET_ALL;
      case "INVOICE":
        return INVOICE_URLS.GET_ALL;
      default:
        return ADMIN_OPERATIONS_LOYALTY.GET_ALL;
    }
  };

  // this is api to the table data
  const loadTableData = () => {
    //setLoading(true, "Loading....Please Wait.");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      // setTabData([]);
      setLoading(false);
    };
    let post_data = { category: category };
    const subscription = post(get_url(), post_data, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        //console.table("testing", response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [category, tab]);

  const MyOfferSwitch = () => {
    const Customer_Data = [{ value: "1", label: "Customer Transaction" }];
    const Business_Data = [{ value: "2", label: "Business Transaction" }];
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_Data}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true }}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_Data}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true }}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };

  const nameFunction = (row) => {
    let url = USER_URLS.GET_USER_IMAGE;
    return (
      <div className="is-flex">
        {/* <figure className="image is-32x32 mr-2 ">
          <img className="is-rounded" src={PROFILE_PIC} alt="" />
        </figure> */}
        <SmartImageDisplay
          srcType="URL"
          url={url}
          id={row.ID}
          imageClass="is-24x24"
        />
        {row["user_name"] || ""}
      </div>
    );
  };

  const tagImage = (row) => {
    return (
      <>
        <div key={"TransactionDetailsFirstcolumns"} className="columns">
          <div key={"TransactionDetailsFirstcolumn"} className="column is-6">
            <div
              className="TransactionDetails_Crops_icon_round is-flex "
              // onClick={() => navigateLink("crop-details")}
              onClick={() => {
                let id =
                  row.ID !== undefined
                    ? encodeURIComponent(encrypt_data("" + row.ID))
                    : "";
                navigateLink("crops-table/" + id);
              }}
            >
              <span className="imagerouded pt-1">
                <img src={C_PIC} height="20" width="20" alt=""></img>
              </span>
              {row["crop_balance"]}
            </div>
          </div>
        </div>
      </>
    );
  };

  const tabs_display = () => {
    return (
      <div className="tabs is-boxed is-medium is-8 crop-top-tabs">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={tab === item.index ? "is-active" : ""}
              >
                
                  <span className="pr-6 pl-6">{item.label}</span>
                
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const crop_columns = [
    {
      title: "CUSTOMER NAME",
      index: "customerline",
      valueFunction: nameFunction,
    },
    { title: "CROP ID", index: "crop_id" },
    { title: "CROP", index: "cropsline", valueFunction: tagImage },
  ];

  const prop_columns = [
    {
      title: "CUSTOMER NAME",
      index: "customerline",
      valueFunction: nameFunction,
    },
    { title: "PROP ID", index: "cropidline" },
    { title: "PROPs", index: "cropsline", valueFunction: tagImage },
  ];

  const invoice_line_button = [
    {
      label: "",
      leftIcon: "fa fa-file",
      type: "icon",
      classList: ["smart-delete-icon"],
      onClick: (data) => {
        // navigateLink("invoice-details");
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("crops-table/" + id);
      },
    },
  ];

  const invoice_columns = [
    {
      title: "CUSTOMER NAME",
      index: "customerline",
      valueFunction: nameFunction,
      width: "40",
    },
    {
      title: "INVOICE",
      index: "invoice_line",
      type: "buttons",
      buttons: invoice_line_button,
    },
  ];

  const get_table_columns = () => {
    switch (tab) {
      case "CROP":
        return crop_columns;
      case "PROP":
        return prop_columns;
      case "INVOICE":
        return invoice_columns;
      default:
        return crop_columns;
    }
  };
  return (
    <div className="TransactionDetails">
      <div key={"TransactionDetailsSecondcolumns"} className="columns">
        <div key={"TransactionDetailsSecondcolumn"} className="column">
          <div className="card">
            <div
              key={"TransactionDetailsThirdcolumns"}
              className="columns is-vcentered"
            >
              <div
                key={"TransactionDetailsThirdcolumn"}
                className="column is-9 ml-6 crop-admin-table-title"
              >
                {MyOfferSwitch()}
              </div>
              <div
                key={"TransactionDetailsFourthcolumn"}
                className="column is-3 has-text-right crop-table-top-pagination"
              >
                {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                <i className="fa fa-caret-right" aria-hidden="true"></i> */}
              </div>
            </div>
            <div key={"TransactionDetailsFourthcolumns"} className="columns">
              <div key={"TransactionDetailsFifthcolumn"} className="column ">
                {tabs_display()}
              </div>
            </div>
            <div key={"TransactionDetailsFifthcolumns"} className="columns">
              <div
                key={"TransactionDetailsSixthcolumn"}
                className="column mt-0 pt-0"
              >
                <SmartSoftTable
                  columns={get_table_columns()}
                  data={data}
                  tableProps={{ className: "crop-table-layout" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;

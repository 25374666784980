import { useEffect, useState } from "react";
import { DASHBOARD_URLS } from "../../../api/Admin/Dashboard";
import { useSiteContext } from "../../../contexts/SiteProvider";
import {
  ADMIN_CENT,
} from "../../../services/ImageService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { get } from "../../../services/smartApiService";

const Balance = () => {
  const [tabData, setTabData] = useState([]);
  const { setLoading } = useSiteContext();

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait.");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error", );
      setTabData([]);
      setLoading(false);
    };
    const url = DASHBOARD_URLS.GET_ADMIN_COUNTS;
    // const year = 1;
    const subscription = get(
      url,

      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  return (
    <div
      
      className="columns p-3 is-multiline smart-crop-dashboard-container card-details-Blaance ml-1"
    >
      <div
        key={"BalanceFirstColumn"}
        className="column is-12 dashboard-user-name p-0 m-0"
      >
        Balance
      </div>
      <div key={"BalanceSecondColumn"} className="column is-12">
        <div key={"BalanceSecondColumns"} className="columns is-multiline">
          <div key={"BalanceThirdColumn"} className="column is-6">
            <div className="dashboard-crop-balance-card">
              <div className=" ml-2 admin-dashboard-header  is-size-6">
                Total CROPs Credit
              </div>
              <div className="is-flex">
                <div className="dashboard-user-name ml-2 is-size-4 mt-1 ">
                  {/* <i className="fa fa-product-hunt" aria-hidden="true"></i> */}
                  <img
                    className="Balance-image-image mt-1"
                    src={ADMIN_CENT}
                    alt=""
                  />
                </div>
                <div className="admin-crop-card-number ml-4 mt-2">
                  {tabData?.total_crops_credit}
                </div>
              </div>
            </div>
          </div>
          <div key={"BalanceFourthColumn"} className="column is-6">
            <div className="dashboard-crop-balance-card">
              <div className=" ml-2 admin-dashboard-header is-size-6">
                Total CROPs Debit
              </div>
              <div className="is-flex">
                <div className="dashboard-user-name ml-2 is-size-4 mt-1">
                  {/* <i className="fa fa-product-hunt" aria-hidden="true"></i> */}
                  <img
                    className="Balance-image-image mt-1"
                    src={ADMIN_CENT}
                    alt=""
                  />
                </div>
                <div className="admin-crop-card-number-debit ml-4 mt-2">
                  {tabData?.total_crops_debit}
                </div>
              </div>
            </div>
          </div>
          <div key={"BalanceFifthColumn"} className="column is-12 mb-4">
            {/* <div className="dashboard-crop-balance-card">
              <div className=" ml-2 has-text-weight-bold">
                Total PROPs Credit
              </div>
              <div className="is-flex">
                <div className="dashboard-user-name ml-2 is-size-4 mt-1">
                  <i class="fa fa-product-hunt" aria-hidden="true"></i>
                </div>
                <div className="admin-crop-card-number ml-4 mt-2">
                  176756.86
                </div>
              </div>
            </div> */}
          </div>
          <div key={"BalanceSixthColumn"} className="column is-12  mb-5">
            {/* <div className="dashboard-crop-balance-card">
              <div className=" ml-2 has-text-weight-bold">
                Total PROPs Debit
              </div>
              <div className="is-flex">
                <div className="dashboard-user-name ml-2 is-size-4 mt-1">
                  <i class="fa fa-product-hunt" aria-hidden="true"></i>
                </div>
                <div className="admin-crop-card-number-debit ml-4 mt-2">
                  176756.86
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;


const NoResultComponents = ({title,}) => {
  return (
   <>
   <div className='no-result'>
    <div className='is-size-6 has-text-weight-bold'>{title}</div>
    <div> 
    {/* <i className={icon} aria-hidden="true"></i>  */}
    </div>
  
   </div>
   </>
  )
}

export default NoResultComponents
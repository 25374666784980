import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { ADMIN_PAYMENT } from "../../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import {
  encrypt_data,
  setSessionStorage,
} from "../../../../services/sessionService";
import { post } from "../../../../services/smartApiService";
import UpdatePopup from "./UpdatePopup";
import { changeDateTimeZoneFormat } from "../../../../services/core/CommonService";

const PaymentTable = () => {
  const [tabData, setTabData] = useState({ table: [] });
  const [week, setWeek] = useState(0);
  const navigate = useNavigate();
  const basenav = "/operations";
  const { setLoading } = useSiteContext();
  // const currentDate = new Date();
  const currentDate = new Date().toISOString().split('T')[0];
  const [isDisabled, setIsDisabled] = useState(false);
  const navigateLink = (index) => {
    navigate(basenav + "/" + index);
  };

  const { openModal } = useSiteContext();
  useEffect(() => {
    loadTableData();
  }, []);
  const loadTableData = (flag = null) => {  
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setTabData({ table: [] });
      setLoading(false);
    };
    let post_data = {};
    if (flag != null) {
      if (flag == "L") post_data = { sdate: tabData.start_date };
      else post_data = { sdate: tabData.end_date };
    }
    setSessionStorage("CROP_ADMIN_PAYMENT_SDATE", "");
    setSessionStorage("CROP_ADMIN_PAYMENT_EDATE", "");
    setSessionStorage("CROP_ADMIN_PAYMENT_weekData","");
    const subscription = post(
      ADMIN_PAYMENT.GET_CROP_BALANCE,
      post_data,
      handleError
    ).subscribe((response) => {
      //console.log(response)
      setTabData(response.data);
      const endDateValue = changeDateTimeZoneFormat( currentDate,"YYYY-MM-DD")
      setIsDisabled(response.data.end_date > currentDate);
      console.log(endDateValue);
      console.log(currentDate)
      // console.table("testing", tabData.start_date);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  const openNewForm = (data = {}) => {
    console.log("open form data", data);
    let dataIn = {
      sd_mt_userdb_id: data.ID,
      week_date: tabData.start_date,
    };
    let modalObject = {
      body: <UpdatePopup loadTableData={loadTableData} dataIn={dataIn} />,
      modalClass: "crop-admin-modal smart-modal-50",
      // title:"Update",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const viewButtons = [
    {
      label: "View",
      leftIcon: "",
      type: "",
      classList: [
        "button admin-crop-presuspended-status is-rounded is-small m-0",
      ],
      onClick: (data) => {
        console.log("data", data);
        let weekData = {
          start_date: tabData.start_date,
          end_date: tabData.end_date,
        };
        setSessionStorage("CROP_ADMIN_PAYMENT_SDATE", tabData.start_date);
        setSessionStorage("CROP_ADMIN_PAYMENT_EDATE", tabData.end_date);
        setSessionStorage("CROP_ADMIN_PAYMENT_weekData", weekData);
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("payment-history-list/" + id);
      },
      // onclick: ()=>{navigateLink("payment-history-list")},
    },
  ];

  const updateButton = [
    {
      label: "Update",
      leftIcon: "",
      type: "",
      classList: [
        "button admin-crop-presuspended-Update-Update is-rounded is-small m-0",
      ],
      onClick: (data) => {
        openNewForm(data);
      },
    },
  ];

  const updateButtonFunction = (data) => {
    const label = data.status == 0 ? "update" : "In Progress";
    return data.status != 10 ? (
      <button
        className="button admin-crop-presuspended-Update-Update is-rounded is-small m-0"
        onClick={() => {
          openNewForm(data);
        }}
      >
        {label}
      </button>
    ) : (
      <span>Paid</span>
    );
  };

  const nameFunction = (row) => {
    return "A$ " + row?.total;
  };

  const displayAmount = (amount) => {
    // console.log("amount",amount.service_charge)
    return Math.round(amount.service_charge * 100) / 100;
  };

  const columns = [
    { title: "", index: "", width: "3" },
    { title: "BUSINESS NAME", index: "business_name", },
    { title: "TOTAL (A$)", index: "total_aud", width: "10"  },
    { title: "GST (A$)", index: "gst_aud",width: "10" },
    { title: "NET AMOUNT (A$)", index: "total_amount", width: "10"  },
    {
      title: "SERVICE CHARGES (A$)",
      index: "service_charge",
      
      valueFunction: displayAmount,
      width: "10" 
    },
    {
      title: "TO PAY",
      index: "total",
      valueFunction: nameFunction,
      
      isSortable: true,
      width: "10" 
    },
    {
      title: "VIEW",
      index: "view",
      type: "buttons",
      
      buttons: viewButtons,
    },

    {
      title: "ACTION",
      index: "update",
      // type: "buttons",
      
      valueFunction: updateButtonFunction,
    },
    { title: "REMARKS", index: "remarks",  },
  ];

  const data = [
    { businessname: "Arrium", amount: "AUD 0.00" },
    { businessname: "Billabong", amount: "AUD 0.00" },
  ];

  const MyWeekDays = () => {
    const options = { month: "short", day: "numeric" };
    // Parse the dates
    const start = new Date(tabData.start_date);
    const end = new Date(tabData.end_date);

    // Format the dates
    const formattedStartDate = start.toLocaleDateString("en-US", options);
    const formattedEndDate = end.toLocaleDateString("en-US", options);

    return (
      <div>
        <h2 className="is-size-7 mt-1">
          {formattedStartDate} - {formattedEndDate}
        </h2>
      </div>
    );
  };
  const nextWeek = () => {
    loadTableData("R");
  };

  const prevWeek = () => {
    // setWeek(-1)
    loadTableData("L");
  };

  // const isDisabled = tabData.end_date && tabData.end_date > currentDate;

  return (
    <>
      <div className="smart-crop-admin-container">
        <div key={"PaymentTablecolumnsone"} className="columns">
          <div key={"PaymentTablecolumnone"} className="column ">
            <div key={"PaymentTablecardone"} className="card">
              <div
                key={"PaymentTablecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"PaymentTablecolumntwo"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"PaymentTablecolumnthree"}
                  className="column is-8 crop-admin-table-title"
                >
                  Payment
                </div>

                <div
                  key={"PaymentTablecolumnfour"}
                  className="column is-3 crop-table-top-pagination is-flex has-text is-justify-content-end"
                >
                  <i
                    className="fa fa-caret-left mr-3 is-clickable"
                    aria-hidden="true"
                    onClick={prevWeek}
                  ></i>
                  <span className="crop-admin-table-title">{MyWeekDays()}</span>
                  <i
                    // className="fa fa-caret-right ml-3 is-clickable"
                    className={`fa fa-caret-right ml-3 ${isDisabled ? "disabled-class" : "is-clickable"}`}
                    aria-hidden="true"
                    onClick={!isDisabled ? nextWeek : null}
                    style={{ pointerEvents: isDisabled ? "none" : "auto", opacity: isDisabled ? 0.5 : 1 }}
                    // onClick={nextWeek}
                  ></i>
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={tabData?.table}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentTable;

const ADMIN_LOGIN_URLS = {
    LOGIN:"auth/admin_login",
    LOGIN_BUSINESS:"auth/business_login",
    REFRESH:"auth/refresh",
    HEALTH_CHECK:"auth/health_check"
    
}
const ADMIN_PROFILE_URLS={
    GET_PROFILE_IMAGE:"/user/get_user_image",
    GET_PROFILE_IMAGE_ID:"/user/get_user_image_id",
    UPDATE_PROFILE:"user/update_admin_profile"
}

const BUSINESS_LOGIN_URLS = {
    SEND_OTP:"auth/send_otp",
    SEND_OTP_FORGOT:"auth/send_otp_forgot",
    VERIFY_BUSINESS_DETAILS:"auth/verify_business",
    RESET_PASSWORD_OTP:"auth/reset_pass_otp",
    SEND_ADMIN_OTP: "auth/send_otp_admin_forgot"
}

const USER_URLS = {
    INSERT_BUSINESS:"user/insert_business",
    GET_ONE_PROFILE:"user/get_one_profile",
    GET_ALL_ADMIN:"user/get_all",
    UPDATE_STATUS:"user/update",
    CHANGE_PASSWORD:"/user/change_password",
    GET_ALL_SELECT_BUSINESS:"/user/get_all_select_business",
    GET_USER_IMAGE:"user/get_user_image_id",
    GET_ALL_SELECT_STATES:"/user/get_all_select_states",
}

const DATABASE_URLS={
    GET_ALL_LIKES:"business/likes/get_all_user",
   // GET_ALL_RATING:"business/ratings/get_all_offer",
    GET_ALL_RATING:"business/ratings/get_all_user",
    GET_ALL_AUDIT:"user/customer_audit/get_all",
    GET_ALL_INVOICE:"/business/invoice/get_all_invoice_user",
    GET_ALL_PROFILE:"user/get_one",
    GET_ALL_CROPS:"user/get_all_crops",
    GET_ALL_USER:"/business/ratings/get_all_user",
    GET_ALL_ORDERS:"/business/orders_items/get_all_admin",
    GET_ALL_STATEMENT:"/services/user/get_all_user",

}

const INVOICE_URLS={
    GET_ALL:'/business/invoice/get_all_invoice',
    GET_ORDER_INVOICE:"/business/invoice/get_order_invoice"
}

export {
    ADMIN_LOGIN_URLS,
    BUSINESS_LOGIN_URLS,
    USER_URLS,
    ADMIN_PROFILE_URLS,
    DATABASE_URLS,
    INVOICE_URLS
}
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftButton, SmartSoftInput } from "soft_digi";

const CropManagementForm = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  // const { setLoading, closeModal } = useSiteContext();

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  // const handleErrorChange = (name, value) => {
  //   setFormErrors((prev) => {
  //     // Create a copy of the previous state
  //     const updatedFormData = { ...prev };
  //     // Check if the value is null or empty
  //     if (value === null || value === "") {
  //       // If true, remove the property from the updated state
  //       delete updatedFormData[name];
  //     } else {
  //       // Otherwise, update or add the property in the updated state
  //       updatedFormData[name] = value;
  //     }

  //     return updatedFormData;
  //   });
  // };

  // const numericValidations = [
  //   {
  //     type: "required",
  //     msg: "Please enter correct value",
  //   },
  // ];

  const handleFormSubmit = () => {
    // console.log("formdata", formData);
    setFormSubmit(true);
    // console.log("form errors ", formErrors);
  };

  const Crops = () => {
    return (
      <>
        <div className="column is-12 crop-admin-form ml-6">
          <div className="column is-6 card p-0">
            <div className="smart-crop-admin-holiday-card pl-5">CROP</div>

            <div className="is-flex is-justify-content-space-around mt-5">
              <div>1 CROP(default)</div>
              <SmartSoftInput
                label="AUD"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
            </div>

            <div className="is-flex is-justify-content-space-around mt-5">
              <div>1 CROP(purchase)</div>
              <SmartSoftInput
                label="AUD"
                value={formData?.state_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("state_name", value)}
                errorEnable={formSubmit}
              />
            </div>

            <div className=" has-text-centered mt-3">
              <SmartSoftButton
                classList={["smart-crop-admin-action-button"]}
                label="UPDATE"
                onClick={handleFormSubmit}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const Milestonedata = () => {
    return (
      <>
        <div className="column is-12 crop-admin-form mx-0 my-0">
          <div className="column is-12 card p-0 mt-3 mr-6 smart-holiday-update-card">
            <div className="smart-crop-admin-holiday-card pl-5">
              Milestone data
            </div>

            <div className="is-flex is-justify-content-space-around mt-6 ">
              <div>CROP 5k</div>
              <SmartSoftInput
                label="BLUE"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
              <SmartSoftInput
                label="SILVER"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
              <SmartSoftInput
                label="GOLD"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />

              <SmartSoftInput
                label="PLATINUM"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
            </div>

            <div className="is-flex is-justify-content-space-around mt-6">
              <div>CROP 10k</div>
              <SmartSoftInput
                label="BLUE"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
              <SmartSoftInput
                label="SILVER"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
              <SmartSoftInput
                label="GOLD"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />

              <SmartSoftInput
                label="PLATINUM"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
            </div>

            <div className="is-flex is-justify-content-space-around mt-6">
              <div>CROP 25k</div>
              <SmartSoftInput
                label="BLUE"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
              <SmartSoftInput
                label="SILVER"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
              <SmartSoftInput
                label="GOLD"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />

              <SmartSoftInput
                label="PLATINUM"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
            </div>

            <div className="is-flex is-justify-content-space-around mt-6">
              <div>CROP 25k Plus</div>
              <SmartSoftInput
                label="BLUE"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
              <SmartSoftInput
                label="SILVER"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
              <SmartSoftInput
                label="GOLD"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />

              <SmartSoftInput
                label="PLATINUM"
                value={formData?.holiday_name || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("holiday_name", value)}
                errorEnable={formSubmit}
              />
            </div>

            <div className="ml-3 mt-4">
              <SmartSoftButton
                classList={["smart-crop-admin-action-button"]}
                label="UPDATE"
                onClick={handleFormSubmit}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const navigate = useNavigate();

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  return (
    <>
      <div className="smart-crop-admin-container">
        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="columns is-vcentered">
                <div className="column is-1 crop-admin-table-back">
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div>{Crops()}</div>
              {Milestonedata()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CropManagementForm;

import { USER_URLS } from "../../api/Admin/AurhUrls";
import { ADMIN_OPERATIONS_LOYALTY, ADMIN_OPERATIONS_SECTOR } from "../../api/Admin/OperationsUrl";
import { MANAGE_OFFER_URLS } from "../../api/Business/OffersUrls";
import { get, post } from "../smartApiService";

/**
 * 
 * @param url s
 * @param call_back 
 * @returns 
 */
const admin_generic_select=(url:string,call_back:any)=>{
  const handleError = (errorMessage:any) => {}; 
  const subscription =get(url, handleError).subscribe(
      (response) => {
          call_back(response.data);   
      }
    );
    return () => {
      subscription.unsubscribe();
    };
}
const admin_generic_select_post=(url:string,sector:any,call_back:any)=>{
  const handleError = (errorMessage:any) => {}; 
  const subscription =post(url,sector, handleError).subscribe(
      (response) => {
          call_back(response.data);   
      }
    );
    return () => {
      subscription.unsubscribe();
    };
}
/**
 * 
 * @param call_back 
 * @returns 
 */
const admin_sector_select=(call_back:any)=>{
    const handleError = (errorMessage:any) => {};   
    let url = ADMIN_OPERATIONS_SECTOR.GET_ALL_SELECT;
    const subscription =get(url, handleError).subscribe(
        (response) => {
            call_back(response.data);   
        }
      );
      return () => {
        subscription.unsubscribe();
      };
}

const admin_loyalty_select=(call_back:any)=>{
  let url = ADMIN_OPERATIONS_LOYALTY.GET_ALL_SELECT;
  admin_generic_select(url,call_back);
}

const admin_business_offer_select=(id:number,call_back:any)=>{
  const handleError = (errorMessage:any) => {};   
  let url = MANAGE_OFFER_URLS.GET_ALL_SELECT_WITH_USERID;
  const subscription =post(url,{id:id}, handleError).subscribe(
      (response) => {
          call_back(response.data);   
      }
    );
    return () => {
      subscription.unsubscribe();
    };
}

const admin_business_select=(call_back:any,sector:any)=>{
  console.log(sector)
  let url = USER_URLS.GET_ALL_SELECT_BUSINESS;
  admin_generic_select_post(url,sector,call_back);
}

const admin_states_select=(call_back:any)=>{
  let url = USER_URLS.GET_ALL_SELECT_STATES;
  admin_generic_select(url,call_back);
}


export {
  admin_business_offer_select,
  admin_business_select, admin_loyalty_select, admin_sector_select, admin_states_select
};

const DASHBOARD_URLS = {
    GET_COUNTS:"business/dash_board/get_count",
    GET_ADMIN_COUNTS:"admin/dash_count",
    GET_ADMIN_REVENUE:"admin/dash_revenue",
    GET_OFFER_TABLE : "/business/orders_items/admin_dashboard",
    GET_OFFERS:"/business/offers/offer_performance",
    GET_ADMIN_MARKET_SLOT:"admin/get_market_slots", 
    GET_TOTAL_SALES:"/admin/payment/get_total_sales",
    GET_REVENUE_CHAT:"/admin/payment/get_revenue_chat"
   
}

export {
    DASHBOARD_URLS
}
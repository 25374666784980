import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftCheckRadioSwitch, SmartSoftTable } from "soft_digi";
import { ADMIN_PROFILE_URLS, USER_URLS } from "../../../../api/Admin/AurhUrls";
import SmartImageDisplay from "../../../../components/site/FormElements/SmartImageDisplay";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { getSessionStorage, setSessionStorage } from "../../../../services/sessionService";
import {
  // get,
  post,
} from "../../../../services/smartApiService";
import Status from "./Status";

const ManagementService = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [tabData, setTabData] = useState([]);

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error", );
      setTabData([]);
      setLoading(false);
    };
    let post_data = {
      category: category,
    };
    const subscription = post(
      USER_URLS.GET_ALL_ADMIN,
      post_data,
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };



  useEffect(() => { 
    let categeory_session = getSessionStorage("crop_admin_operation_service");
    if(!categeory_session){
      categeory_session = "1";
    }   
     setCategory(categeory_session)
  }, []);



  useEffect(() => {
    if(category){
      loadTableData();
      setSessionStorage("crop_admin_operation_service",category)
    }       
  }, [category]);





  const MyOfferSwitch = () => {
    // const [formData, setFormData] = useState({});
    // const [formSubmit, setFormSubmit] = useState(false);
    // const [formErrors, setFormErrors] = useState(false);
    const Customer_services = [{ value: "1", label: "Customer Services" }];
    const Business_services = [{ value: "2", label: "Business Services" }];

    // const handleInputChange = (name, value) => {
    //   setFormData((prev) => ({ ...prev, [name]: value }));
    // };
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_services}
              switchMode={true}
              inputProps={{ isRounded: true }}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_services}
              switchMode={true}
              inputProps={{ isRounded: true }}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
        </div>
      </>
    );
  };

  const { setLoading, openModal,refreshUser } = useSiteContext();
  const openNewForm = (data = {}) => {
    // console.log(data);
    let modalObject = {
      body: <Status loadTableData={loadTableData} dataIn={data} refreshUser={refreshUser} />,
      modalClass: "crop-admin-modal smart-modal-50",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const buttons = [
    {
      label: "Change",
      classList: ["button admin-crop-suspended-status is-rounded is-small m-0"],
      onClick: (data) => {
        openNewForm(data);
      },
    },
  ];

  const nameTags = [
    {
      label: "SUSPENDED",
      value: "SUSPENDED",
      class: ["admin-crop-suspended-status is-rounded m-0"],
    },
    {
      label: "PRESUSPENDED",
      value: "PRESUSPENDED",
      class: ["admin-crop-presuspended-status is-rounded m-0"],
    },
    {
      label: "ACTIVE",
      value: "ACTIVE",
      class: ["admin-crop-active-status is-rounded m-0"],
    },
    {
      label: "DEACTIVATED",
      value: "DEACTIVATED",
      class: ["admin-crop-deactivated-status is-rounded m-0"],
    },
  ];

  const offer_image = (data) => {
    let url = ADMIN_PROFILE_URLS.GET_PROFILE_IMAGE_ID;
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <>
        <div className=" is-flex">
          <SmartImageDisplay
            srcType="URL"
            url={url}
            id={id}
            imageClass="is-24x24 is-rounded"
          />
          <div className="ml-1">{data?.first_name}</div>
        </div>
      </>
    );
  };

  const columns = [
    { title: "", index: "", width: "3" },
    {
      title: "NAME",
      index: "first_name",
      width: "25",
      valueFunction: offer_image,
    },
    { title: "CROP ID", index: "crop_id", class: ["m-0"], width: "22" },
    {
      title: "STATUS",
      index: "active_status",
      type: "tags",
      tags: nameTags,
      class: ["m-0"],
      width: "25",
    },
    // { title: "TIER", index: "user_name", class: ["m-0"] },
    {
      title: "CHANGE STATUS",
      index: "changestatus",
      type: "buttons",
      buttons: buttons,
      class: ["m-0"],
      width: "25",
    },
  ];

  // const TableData = [
  //   {
  //     name: "pradeep kumar",
  //     cropid: "100004",
  //     status: 5,
  //     tier: "Blue",
  //   },

  //   {
  //     name: "Gopinath jaya kumar",
  //     cropid: "100006",
  //     status: 10,
  //     tier: "silver",
  //   },

  //   {
  //     name: "Akshu Hema kumar",
  //     cropid: "100007",
  //     status: 5,
  //     tier: "Silver",
  //   },
  //   {
  //     name: "aisha kumar",
  //     cropid: "100008",
  //     status: 15,
  //     tier: "Silver ",
  //   },
  //   { name: "hema kumar", cropid: "100009", status: 15, tier: "Blue" },
  //   { name: "saban Raj k", cropid: "100010", status: 15, tier: "Blue" },
  // ];

  // //Business Table Data
  // const BusinessTableData = [
  //   {
  //     name: "Arrium",
  //     cropid: "BUS0000094",
  //     status: "active",
  //     tier: "B ",
  //   },
  // ];

  return (
    <>
      <div className="smart-crop-admin-container">
        <div key={"ManagementServicecolumnsone"} className="columns">
          <div key={"ManagementServicecolumntwo"} className="column">
            <div key={"ManagementServicecardone"} className="card">
              <div
                key={"ManagementServicecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"ManagementServicecolumnthree"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"ManagementServicecolumnfour"}
                  className="column is-8 crop-admin-table-title"
                >
                  {MyOfferSwitch()}
                </div>

                <div
                  key={"ManagementServicecolumnfive"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={tabData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagementService;

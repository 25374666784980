import { useEffect, useState } from "react";
import { SmartSoftButton, SmartSoftTable } from "soft_digi";
import { useSiteContext } from "../../../../contexts/SiteProvider";
// import LoyalityForm from "./InteresForm.jsx";
import { useNavigate } from "react-router-dom";
import { ADMIN_OPERATIONS_INTERESTS } from "../../../../api/Admin/OperationsUrl";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";
import InterestForm from "./InteresForm";

const InterestTable = () => {
  const navigate = useNavigate();

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  // this is api to the table data
  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");

    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setTabData([]);
      setLoading(false);
    };
    const subscription = get(
      ADMIN_OPERATIONS_INTERESTS.GET_ALL,
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const edit_buttons = [
    {
      label: "",
      leftIcon: "fa-pencil",
      type: "icon",
      classList: ["smart-edit-icon"],
      onClick: (data) => {
        viewEditForm(data["ID"]);
        //viewEditData(data["ID"],"VIEW");
      },
    },
  ];
  const delete_buttons = [
    {
      label: "",
      leftIcon: "fa-trash",
      type: "icon",
      classList: ["smart-delete-icon"],
      onClick: (data) => {
        openDeleteModal(data["ID"]);
        //viewEditData(data["ID"],"VIEW");
      },
    },
  ];

  const viewEditForm = (id, action_name) => {
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setLoading(false);
    };
    const subscription = post(
      ADMIN_OPERATIONS_INTERESTS.GET_ONE,
      { id: id },
      handleError
    ).subscribe((response) => {
      // console.log("response ", response);
      openNewForm(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const deleteData = (id) => {
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setLoading(false);
    };
    const subscription = post(
      ADMIN_OPERATIONS_INTERESTS.DELETE,
      { id: id },
      handleError
    ).subscribe((response) => {
      showAlertAutoClose( "Deleted Successfully...","success");
      closeModal();
      loadTableData();
      // setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  const openDeleteModal = (id, name) => {
    let modelObject = {
      title: "Interest Deletion!",
      body: "Do you wish to delete this Interest? This action cannot be reverted",
      okFunction: () => {
        deleteData(id);
      },
      cancelFunction: () => {
        closeModal();
        // console.log("cancel function")
      },
    };
    openModal(modelObject);
  };

  const columns = [
    { title: "", index: "testnew", width: "20" },
    { title: "INTEREST", index: "interest_name", isSortable: true, width: "40" },
    { title: "EDIT", index: "edit", type: "buttons", buttons: edit_buttons },
    {
      title: "DELETE",
      index: "delete",
      type: "buttons",
      buttons: delete_buttons,
    },
  ];
  // const data = [];

  const openNewForm = (data = {}) => {
    let modalObject = {
      body: <InterestForm loadTableData={loadTableData} dataIn={data} />,
      modalClass: "crop-admin-modal",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  return (
    <>
      <div className="smart-crop-admin-container">
        <div key={"InterestTableFirstcolumns"}  className="columns">
          <div key={"InterestTableFirstcolumn"} className="column">
            <div className="card">
              <div key={"InterestTableSecondcolumns"} className="columns is-vcentered">
                <div key={"InterestTableSecondcolumn"} className="column is-1 crop-admin-table-back">
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div key={"InterestTableThirdcolumn"} className="column is-8 crop-admin-table-title">
                Interests List
                </div>

                <div key={"InterestTableFourthcolumn"} className="column is-3 has-text-right crop-table-top-pagination">
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <div key={"InterestTableThirdcolumns"} className="columns">
                <div key={"InterestTableFifthcolumn"} className="column is-offset-1 is-8">
                  <SmartSoftButton
                    label="ADD AN INTEREST"
                    classList={[
                      "is-rounded is-small crop-admin-secondary-button",
                    ]}
                    onClick={openNewForm}
                  />
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={tabData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterestTable;

import {
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import config from "../config/config";
import AdminRoute from "./Admin/AdminRoute";
const basePath = config.REACT_APP_BASE_PATH || '';
//console.log("basePath " , basePath);
const SiteRoute = () => {
  return (
    <>
      <Router basename={process.env.REACT_APP_BASE_PATH || ''}>
        <AdminRoute />
      </Router>
      <ToastContainer />
    </>
  );
};

export default SiteRoute;

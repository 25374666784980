import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SERVICES_NOTIFICATION_URLS } from "../../api/Services/ServiceUrls";
import { useSiteContext } from "../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../services/notifyService";
import { post } from "../../services/smartApiService";
import "./AdminLayoutCss.css";
import { getTimeAgo } from "../../services/core/CommonService";


const SingleNotification = ({ type }) => {
  const [data, setData] = useState([]);
  const { setLoading,refreshUser } = useSiteContext();
  // const { setLoading, } = useSiteContext();

  const navigate = useNavigate();
  const baseNav = "";

  const loadTableData = () => {
    // setLoading(true, "Logging....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setData([]);
      setLoading(false);
    };
    let post_data = {
      notification_type: type,
    };
    const subscription = post(
      SERVICES_NOTIFICATION_URLS.GET_ALL,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      // setLoading(false);
      // console.log("notification data",data)
      // refreshUser()
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  return (
    <>
      <div className="smart-notification-accord">
        {data.data &&
          data.data.length > 0 &&
          data.data.map((obj, index) => {
            return (
              <div  className={
                obj.notification_status == 0
                  ? "crop-customer-notification-list-item has-text-danger"
                  : "crop-customer-notification-list-item"
              } key={index}>
                {obj.notification_message}
              </div>
            );
          })}
           {/* {data && data.length > 0 && (
        <div
          onClick={() => navigateLink("Notification/notification-view")}
          className="has-text-centered customer-notification-text has-text-weight-semibold "
        >
          view
        </div>
           )} */}
        {data.data && data.data.length < 1 && (
          <div className="crop-customer-notification-list-item No-notification">
            No Notifications
          </div>
        )}        
      </div>
      <div className="crop-customer-notification-list-time is-flex is-justify-content-space-between">
      {data.data && data.data.length > 0 && (
          <div className="">
            {data.time && data.time.length > 1 && getTimeAgo(data.time)}
          </div>
        )}
          {data.data &&
          data.data.length > 0 && 
          <div className="">
          <Link to={"/notification/" + type}> More</Link>
          </div>  } 
      </div>
    </>
  );
};

export default SingleNotification;

import CryptoJS from "crypto-js";
import config from "../config/config";

const USER_STORAGE_KEY = "userEncryptedSessionStorageAdmin";
const REMEBER_ME_INDEX = "rememberedUsernameAdmin";
const CSRF_INDEX = "smartCropTokenAdminIndex";

const encrypt_data = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(
    data,
    config.ENCRYPTION_KEY
  ).toString();
  return encryptedData;
};

const decrypt_data = (data) => {
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(data, config.ENCRYPTION_KEY);
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } catch (error) {
    return null;
  }
};

const encrypt = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    config.ENCRYPTION_KEY
  ).toString();
  return encryptedData;
};

const decrypt = (encryptedData) => {
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(
      encryptedData,
      config.ENCRYPTION_KEY
    );
    const decryptedData = JSON.parse(
      decryptedBytes.toString(CryptoJS.enc.Utf8)
    );
    return decryptedData;
  } catch (error) {
    return null;
  }
};

const getSessionStorage = (key) => {
  const encryptedData = sessionStorage.getItem(key);
  // console.log("emcrypted data ", encryptedData);
  if (encryptedData) {
    return decrypt(encryptedData);
  }
  return null;
};

const setSessionStorage = (key, data) => {
  const encryptedData = encrypt(data);
  sessionStorage.setItem(key, encryptedData);
};

const setUserSession = (data) => {
  setSessionStorage(USER_STORAGE_KEY, data);
};

const getUserSession = (index = null) => {
  let session_data = getSessionStorage(USER_STORAGE_KEY);
  // console.log("session data ", session_data);
  if (index !== null) {
    return session_data && session_data[index] !== undefined
      ? session_data[index]
      : "";
  }
  return session_data;
};

const getToken = () => {
  return getUserSession("accessToken");
};

const clearSessionStorage = () => {
  sessionStorage.clear();
};
const setLocalStorage = (key, data) => {
  const encryptedData = encrypt(data);
  localStorage.setItem(key, encryptedData);
};

const getLocalStorage = (key) => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    return decrypt(encryptedData);
  }
  return null;
};

const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

const setRemeberMe = (data) => {
  setLocalStorage(REMEBER_ME_INDEX, data);
};

const getRemeberMe = (data) => {
  return getLocalStorage(REMEBER_ME_INDEX);
};

const removeRemeberMe = () => {
  removeLocalStorage(REMEBER_ME_INDEX);
};

const setCsrf = (data) => {
  setLocalStorage(CSRF_INDEX, data);
};

const getCsrf = (data) => {
  return getLocalStorage(CSRF_INDEX);
};

export {
  setSessionStorage,
  getSessionStorage,
  clearSessionStorage,
  decrypt_data,
  encrypt_data,
  getRemeberMe,
  getToken,
  getUserSession,
  removeRemeberMe,
  setRemeberMe,
  setUserSession,
  setCsrf,
  getCsrf,
};

const StarDisplay = ({ rating ,totalStars=5}) => {
  return (
    <div className="star-rating">
        {[...Array(totalStars)].map((star, index) => {
            const fullStars = Math.floor(rating);
            const isHalfStar = rating - fullStars >= 0.5 && index === fullStars;

            return (
                <span key={index} className="star">
                    {index < fullStars ? (
                        <i className="fa fa-star" />
                    ) : isHalfStar ? (
                        <i className="fa fa-star-half-o" />
                    ) : (
                        <i className="fa fa-star-o" />
                    )}
                </span>
            );
        })}
        {rating}
    </div>
);
};
;
  
  export default StarDisplay;
  
import { useEffect, useState } from "react";
// import { ADMIN_CHART } from "../../../services/ImageService";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  // Title,
  Tooltip,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { DASHBOARD_URLS } from "../../../api/Admin/Dashboard";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { changeDateTimeZoneFormat } from "../../../services/core/CommonService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { get } from "../../../services/smartApiService";
import RevenueChart from "./RevenueChart";
import "./RevenueOverview.css";

const RevenueOverChart = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    // Title,
    Tooltip
    // Legend
  );

  const data = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "Offers - Sold",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: "rgba(0,83,223)",
        borderColor: "rgba(0,83,223)",
        borderWidth: 1,
      },
      {
        label: "Offers - Redeemed",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: "rgba(88,209,252)",
        borderColor: "rgba(88,209,252)",
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        // text:'Bar chart with Data and Options'
      },

      filler: {
        propagate: true,
      },
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 20,
          min: 0,
          max: 100,
          callback: function (value, index, values) {
            return value + " ";
          },
        },
      },
    },
  };
  return (
    <>
      <div className="">
        <Bar data={data} options={options} />
      </div>
    </>
  );
};

const RevenueOverview = () => {
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const { setLoading } = useSiteContext();
  // const [formSubmit, setFormSubmit] = useState(false);
  // const [formErrors, setFormErrors] = useState(false);
  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setData([]);
      setLoading(false);
    };
    const subscription = get(
      DASHBOARD_URLS.GET_ADMIN_REVENUE,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  // const handleErrorChange = (name, value) => {
  //   setFormErrors((prev) => {
  //     // Create a copy of the previous state
  //     const updatedFormData = { ...prev };
  //     // Check if the value is null or empty
  //     if (value === null || value === "") {
  //       // If true, remove the property from the updated state
  //       delete updatedFormData[name];
  //     } else {
  //       // Otherwise, update or add the property in the updated state
  //       updatedFormData[name] = value;
  //     }

  //     return updatedFormData;
  //   });
  // };
 
  const SalesRevenue = () => {
    return (
      <>
        {/* <div
          key={"RevenueOverviewFirstcard"}
          className="card mt-4 sales-table-layout"
        >
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th key={"RevenueOverviewFirstColSpan"} colSpan="4">
                  Sales Revenue
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Sector</th>
                <th>Amount</th>
                <th>Percentage</th>
                <th>Last Update</th>
              </tr>

              {data.sector &&
                data.sector.map((item, key) => {
                  return (
                    <tr key={key}>
                      <th className="sector-name">{item?.sector_name}</th>
                      <td>{item.total}</td>
                      <td>{item.factor}%</td>
                      <td>
                        {changeDateTimeZoneFormat(
                          item.last_modified_time,
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>

            <thead>
              <tr>
                <th key={"RevenueOverviewSecondColSpan"} colSpan="4">
                  Services Revenue
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Category</th>
                <th>Amount</th>
                <th>Percentage</th>
                <th>Last Update</th>
              </tr>
              {data.market &&
                data.market.map((item, key) => {
                  return (
                    <tr key={key}>
                      <th>{item?.sector_name}</th>
                      <td>{item.total}</td>
                      <td>{item.factor}%</td>
                      <td>
                        {changeDateTimeZoneFormat(
                          item.last_modified_time,
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div> */}
        <div className="RevenueOverviewTabile">
          <table >
            <thead className="RevenueOverviewTabile-one">
              <tr className=" RevenueOverview-section-header">
                <th className="Sales-Revenue-color has-text-left" colSpan="4">
                  Sales Revenue
                </th>
              </tr>
              <tr className="RevenueOverview-section-subheader">
                <th className="Soctor-line-color">Sector</th>
                <th className="Amount-Percentage-LastUpdate">Amount</th>
                <th className="Amount-Percentage-LastUpdate">Percentage</th>
                <th className="Amount-Percentage-LastUpdate">Last Update</th>
              </tr>
            </thead>
            <tbody className="RevenueOverviewTabile-two">
              {data.sector &&
                data.sector.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td className="Soctor-colorr">{item?.sector_name}</td>
                      <td>{item.total}</td>
                      <td>{item.factor}%</td>
                      <td>
                        {changeDateTimeZoneFormat(
                          item.last_modified_time,
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                  );
                })}
              <tr className="section-header">
                <th className="Services-Revenue-color" colSpan="4">
                  Services Revenue
                </th>
              </tr>
              <tr className="section-subheader">
                <th className="Soctor-colorr">Category</th>
                <th className="Amount-Percentage-LastUpdate">Amount</th>
                <th className="Amount-Percentage-LastUpdate">Percentage</th>
                <th className="Amount-Percentage-LastUpdate">Last Update</th>
              </tr>
               {data.market &&
                data.market.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td className="Soctor-colorr">{item?.sector_name}</td>
                      <td>{item.total}</td>
                      <td>{item.factor}%</td>
                      <td>
                        {changeDateTimeZoneFormat(
                          item.last_modified_time,
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        key={"RevenueOverviewSecondcard"}
        className="smart-crop-dashboard-container card pl-4 pr-5 pt-4 pb-4"
      >
        <div className="">
          {" "}
          <RevenueChart />
        </div>
        <div>{SalesRevenue()}</div>
      </div>
    </>
  );
};

export default RevenueOverview;

const ADMIN_OPERATIONS_LOYALTY = {
     GET_ALL:"admin/loyalty/get_all",
     GET_ONE:"admin/loyalty/get_one",
     INSERT:"admin/loyalty/insert",
     UPDATE:"admin/loyalty/update",
     DELETE:"admin/loyalty/delete_one",
     GET_ALL_SELECT:"admin/loyalty/get_all_select"
};

const ADMIN_OPERATIONS_INTERESTS = {
    GET_ALL:"admin/interest/get_all",
    GET_ONE:"admin/interest/get_one",
    INSERT:"admin/interest/insert",
    UPDATE:"admin/interest/update",
    DELETE:"admin/interest/delete_one"
};

const ADMIN_OPERATIONS_HOLIDAYS = {
    GET_ALL:"admin/holidays/get_all",
    GET_ONE:"admin/holidays/get_one",
    INSERT:"admin/holidays/insert",
    UPDATE:"admin/holidays/update",
    DELETE:"admin/holidays/delete_one"
};

const ADMIN_OPERATIONS_SECTOR = {
    GET_ALL:"admin/sector/get_all",
    GET_ONE:"admin/sector/get_one",
    INSERT:"admin/sector/insert",
    UPDATE:"admin/sector/update",
    DELETE:"admin/sector/delete_one",
    GET_ALL_SELECT:"admin/sector/get_all_select",
    GET_IMAGE_PURCHASE:"admin/sector/get_image_purchase",
    GET_IMAGE_REDEEM:"admin/sector/get_image_redeem",
};

const ADMIN_SETTINGS= {
    GET_ALL:"/services/site/get_all",   
    INSERT:"/services/site/insert",   
};


const ADMIN_BIDSECTOR_SECTOR = {
    GET_ALL_BID_ADMIN:"/business/market_offers/get_all_admin",
    UPDATE_QUALIFY:"/business/market_offers/update_qualify",
    UPDATE_ORDER:"/business/market_offers/update_order",
    INSERT_BID:"/business/market_offers/insert_bid",
    DELETE:"/business/market_offers/delete_one",
};

const ADMIN_OPERATIONS_SERVICE = {
    GET_ALL:"/admin/service/get_all",
    GET_ONE:"/admin/service/get_one",
    INSERT:"/admin/service/insert",
    UPDATE:"/admin/service/update",
    DELETE:"/admin/service/delete_one",
    GET_ALL_SELECT:"/user/get_all_select_business"
    
};

const ADMIN_PAYMENT={
    GET_CROP_BALANCE:"/admin/payment/get_crop_bal",
    GET_TOTAL_SALES:"/admin/payment/get_total_sales",
    UPDATE:"/admin/payment/insert"
}



export {
    ADMIN_OPERATIONS_LOYALTY,
    ADMIN_OPERATIONS_INTERESTS,
    ADMIN_OPERATIONS_HOLIDAYS,
    ADMIN_OPERATIONS_SECTOR,
    ADMIN_SETTINGS,
    ADMIN_BIDSECTOR_SECTOR,
    ADMIN_OPERATIONS_SERVICE,
    ADMIN_PAYMENT
}

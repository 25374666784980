import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSiteContext } from '../../../../contexts/SiteProvider';
import { showAlertAutoClose } from '../../../../services/notifyService';
import { get, post } from '../../../../services/smartApiService';
import { ADMIN_PROFILE_URLS, USER_URLS } from '../../../../api/Admin/AurhUrls';
import { ValidateFormNew } from 'soft_digi/dist/services/smartValidationService';
import { SmartSoftButton, SmartSoftForm } from 'soft_digi';
import { ADMIN_SETTINGS } from '../../../../api/Admin/OperationsUrl';
import { ALLOW_NUMERIC } from '../../../../services/PatternSerivce';

const SetSector = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const { setLoading  } = useSiteContext();

    const baseNav = "/operations";
    const navigateLink = (index) => {
      navigate(baseNav + "/" + index);
    };
    const handleInputChange = (name, value) => {
      //console.log(" name " ,name , "   value " , value)
      setFormData((prev) => ({ ...prev, [name]: value }));
      if (name == "profile_image" && value) {
        const lastIndex = value.length - 1;
      }
    };
    const handleErrorChange = (name, value) => {
      setFormErrors((prev) => {
        // Create a copy of the previous state
        const updatedFormData = { ...prev };
        // Check if the value is null or empty
        if (value === null || value === "") {
          // If true, remove the property from the updated state
          delete updatedFormData[name];
        } else {
          // Otherwise, update or add the property in the updated state
          updatedFormData[name] = value;
        }
  
        return updatedFormData;
      });
    };
    const loadSettingsData = () => {
      setLoading(true, "Loading....Please Wait.");
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage, "error");
        setLoading(false);
      };
      const subscription = get(ADMIN_SETTINGS.GET_ALL, handleError).subscribe(
        (response) => {
          setFormData(response.data);
          setLoading(false);
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    };
    useEffect(() => {
      loadSettingsData();
    }, []);
  
  

    const handleSubmit = () => {
      setFormSubmit(true);
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage, "error");
        setLoading(false);
      };
      setLoading(true, "Details Submitting....Please Wait");
      let url = ADMIN_SETTINGS.INSERT;
      const subscription = post(url, formData, handleError).subscribe(
        (response) => {
          showAlertAutoClose("Data Saved Successfully", "success");
          setLoading(false);
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    };
  

    const formElements = [
      {
        type: "TEXT_BOX",
        width: "6",
        name: "sd_crop_earn",
        element: {
          label: "Earn CROPs (CROPs to be credited for 1 AUD)",
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          pattern: ALLOW_NUMERIC,
          max: 3,
       
        },
      },
      {
        type: "TEXT_BOX",
        width: "6",
        name: "sd_crop_redeem",
        element: {
          label: "Redeem CROPs (CROPs required to redeem 1 AUD) ",
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          max: 3,
          pattern: ALLOW_NUMERIC,
         
        },
      },
      {
        type: "TEXT_BOX",
        width: "6",
        name: "sd_crop_get_mate",
        element: {
          label: "Get a Mate CROPs  ",
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          pattern: ALLOW_NUMERIC,
          max: 3,
         
        },
      },
    
    ];
  return (
    <>
      <div className="smart-crop-admin-container is-mobile">
        <div key={"HolidaysTableFirstcolumns"} className="columns">
          <div key={"HolidaysTableFirstcolumn"} className="column ">
            <div className="card">
              <div
                key={"HolidaysTableSecondcolumns"}
                className="columns is-vcentered"
              >
                <div
                  key={"HolidaysTableSecondcolumn"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"HolidaysTableThirdcolumn"}
                  className="column is-8 crop-admin-table-title"
                >
                  Set CROPs
                </div>

               
              </div>
              <div className=''>
        <div className="crop-admin-form">
        <div className="mt-5 mr-5 p-6">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
            // onChange={(value) => handleInputChange("input_four", value)}
            // errorEnable={formSubmit}
          />

<div className="pt-5 has-text-right">
            <SmartSoftButton
              label="Save Change"
              onClick={handleSubmit}
              classList={["smart-crop-admin-action-button is-right", "px-6 "]}
            />
          </div>
          </div>
          </div>
        </div>
              
            </div>
          </div>
        </div>
      
      </div>
    </>
  )
}

export default SetSector


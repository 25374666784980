import moment from "moment";
import moment_timzone from "moment-timezone";
const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0;
};

const formatDate = (dateString: string, format: string) => {
  try {
    const formattedDate = moment(dateString).format(format);
    return formattedDate;
  } catch (error) {
    // If an error occurs (e.g., invalid date), return the original string
    return dateString;
  }
};

const changeDateTimeZoneFormat = (
  dateString: string,
  format: string = "DD-MM-YYYY H:mm",
  timeZone: string = "Asia/Kolkata"
) => {
  // Check if the dateString ends with 'Z', if not, add it
  // if (dateString && !dateString.endsWith("Z")) {
  // }
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //console.log("timezone ", userTimezone);
  const selectedDateInUTC = moment_timzone.utc(dateString); // Assume the date is already in UTC
  const selectedDateInTimeZone = selectedDateInUTC.tz(userTimezone);
  const dateAlone = selectedDateInTimeZone.format(format);
  return dateAlone;
};

const changeDateTimeZone = (
  dateString: string,
  timeZone: string = "Asia/Kolkata"
) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const selectedDateInUTC = moment_timzone.utc(dateString); // Assume the date is already in UTC
  const selectedDateInTimeZone = selectedDateInUTC.tz(userTimezone);
  const dateAlone = selectedDateInTimeZone.format("DD-MM-YYYY");
  return dateAlone;
};
const getTimeAgo = (timestamp: any) => {
  const currentTime: any = new Date(new Date().toISOString());
  const previousTime: any = new Date(timestamp + "Z");
  const timeDifference: any = currentTime - previousTime;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else {
    return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
  }
};

export {
  changeDateTimeZone,
  changeDateTimeZoneFormat,
  formatDate,
  isEmptyObject,
  getTimeAgo,
};

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SmartSofFile,
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  SmartSoftForm,
  SmartValid,
} from "soft_digi";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { base64ToFileSize } from "../../../services/core/FileService";

// import {
//   ADMIN_USER_LOGO,
//   USER_PROFILE_IMAGE,
// } from "../../../services/ImageService";
import { getImageContent } from "../../../services/fileService";
import {
  // ALLOW_ALPHABET,
  // VALID_EMAIL_ADDRESS,
  ALLOW_ALPHABET_SPACE,
  ALLOW_NUMERIC,
} from "../../../services/PatternSerivce.js";

import { ADMIN_PROFILE_URLS, USER_URLS } from "../../../api/Admin/AurhUrls";
import {
  showAlertAutoClose,
  
} from "../../../services/notifyService";
import { get, post } from "../../../services/smartApiService";
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SmartImageEditor from "../../../components/site/FormElements/SmartImageEditor";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { changeDateTimeZoneFormat } from "../../../services/core/CommonService";

const AccountSettings = () => {
  const [formData, setFormData] = useState({country_code:"AU"});
  const [tab, setTab] = useState("account");
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading,refreshUser, openModal, closeModal  } = useSiteContext();
  const today = new Date();
  const dates = [];
  const endDate = new Date(today);
  endDate.setDate(today.getDate() + 29);

  let currentDate = new Date(today);
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }


  const navigate = useNavigate();
  const baseNav = "/account-settings";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name == "profile_image" && value) {
      const lastIndex = value.length - 1;
    }
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait.");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error");
      setLoading(false);
    };
    const subscription = get(USER_URLS.GET_ONE_PROFILE, handleError).subscribe(
      (response) => {
         response.data.userData.country_code=response.data.userData.country_code=="61"?"AU":"IN"
        setFormData(response.data.userData);

        // console.log(response.data);
        //   let mergeObj = {
        //     ...response.data.addrData,
        //     ...response.data.bankData,
        //     ...response.data.userData
        // };
        // setFormData(mergeObj)
        // setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const handleSubmit = () => {
    window.scrollTo(0, 0);
    setFormSubmit(true);
    if (!ValidateFormNew(formData,formElements)) {
      return false;
    }
    if(formErrors && formErrors.profile_image){      
      return false;
    }
    
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Details Submitting....Please Wait");
    let url = ADMIN_PROFILE_URLS.UPDATE_PROFILE;

     let data_in = {...formData};
     data_in["age"] = changeDateTimeZoneFormat( data_in["age"],"YYYY-MM-DD");

    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        // console.log("response form ", response.data);
        refreshUser()
        // setFormData({});
        showAlertAutoClose("Data Saved Successfully", "success");
        loadTableData();

        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const tabs = [
    {
      index: "account",
      label: "Account",
      icon: <i className="fa fa-user-circle-o" aria-hidden="true"></i>,
      link: "account",
    },
    {
      index: "security",
      label: "Security",
      icon: <i className="fa fa-unlock-alt" aria-hidden="true"></i>,
      link: "security",
    },
  ];

  const handleTabs = (index) => {
    setTab(index);
  };

  const tabs_display = () => {
    return (
      <div className="tabs is-boxed is-medium is-8 crop-top-tabs account-settings-tab">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={tab === item.index ? "is-active" : ""}
              >
                <a href="#" onClick={() => navigateLink(item.link)}>
                <span className={tab === item.index ? "is-active" : "account-settings-tab"}>{item.icon}</span>
                  <span className={tab === item.index ? "is-active ml-3" : "account-settings-tab ml-3"}>{item.label}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const EmailValidation = {
    email: [SmartValid.email("Please enter a valid Email address")],
  };
  const AccountFormValidations = {
    // email_id: [
    //   SmartVali.email("Please enter a valid Email address"),
    //   SmartValid.email("Please enter a valid Email address"),
    // ],
    first_name: [
      SmartValid.required("First Name is Required"),
      SmartValid.pattern("Invalid Name Format",/^[a-zA-Z\s'-]+$/)
    ],
    last_name: [
      SmartValid.required("Last Name is Required"),
      SmartValid.pattern("Invalid Name Format",/^[a-zA-Z\s'-]+$/)
    ],
    date: [SmartValid.required("Date is Required")],
    password: [SmartValid.required("Password is Required")],
    Gender: [SmartValid.required("Gender is Required")],
    dateofbirth:[SmartValid.required("DOB is Required")],
    mobile_no: [
      SmartValid.requiredNumber("Mobile No is Required"),
      // SmartValid.minLength("Number should be minimum 10 digit", 9),
    ],

  };

  const openImageModalOnEdit = (value) => {
    if (value) {
      const lastIndex = value.length - 1;
      openImageModal(value[lastIndex], lastIndex);
    }
  };

  const setImage = (image_content, imageData, index) => {
    let name = "profile_image";
    imageData.content = image_content.split(",")[1];
    setFormData((prev) => ({ ...prev, [name]: imageData }));
  };

  const openImageModal = (imageData, index) => {
    if (imageData) {
      let modelObject = {
        body: (
          <SmartImageEditor
            src={imageData.content}
            setImage={(image) => setImage(image, imageData, index)}
            closeModal={closeModal}
            enableOthers={false}
          />
        ),
        modelClass: "customer-model-layout smart-modal-90",
        bodyClose: false,
      };
      openModal(modelObject);
    }
  };

  const deleteImage = () => {
    setFormData((prev) => ({ ...prev, profile_image: null }));
  };

  const openImageModalOnEditNew = (val) => {
    let value = [val];
    if (value) {
      const lastIndex = value.length - 1;
      openImageModal(value[lastIndex], lastIndex);
    }
  };

   const Validations = {
     // first_name: [SmartValid.required("First Name is Required")],
     // middle_name: [SmartValid.required("Middle Name is Required")],
     // last_name: [SmartValid.required("Last Name is Required")],
     // age: [SmartValid.required("Age is Required")],
     // DOB: [SmartValid.required("DOB is Required")],
     // add_1: [SmartValid.required("Address 1 is Required")],
     // add_2: [SmartValid.required("Address 2  is Required")],
     // city: [SmartValid.required("City is Required")],
     // state: [SmartValid.required("State is Required")],
     // pincode: [SmartValid.required("Pincode is Required")],
     image: [
       SmartValid.custom((value) => {
         // allowed profile size 800 kb
         let image_size = base64ToFileSize(
           value && value.content ? value.content : ""
         );
         return image_size > 800 ? "Size should be less than 800 KB" : "";
       }),
     ],
   };

  const formElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "first_name",
      element: {
        label: "First Name",
        placeHolder: "Enter Your Fist Name",
        validations: AccountFormValidations.first_name,
        inputType: "BORDER_LABEL",
        isRequired:true,
        inputProps: { isFocussed: true },
        max: 255,
        pattern: ALLOW_ALPHABET_SPACE,
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "middle_name",
      element: {
        label: "Middle Name",
        placeHolder: "Enter Your Middle Name",
        // validations: AccountFormValidations.name,
        inputType: "BORDER_LABEL",
        // isRequired:true,
        inputProps: { isFocussed: true },
        max: 255,
        pattern: ALLOW_ALPHABET_SPACE,
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "last_name",
      element: {
        label: "Last Name",
        placeHolder: "Enter Your Last Name",
        validations: AccountFormValidations.last_name,
        inputType: "BORDER_LABEL",
        isRequired:true,
        inputProps: { isFocussed: true },
        max: 255,
        pattern: ALLOW_ALPHABET_SPACE,
      },
    },

    {
      type: "TEXT_BOX",
      width: "12",
      name: "email_id",
      element: {
        label: "Email",
        placeHolder: "Enter Your Email-Id",
        validations: EmailValidation.email,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
        isRequired:true,
      },
    },

    {
      type: "DATE",
      width: "4",
      name: "age",
      element: {
        label: "Date of Birth",
        validations: AccountFormValidations.dateofbirth,
        isRequired:true,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },     
        maxDate: new Date(),
        showYearDropdown:true,
        showMonthDropdown:true
      },
    },
    {
      type: "MOBILE",
      width: "8",
      name: "mobile_no",
      codeName: "country_code",
      element: {
        label: "Phone",
        placeHolder: "Enter Your Phone",
        value: formData?.mobile_no || "",
        codeSelected: formData?.country_code,
        countries: ["AU"],
        // placeHolder: "Enter Mobile Number",
        inputType: "BORDER_LABEL",
        rightIcon: "fa-mobile",
        validations: AccountFormValidations.mobile_no,
        isRequired: true,
        max: 10,
        // pattern: ALLOW_NUMERIC,
      },
    },

    // {
    //   type: "TEXT_BOX",
    //   width: "6",
    //   name: "mobile_no",
    //   element: {
    //     label: "Phone",
    //     placeHolder: "Enter Your Phone",
    //     validations: AccountFormValidations.mobile_no,
    //     inputType: "BORDER_LABEL",
    //     inputProps: { isFocussed: true },
    //     pattern: ALLOW_NUMERIC,
    //     max: 10,
    //     isRequired:true,
    //   },
    // },
  ];
  const TextFeild = () => {

    const options = [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
      { value: "Other", label: "Other" },
    ];

    return (
      <div className="crop-admin-form">
        <div className="mt-5 mr-5">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
            // onChange={(value) => handleInputChange("input_four", value)}
            // errorEnable={formSubmit}
          />

          <span className="crop-gender-label-color">Gender</span>

          <div className="mr-3 mt-4">
            <SmartSoftCheckRadioSwitch
              isMulti={true}
              options={options}
              type={"radio"}
              name="gender"
              value={formData?.gender || ""}
              onChange={(value) => handleInputChange("gender", value)}
              errorEnable={formSubmit}
              errorUpdate={(value) => handleErrorChange("euserid", value)}
              validations={AccountFormValidations.Gender}
            />
          </div>

          <div className="pt-5">
            <SmartSoftButton
              label="Save Change"
              onClick={handleSubmit}
              classList={["smart-crop-admin-action-button", "px-6 "]}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="smart-admin-crop-container is-mobile">
        <div key={"AccountSettingsFirstcolumns"} className="columns">
          <div key={"AccountSettingsFirstcolumn"} className="column">
            {tabs_display()}
          </div>
        </div>

        <div key={"AccountSettingsSecondcolumns"} className="columns">
          <div
            key={"AccountSettingsSecondcolumn"}
            className="column is-6 is-flex is-justify-content-center "
          >
            <div
              key={"AccountSettingsThirdcolumn"}
              className="column is-12 has-text-centered account-settings-form"
            >
              <span className="is-flex is-justify-content-center">
                <div className="pb-4  ">
                  <figure className="image is-128x128 ">
                    <img
                      className="is-rounded admin-account-image"
                      src={getImageContent(formData?.profile_image)}
                      alt=""
                    />
                  </figure>
                  <div className="smart-crop-image-edit is-size-6">
                  <i
                    className="fa fa-pencil-square-o  image-edit-options"
                    onClick={() =>
                      openImageModalOnEditNew(formData?.profile_image)
                    }
                  ></i>
                  <i
                    className="fa fa-trash image-edit-options ml-3"
                    onClick={() => deleteImage()}
                  ></i>
                </div>
                </div>
              </span>
              <span className="is-flex is-justify-content-center">
                <SmartSofFile
                  placeHolder="Upload New Photo"
                  value={formData?.profile_image}
                  onChange={(value) => {
                    openImageModalOnEdit(value);
                    handleInputChange("profile_image", value);
                  }}
                  errorEnable={formSubmit}
                  fileNameEnable={false}
                  validations={Validations.image}
                  isMulti={true}
                  isRequired={true}
                />
              </span>
              <span>
                <div className="mt-4 account-image-text">
                  {" "}
                  Allowed PNG or JPEG. Max size of 800K
                </div>
              </span>
            </div>
          </div>

          <div
            key={"AccountSettingsFourthcolumn"}
            className="column is-6 media-screen-AccountSettings"
          >
            {TextFeild()}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;

import { useNavigate, } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import NoResultComponents from "../../../components/site/NoResultComponents";

const ActiveOffersTable = () => {
  const navigate = useNavigate();
  const columns = [
    { title: "", index: "" },
    { title: "PRODUCT", index: "prodectline" },
    { title: "TITLE", index: "titleline" },
    { title: "STAR RATING", index: "starratingline" },
    // { title: "DESCRIPTION", index: "descriptionline" },
    { title: "LIKES", index: "likeline" },
  ];
  const data = [{}];
  const baseNav = "/database";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  return (
    <>
      <div className="ActiveOffersTable">
        <div key={"ActiveOffersTableFirstColumns"} className="columns">
          <div key={"ActiveOffersTableFirstColumn"} className="column">
            <div key={"CustomerDataBusinessDatacardone"} className="card">
              <div className="card ActiveOfferscarone">
                <div className="">
                  <div
                    key={"ActiveOffersTableSecondColumns"}
                    className="columns is-vcentered"
                  >
                    <div
                      key={"ActiveOffersTableSecondColumn"}
                      className="column is-1 crop-admin-table-back"
                    >
                      <span
                        className="icon"
                        onClick={() => navigateLink("database_management-list")}
                      >
                        <i className="fa fa-undo" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div
                      key={"ActiveOffersTableThirdColumn"}
                      className="column is-8 crop-admin-table-title"
                    >
                      Active Offers
                    </div>
                    <div
                      key={"ActiveOffersTableFourthColumn"}
                      className="column is-3 has-text-right crop-table-top-pagination"
                    >
                      {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                  <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                    </div>
                  </div>
                  <SmartSoftTable
                    columns={columns}
                    data={data}
                    tableProps={{ className: "crop-table-layout" }}
                  />
                  {data&&data.length<1&&( <NoResultComponents title={"No Results Found"}  />)} 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveOffersTable;

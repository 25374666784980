import { useEffect, useState } from "react";
import { DASHBOARD_URLS } from "../../../api/Admin/Dashboard";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { get } from "../../../services/smartApiService";

const Sales = () => {
  const [data, setData] = useState({});
  const { setLoading } = useSiteContext();

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setData({});
      setLoading(false);
    };
    const url = DASHBOARD_URLS.GET_TOTAL_SALES;
    // const year = 1;
    const subscription = get(
      url,

      handleError
    ).subscribe((response) => {
      setData(response.data);
      //console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const productRate = [
    { Title: "Woolworths", ProducTrate: "$1913.00" },
    { Title: "Woolworths", ProducTrate: "$1913.00" },
    { Title: "Woolworths", ProducTrate: "$1913.00" },
  ];

  return (
    <div className="smart-crop-dashboard-container card p-4">
      <div className="admin-dashboard-header is-size-5">Total Sales</div>
      <div className="is-flex">
        <div className="total-sales-amount">A$ {data?.last_thirty_revenue}</div>
        <div className="is-flex sales-amount-percentage mt-2 ml-2">
          <i className="fa fa-caret-up" aria-hidden="true"></i>
          <div className="change-percentage ml-2">
            {data?.deviation > 0 ? data?.deviation + "%" : ""}
          </div>
        </div>
      </div>
      {data.business_details &&
        data.business_details.map((item, index) => (
          <div key={index} className="sales-person-details">
            <div>
              <div className="is-size-6 admin-dashboard-header">
                {item.business_name}
              </div>
              <div className="sales-product-title"></div>
            </div>
            <div className="sales-person-details-sub-total">
              A$ {item.total_amount}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Sales;

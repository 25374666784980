import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { ADMIN_PAYMENT } from "../../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  changeDateTimeZone
} from "../../../../services/core/CommonService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import {
  decrypt_data,
  getSessionStorage,
} from "../../../../services/sessionService";
import { post } from "../../../../services/smartApiService";

const PaymentHistoryTable = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const decodeId = id !== 0 ? decodeURIComponent(id) : 0;
  const decrypted_id = id !== 0 ? decrypt_data(decodeId) : 0;
  const basenav = "/operations";
  const [tabData, setTabData] = useState({ table: [] });
  const [weekData, setWeekData] = useState([]);
  const { setLoading } = useSiteContext();

  const navigateLink = (index) => {
    navigate(basenav + "/" + index);
  };
  useEffect(() => {
    getWeekValue();
    loadTableData();
  }, []);
  const getWeekValue = () => {
    let week = getSessionStorage("CROP_ADMIN_PAYMENT_weekData");
    setWeekData(week);
    console.log(week);
  };
  const loadTableData = (flag = null) => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setTabData({ table: [] });
      setLoading(false);
    };
    let sdate = getSessionStorage("CROP_ADMIN_PAYMENT_SDATE");
    let post_data = {
      id: decrypted_id,
      sdate: sdate,
      history_flag:1
    };
    // if (flag != null) {
    //   if (flag == "L") post_data.sdate = tabData.start_date;
    //   else post_data.sdate = tabData.end_date;
    // }
    const subscription = post(
      ADMIN_PAYMENT.GET_CROP_BALANCE,
      post_data,
      handleError
    ).subscribe((response) => {
      console.log(response);
      setTabData(response.data);
      console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const MyWeekDays = () => {
    const options = { month: "short", day: "numeric" };
    // Parse the dates
    const start = new Date(tabData.start_date);
    const end = new Date(tabData.end_date);

    // Format the dates
    const formattedStartDate = start.toLocaleDateString("en-US", options);
    const formattedEndDate = end.toLocaleDateString("en-US", options);

    return (
      <div>
        <h2 className="is-size-7 mt-1">
          {formattedStartDate} - {formattedEndDate}
        </h2>
      </div>
    );
  };

  const nextWeek = () => {
    loadTableData("R");
  };

  const prevWeek = () => {
    // setWeek(-1)
    loadTableData("L");
  };

  const date_Formate_fun = (data) => {
    return <>{changeDateTimeZone(data.sd_last_modified_time)}</>;
  };

  const updateButtonFunction = (data) => {
    const label = data.status === 0 ? "Submitted" : data.status === 10 ? "Paid" : "In Progress";
    return <span className="status-label">{label}</span>;
  };
  
  const date_Formate_Last = (data) => {
    return <>{changeDateTimeZone(data.sd_last_modified_time)}</>;
  };

  const columns = [
    { title: "" },
    {
      title: "DATE",
      index: "sd_last_modified_time",
      valueFunction: date_Formate_fun,
    },
    { title: "TITLE", index: "offer_title", width: "20" },
    { title: "TOTAL", index: "sd_amount_aud" },
    // { title: "GST (%)", index: "offer_gst" },
    { title: "GST (A$)", index: "gst_value" },
    { title: "RETENTION (%)", index: "service" },
    { title: "RETENTION (A$)", index: "service_value" },
    { title: "TO PAY", index: "total_value" },
    // { title: "Last Out Date", index: "sd_last_modified_time" , valueFunction:date_Formate_Last },
    { title: "Status", index: "status" ,valueFunction: updateButtonFunction,},
  ];

  //   "ID":"201","sd_mt_userdb_id":"146","sd_transaction_type":"CREDIT","sd_crop_type":"PO_EARN","sd_crop_points":"75","sd_amount_aud":"985","sd_transaction_id":"68","sd_created_by":"171","sd_last_modified_time":"2024-04-16
  // 17:33:14","total_amount":"38035.81982421875","business_name":"Janarthanan
  // ps"

  const data = [
    {
      date: "",
      title: "",
      price: 250,
      crops: "",
      qty: 5,
      totalcrops: "",
      total: "",
      gst: "",
      cropsvalues: "Total to pay",
      cropsretention: 0.0,
      topay: "",
    },
  ];
  return (
    <>
      <div className="card">
        <div className=" card">
          <div
            key={"PaymentHistoryTablecolumnsone"}
            className="columns is-vcentered"
          >
            <div
              key={"PaymentHistoryTablecolumnone"}
              className="column is-1 crop-admin-table-back"
            >
              <span
                className="icon"
                onClick={() => navigateLink("payment-list")}
              >
                <i className="fa fa-undo" aria-hidden="true"></i>{" "}
              </span>
            </div>
            <div
              key={"PaymentHistoryTablecolumntwo"}
              className="column is-8 crop-admin-table-title"
            >
              HISTORY
            </div>
            <div
              key={"PaymentTablecolumnfour"}
              className="column is-3 crop-table-top-pagination is-flex has-text is-justify-content-end"
            >
              {/* <i
                className="fa fa-caret-left mr-3 is-clickable"
                aria-hidden="true"
                onClick={prevWeek}
              ></i> */}
              <span className="crop-admin-table-title">{MyWeekDays()}</span>
              {/* <i
                className="fa fa-caret-right ml-3 is-clickable"
                aria-hidden="true"
                onClick={nextWeek}
              ></i> */}
            </div>
            {/* <div
            key={"PaymentHistoryTablecolumnthree"}
            className="column is-3 has-text-right crop-table-top-pagination"
          >
            <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
            <i className="fa fa-caret-right" aria-hidden="true"></i>
          </div> */}
          </div>
          <SmartSoftTable
            columns={columns}
            data={tabData?.table}
            tableProps={{ className: "crop-table-layout" }}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentHistoryTable;

import { useEffect, useState } from "react";
import { SmartSoftCheckRadioSwitch, SmartSoftTable } from "soft_digi";
import { DASHBOARD_URLS } from "../../../api/Admin/Dashboard";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { post } from "../../../services/smartApiService";

import { showAlertAutoClose } from "../../../services/notifyService";
import { getSessionStorage, setSessionStorage } from "../../../services/sessionService";

const Offerperform = () => {
  const [category, setCategory] = useState(null);
  const [data, setTabData] = useState([]);
  const { setLoading } = useSiteContext();

  // this is api to the table data
  const loadTableData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      // setTabData([]);
      setLoading(false);
    };
    let post_data = { type: category };
    const subscription = post(
      DASHBOARD_URLS.GET_OFFERS,
      post_data,
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    let categeory_session = getSessionStorage("crop_admin_op_cat");
    if (!categeory_session) {
      categeory_session = "1";
      
    }
    setCategory(categeory_session);
  }, []);

  useEffect(() => {
    if (category) {
      loadTableData();
      setSessionStorage("crop_admin_op_cat", category);
  
    }
  }, [category]);

  const MyOfferSwitch = () => {
    const Customer_services = [{ value: "1", label: "High Performance" }];
    const Business_services = [{ value: "2", label: "Low Performance" }];

    return (
      <>
        <div
          key={"OfferPerformanceFirstColumn"}
          className="column is-12 crop-admin-table-title admin-dashboard-header"
        >
          Offer Performance
        </div>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_services}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true }}
              name="switchone"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_services}
              switchMode={true}
              inputProps={{ isRounded: true }}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
        </div>
      </>
    );
  };

  const columns = [
    { title: "Offer", index: "offer_title", width: "30" },
    { title: "Sector", index: "sector_name", width: "10" },
    { title: "Business Name", index: "business_name", width: "10" },
    { title: "Quantity", index: "quantity", width: "10" },
    { title: "Sale Value", index: "sale_price", width: "10" },
  ];

  // const TableData = [
  //   {
  //     item: "Bed Sheet",
  //     type: "Retail",
  //     name: "k-Mart",
  //     quantity: "2",
  //     amount: 50.0,
  //   },
  //   {
  //     item: "Bed Sheet",
  //     type: "Shop",
  //     name: "k-Mart",
  //     quantity: "1",
  //     amount: 60.0,
  //   },

  //   {
  //     item: "Bed Sheet",
  //     type: "Dine",
  //     name: "k-Mart",
  //     quantity: "2",
  //     amount: 80.0,
  //   },

  //   {
  //     item: "Bed Sheet",
  //     type: "Retail",
  //     name: "k-Mart",
  //     quantity: "2",
  //     amount: 50.0,
  //   },
  // ];
  return (
    <>
      <div>
        <div
          key={"OfferPerformanceSecondColumn"}
          className="column is-12 crop-admin-table-title"
        >
          {MyOfferSwitch()}
        </div>
      </div>
      <div className="">
        <SmartSoftTable
          columns={columns}
          data={data}
          tableProps={{ className: "crop-table-layout" }}
        />
      </div>
    </>
  );
};
export default Offerperform;

import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  // SmartSoftCheckRadioSwitch,
  // SmartSoftInput,
  // SmartSoftSelect,
  SmartSoftForm,
  SmartValid,
} from "soft_digi";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { post } from "../../../../services/smartApiService";
// import { CROP_ADMIN_LOYALITY } from "../../../../services/ImageService";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { ADMIN_OPERATIONS_INTERESTS } from "../../../../api/Admin/OperationsUrl";
import {
  showAlertAutoClose,

} from "../../../../services/notifyService";

const InterestForm = ({ loadTableData, dataIn }) => {
  const [formData, setFormData] = useState(dataIn ? dataIn : {});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  // const [roles, setRoles] = useState([]);
  //const [type, setType] = useState("password");
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    // console.log(dataIn);
    setFormSubmit(true);
    if (!ValidateFormNew(formData,interesElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error", );
      setLoading(false);
    };
    setLoading(true, "Details Submitting....Please Wait");
    let url = ADMIN_OPERATIONS_INTERESTS.INSERT;
    if (formData.ID !== undefined) {
      formData["id"] = formData.ID;
      url = ADMIN_OPERATIONS_INTERESTS.UPDATE;
    }

    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        //console.log("response form ", response.data);
        loadTableData();
        showAlertAutoClose("Data Saved Successfully", "success");
        closeModal();
        // setUser(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
 
  useEffect(() => {}, []);
  const namepopupFormValidations = {
    Interestname: [SmartValid.required("Interest Name is Required")],
  };
  const interesElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "interest_name",
      element: {
        label: "Interest Name",
        validations: namepopupFormValidations.Interestname,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
      },
    },
  ];
  return (
    <>
      <div key={"InterestFormFirstcolumns"} className="columns">
        <div
          key={"InterestFormFirstcolumn"}
          className=" column is-10 card crop-admin-modal-content"
        >
          <div
            key={"InterestFormSecondcolumns"}
            className="columns is-multiline"
          >
            <div className="column is-12 has-text-centered" key="1">
              <p className="crop-admin-form-title">
                {" "}
                {dataIn && dataIn.ID !== undefined
                  ? "Update Interest"
                  : "Add Interest"}{" "}
              </p>
            </div>
            <div className="column is-12 crop-admin-form" key="2">
              {/* <SmartSoftInput
                label="Interest Name"
                value={formData?.interest_name || ""}
                inputType="BORDER_LABEL"
                placeHolder="Emirates Skiward"
                onChange={(value) => handleInputChange("interest_name", value)}
                errorEnable={formSubmit}
                pattern={ALLOW_ALPHABET}
                max={255}
                errorUpdate={(value) => handleErrorChange("euserid", value)}
                validations={namepopupFormValidations.Interestname}
              /> */}
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={interesElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
               
              />
            </div>
            <div
              key={"InterestFormSecondcolumn"}
              className="column is-6 is-offset-3 has-text-centered"
            >
              <SmartSoftButton
                key="save"
                label={dataIn && dataIn.ID !== undefined ? "UPDATE" : "SAVE"}
                // leftIcon="fa-save"
                classList={["is-rounded crop-admin-action-button"]}
                onClick={handleSubmit}
              />
            </div>
            <div
              key={"InterestFormThirdcolumn"}
              className="column is-12 has-text-centered"
            >
              {/* <img src={CROP_ADMIN_INTEREST} /> */}
            </div>
          </div>
        </div>
        <div key={"InterestFormFourthcolumn"} className="column is-2">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterestForm;

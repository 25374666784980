import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SmartSoftButton,
  // SmartSoftInput,
  SmartSoftForm,
  SmartSoftTable,
  SmartValid
} from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { ADMIN_OPERATIONS_HOLIDAYS } from "../../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  ALLOW_ALPHABET,
  // ALLOW_ALPHABET,
  ALLOW_ALPHABET_SPACE,
  ALLOW_ALPHABET_SPACE_APOSTROPHE,
} from "../../../../services/PatternSerivce";
import {
  showAlertAutoClose,
 
} from "../../../../services/notifyService";
import { admin_states_select } from "../../../../services/site/SelectBoxServices";
import { get, post } from "../../../../services/smartApiService";
import { changeDateTimeZoneFormat } from "../../../../services/core/CommonService";

const HolidayUpdateForm = ({ loadTableData, editData }) => {
  const [formData, setFormData] = useState({
    holiday_name: "",
    holiday_date: "",
    state_name: "",
  });
  const [formErrors, setFormErrors] = useState(false);
  const [states, setStates] = useState([]);


  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const [formSubmit, setFormSubmit] = useState(false);
  const { setLoading } = useSiteContext();

  useEffect(() => {
    if (editData) {
      setFormData(editData);
    }
  }, [editData]);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const resetData = () => {
    handleInputChange("holiday_name", "");
    handleInputChange("holiday_date", "");
    handleInputChange("state_name", "");
    // handleInputChange("confirm_password", "");
  };
  const handleSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData,[...holidayformElements,...StateformElementsDate,...StateformElements])) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setLoading(false);
    };
    setLoading(true, "Details Submitting Please Wait....");
    let url = ADMIN_OPERATIONS_HOLIDAYS.INSERT;
    if (formData.ID !== undefined) {
      formData["id"] = formData.ID;
      url = ADMIN_OPERATIONS_HOLIDAYS.UPDATE;
    }
    let data_in = {...formData};
    data_in["holiday_date"] = changeDateTimeZoneFormat( data_in["holiday_date"],"YYYY-MM-DD");

    const subscription = post(url,data_in, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose("Data Saved Successfully", "success");
        setFormData({ holiday_name: "", holiday_date: "", state_name: "" });
        loadTableData();
        resetData()
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    admin_states_select((data) => setStates(data));
    //  loadData();
  }, []);

  const HolidaysFormValidations = {
    state: [SmartValid.required("state is Required")],
    name: [SmartValid.required("Name is Required")],
    date: [SmartValid.required("Date is Required")],

    // SmartValid.minLength("Number should be minimum 10 digit", 9),
  };
   const holidayformElements = [
     {
       type: "TEXT_BOX",
       width: "12",
       name: "holiday_name",
       element: {
         label: "Name",
         //  placeHolder: "Enter Your Name",
         validations: HolidaysFormValidations.name,
         inputType: "BORDER_LABEL",
         inputProps: { isFocussed: true },
         max: 255,
        //  pattern: ALLOW_ALPHABET_SPACE_APOSTROPHE,
         isRequired:true,
       },
     },
   ];
   const StateformElementsDate = [
    {
      type: "DATE",
      width: "6",
      name: "holiday_date",
      element: {
        label: "Date",
        validations: HolidaysFormValidations.date,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
        isRequired:true,
        max: 255,
        pattern: ALLOW_ALPHABET_SPACE,
      },
    },
  ];
   const StateformElements = [
     {
       type: "SELECT_BOX",
       width: "12",
       name: "state",
       element: {
         label: "State",
         options: states,
         isRequired:true,
         validations: HolidaysFormValidations.state,
         inputType: "BORDER_LABEL",
         inputProps: { isFocussed: true },
         max: 255,
         pattern: ALLOW_ALPHABET_SPACE,
       },
     },
   ];
  return (
    <>
      <div className="m-4">
        {/* <SmartSoftInput
          label=" Name"
          value={formData?.holiday_name || ""}
          inputType="BORDER_LABEL"
          onChange={(value) => handleInputChange("holiday_name", value)}
          errorEnable={formSubmit}
          // pattern={ALLOW_ALPHABET}
          max={255}
          errorUpdate={(value) => handleErrorChange("euserid", value)}
          validations={HolidaysFormValidations.name}
        /> */}
        <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={holidayformElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
      </div>
      <div className="m-4">
      <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={StateformElementsDate}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
            onChange={(value) => handleInputChange("holiday_date", value)}
            errorUpdate={(value) => handleErrorChange("euserid", value)}
          />
        {/* <SmartSoftDate
          label="Date"
          value={formData?.holiday_date || ""}
          inputType="BORDER_LABEL"
          onChange={(value) => handleInputChange("holiday_date", value)}
          errorEnable={formSubmit}
          errorUpdate={(value) => handleErrorChange("euserid", value)}
          // validations={HolidaysFormValidations.date}
        /> */}
      </div>
      <div className="m-4">
        {/* <SmartSoftInput
          label=" State"
          value={formData?.state_name || ""}
          inputType="BORDER_LABEL"
          onChange={(value) => handleInputChange("state_name", value)}
          errorEnable={formSubmit}
          // pattern={ALLOW_ALPHABET}
          max={255}
          errorUpdate={(value) => handleErrorChange("euserid", value)}
          validations={HolidaysFormValidations.state}
        /> */}
        <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={StateformElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
      </div>
      <div className="m-4">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button"]}
          label={editData && editData.ID !== undefined ? "UPDATE" : "ADD"}
          onClick={handleSubmit}
          errorEnable={formSubmit}
          errorUpdate={(value) => handleErrorChange("euserid", value)}
          // validations={HolidaysFormValidations}
        />
      </div>
    </>
  );
};

const HolidaysTable = () => {
  const navigate = useNavigate();

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const [editData, setEditData] = useState(null);
  // this is api to the table data
  const loadTableData = () => {
    setLoading(true, "Loading....Please wait.");

    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setTabData([]);
      setLoading(false);
    };
    const subscription = get(
      ADMIN_OPERATIONS_HOLIDAYS.GET_ALL,
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const edit_buttons = [
    {
      label: "",
      leftIcon: "fa-pencil",
      type: "icon",
      classList: ["smart-edit-icon"],
      onClick: (data) => {
        viewEditForm(data["ID"]);
        //viewEditData(data["ID"],"VIEW");
      },
    },
  ];
  const delete_buttons = [
    {
      label: "",
      leftIcon: "fa-trash",
      type: "icon",
      classList: ["smart-delete-icon"],
      onClick: (data) => {
        openDeleteModal(data["ID"]);
        //viewEditData(data["ID"],"VIEW");
      },
    },
  ];
  const viewEditForm = (id, action_name) => {
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setLoading(false);
    };
    const subscription = post(
      ADMIN_OPERATIONS_HOLIDAYS.GET_ONE,
      { id: id },
      handleError
    ).subscribe((response) => {
      // console.log("response ", response);
      setEditData(response.data);
      // console.log(editData);
      // openNewForm(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  const openDeleteModal = (id, name) => {
    let modelObject = {
      title: "Holiday Deletion!",
      body: "Do you wish to delete this holiday? This action cannot be reverted",
      okFunction: () => {
        deleteData(id);
      },
      cancelFunction: () => {
        closeModal();
        // console.log("cancel function")
      },
    };
    openModal(modelObject);
  };
  const deleteData = (id) => {
    // setFormData({ holiday_name: "", holiday_date: "", state_name: "" });
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setLoading(false);
    };
    const subscription = post(
      ADMIN_OPERATIONS_HOLIDAYS.DELETE,
      { id: id },
      handleError
    ).subscribe((response) => {
      setEditData({ holiday_name: "", holiday_date: "", state_name: "" });
      showAlertAutoClose("Deleted Successfully...", "success");
   
      closeModal();
      loadTableData();
      // setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const columns = [
    { title: "", index:"", width: "3" },
    { title: "STATE", index: "state_name", isSortable: true, width: "20" },
    { title: "DATE", index: "holiday_date", isSortable: true ,   dateFormat: "DD-MM-YYYY", type:"date"},
    { title: "NAME", index: "holiday_name", isSortable: true, width: "20" },
    { title: "EDIT", index: "edit", type: "buttons", buttons: edit_buttons },
    {
      title: "DELETE",
      index: "delete",
      type: "buttons",
      buttons: delete_buttons,
    },
  ];
  const data = [];

  // const openNewForm = () => {
  //   let modalObject = {
  //     body: <LoyalityForm loadTableData={loadTableData} dataIn={data} />,
  //     modalClass: "crop-admin-modal",
  //   };
  //   openModal(modalObject);
  // };

  return (
    <>
      <div className="smart-crop-admin-container is-mobile">
        <div key={"HolidaysTableFirstcolumns"} className="columns">
          <div key={"HolidaysTableFirstcolumn"} className="column ">
            <div className="card">
              <div
                key={"HolidaysTableSecondcolumns"}
                className="columns is-vcentered"
              >
                <div
                  key={"HolidaysTableSecondcolumn"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"HolidaysTableThirdcolumn"}
                  className="column is-8 crop-admin-table-title"
                >
                  Holidays
                </div>

                <div
                  key={"HolidaysTableFourthcolumn"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <div key={"HolidaysTableThirdcolumns"} className="columns   mr-0">
                <div key={"HolidaysTableFifthcolumn"} className="column is-8 ">
                  <SmartSoftTable
                    columns={columns}
                    data={tabData}
                    tableProps={{ className: "crop-table-layout" }}
                  />
                </div>
                <div
                  key={"HolidaysTablesixthcolumn"}
                  className="column is-4 card p-0 mt-3  smart-holiday-update-card "
                >
                  <div className="smart-crop-admin-holiday-card pl-5">
                     {editData && editData.ID !== undefined ? "Update Holiday" : "Add New Holiday"}
                  </div>
                  <div className="holiday-update-form crop-admin-holiday-form">
                    <HolidayUpdateForm
                      loadTableData={loadTableData}
                      editData={editData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HolidaysTable;

import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { SmartSoftButton, SmartSoftTable } from "soft_digi";
import { ADMIN_OPERATIONS_SERVICE } from "../../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose, } from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";
import ServiceChargesForm from './ServiceChargesForm';

const ServiceChargesTable = () => {
    const [tabData, setTabData] = useState([]);
    const { setLoading, openModal, closeModal } = useSiteContext();
    const navigate = useNavigate();
  
    const baseNav = "/operations";
    const navigateLink = (index) => {
      navigate(baseNav + "/" + index);
    };
  
    // this is api to the table data
    const loadTableData = () => {
      setLoading(true, "Loading....Please Wait");
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage,"error" );
        setTabData([]);
        setLoading(false);
      };
      const subscription = get(
        ADMIN_OPERATIONS_SERVICE.GET_ALL,
        handleError
      ).subscribe((response) => {
        setTabData(response.data);
        //console.table("testing", response.data);
        setLoading(false);
      });
      return () => {
        subscription.unsubscribe();
      };
    };
  
    useEffect(() => {
      loadTableData();
    }, []);
  
    const edit_buttons = [
      {
        label: "",
        leftIcon: "fa-pencil",
        type: "icon",
        classList: ["smart-edit-icon"],
        onClick: (data) => {
          viewEditForm(data["ID"]);
        },
      },
    ];
    const delete_buttons = [
      {
        label: "",
        leftIcon: "fa-trash",
        type: "icon",
        classList: ["smart-delete-icon"],
        onClick: (data) => {
          openDeleteModal(data["ID"]);
          //  viewEditData(data["ID"],"VIEW");
        },
      },
    ];
  
    const deleteData = (id) => {
      setLoading(true, "Please Wait....");
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage,"error" );
        setLoading(false);
      };
      const subscription = post(
        ADMIN_OPERATIONS_SERVICE.DELETE,
        { id: id },
        handleError
      ).subscribe((response) => {
        showAlertAutoClose("Deleted Successfully...","success");
        closeModal();
        loadTableData();
        // setLoading(false);
      });
      return () => {
        subscription.unsubscribe();
      };
    };
  
    const openDeleteModal = (id, name) => {
      let modelObject = {
        title: "Service Charges Deletion!",
        body: "Do you wish to delete this service charges? This action cannot be reverted",
        okFunction: () => {
          deleteData(id);
        },
        cancelFunction: () => {
          closeModal();
          // console.log("cancel function")
        },
      };
      openModal(modelObject);
    };
  
    const viewEditForm = (id, action_name) => {
      setLoading(true, "Please Wait....");
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage,"error", );
        setLoading(false);
      };
      const subscription = post(
        ADMIN_OPERATIONS_SERVICE.GET_ONE,
        { id: id },
        handleError
      ).subscribe((response) => {
        // console.log("response ", response);
        openNewForm(response.data);
        setLoading(false);
      });
      return () => {
        subscription.unsubscribe();
      };
    };
  
    const columns = [
      {title:"", index:"", width:"5"},
      { title: "BUSINESS NAME ", index: "business_name", width: "25" },
      { title: "SERVICE CHARGES   (%) ", index: "service_value", isSortable: true, width: "20" },
      { title: "EDIT", index: "edit", type: "buttons", buttons: edit_buttons, width:"25" },
      {
        title: "DELETE",
        index: "delete",
        type: "buttons",
        buttons: delete_buttons,
        width:"25"
      },
    ];
    // const data = [];
  
    const openNewForm = (data = {}) => {
      let modalObject = {
        body: <ServiceChargesForm loadTableData={loadTableData} dataIn={data} />,
        modalClass: "crop-admin-modal smart-modal-30",
        bodyClose: false,
      };
      openModal(modalObject);
    };
  
    return (
      <>
        <div className="smart-crop-admin-container">
          <div  key={"LoyalityTableFirstcolumns"} className="columns">
            <div  key={"LoyalityTableFirstcolumn"}  className="column">
              <div className="card">
                <div  key={"LoyalityTableSecondcolumns"} className="columns is-vcentered">
                  <div key={"LoyalityTableSecondcolumn"} className="column is-1 crop-admin-table-back">
                    <span className="icon" onClick={() => navigateLink("home")}>
                      <i className="fa fa-undo" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div key={"LoyalityTableThirdcolumn"} className="column is-8 crop-admin-table-title">
                   Service Charges
                  </div>
  
                  <div key={"LoyalityTableFourthcolumn"}  className="column is-3 has-text-right crop-table-top-pagination">
                    {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                    {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                  </div>
                </div>
                <div key={"LoyalityTableThirdcolumns"} className="columns">
                  <div key={"LoyalityTableFifthcolumn"} className="column is-offset-1 is-8">
                    <SmartSoftButton
                      label="Apply Service Charges"
                      classList={[
                        "is-rounded is-small crop-admin-secondary-button",
                      ]}
                      onClick={openNewForm}
                    />
                  </div>
                </div>
                <SmartSoftTable
                  columns={columns}
                  data={tabData}
                  tableProps={{ className: "crop-table-layout" }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ServiceChargesTable
// import { SmartSoftButton } from 'soft_digi'
import { ANALYTICS } from "../../../services/ImageService";

const UserDetails = () => {
  return (
    <div className="columns card-details-Blaance p-3 smart-crop-dashboard-container ml-0 mb-1">
      {/* <div  key={"UserDetailsFirstcolumn"} className='column is-6'>
            <div className='dashboard-user-name'>
            Congratulations John
            </div>
            <div className=' has-text-weight-bold mt-5 mb-6'>
            Employee of the Month
            </div>
            <SmartSoftButton 
             label='View Performance'
             classList={["smart-crop-admin-action-button","px-6 ","mt-5","mb-5"]}
            />
        </div> */}
      <div key={"UserDetailsSecondcolumn"} className="column is-6">
        <img src={ANALYTICS} alt="" />
      </div>
    </div>
  );
};

export default UserDetails;

import { useEffect, useState } from "react";
import { DASHBOARD_URLS } from "../../../api/Admin/Dashboard";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { ADMIN_DASHBOARD } from "../../../services/ImageService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { get } from "../../../services/smartApiService";
import Balance from "./Balance";
import MarketSlots from "./MarketSlots";
import Offerperform from "./Offerperform";
import RevenueOverview from "./RevenueOverview";
import Sales from "./Sales";
import StatusCard from "./StatusCard";
import UserDetails from "./UserDetails";

const Dashboard = () => {
  const [tabData, setTabData] = useState([]);
  const { setLoading } = useSiteContext();

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error", );
      setTabData([]);
      setLoading(false);
    };
    const url = DASHBOARD_URLS.GET_ADMIN_COUNTS;
    // const year=1
    const subscription = get(
      url,

      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  return (
    <>
      <div key={"1"} className="columns is-multiline">
        <div className="column is-6 ">
          <UserDetails />
        </div>
        <div className="column is-6 ">
          <Balance />
        </div>
      </div>
      <div key={"2"} className="columns is-multiline ">
        <div key={"DashBoardFirstColumn"} className="column is-6 ">
          <RevenueOverview />
        </div>
        <div key={"DashBoardSecondColumn"} className="column is-6 card smart-dashboard-sales mb-3 mt-2">
          <div key={"3"} className="columns is-multiline">
            <div key={"DashBoardThirdColumn"} className="column is-12 ">
              <Sales />
            </div>
            <div key={"DashBoardFourthColumn"} className="column is-12">
              <div
                key={"DashBoardFirstColumns"}
                className="columns is-multiline"
              >
                <StatusCard item={tabData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns mt-3 ml-0 mr-0 card">
        <div key={"DashBoardFifthColumn"} className="column   is-6">
          <div className="admin-dashboard-header">Market Slots</div>
          <MarketSlots />
        </div>
        <div
          key={"DashBoardSixthColumn"}
          className="column dashbord-image-SixthColumn is-6 "
        >
          <img src={ADMIN_DASHBOARD} alt="" />
        </div>
      </div>
      <div className="columns mr-0 ml-0 mt-5">
        <div key={"DashBoardSeventhColumn"} className="column is-12 card">
          <Offerperform />
        </div>
      </div>
    </>
  );
};

export default Dashboard;



import { useNavigate } from "react-router-dom";
const NotificationMessage = ({ pats, message }) => {
    //console.log(" paths " , pats);
    const navigate = useNavigate();

    const CustomLink = ({ text, route }) => {
        const navigateLinks = (link) => {
            navigate(link);
        };
        return <span onClick={() => navigateLinks(route)}
           className="smart-cursor-pointer" style={{ color: "blue", textDecorationLine: "underline" }}>{text}</span>;
    };
    //console.log("entered inside")
    const linkPatterns = pats ? pats : [
        {
            placeholder: "address",
            text: "My orders",
            routeweb: "/customer/mycrop/OrderScreen",
        },
    ];
    console.log("notificaiton " , linkPatterns);

    const elements = [];
    let lastIndex = 0;

    linkPatterns.forEach((pattern) => {
      const index = message.indexOf(pattern.placeholder, lastIndex);
      if (index !== -1) {
        if (index > lastIndex) {
          elements.push(<span>{message.substring(lastIndex, index)}</span>);
        }
        elements.push(
          <CustomLink
            key={`link-${index}`}
            text={pattern.text}
            route={pattern.routeweb}
          />
        );
        lastIndex = index + pattern.placeholder.length;
      }
    });

    if (lastIndex < message.length) {
      elements.push(<span>{message.substring(lastIndex)}</span>);
    }
 //   console.log("elemenets output", elements)
    return elements;   
};

export default NotificationMessage;


import { SmartSoftButton, SmartSoftForm } from "soft_digi";

//Costomer
const CustomerNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "First Time Registration",
      name: "notification_customer_ftr",
      
    },
    {
      title: "Profile Update",
      name: "notification_customer_pu",
    },
    {
      title: "CROPs Earned",
      name: "notification_customer_ce",
    },
    {
      title: "CROPs Redeemed",
      name: "notification_customer_cr",
    },
    {
      title: "CROPs Balance On Milestone Achievement – 1000 CROPs",
      name: "notification_customer_cbone",
    },
    {
      title: "CROPs Balance On Milestone Achievement – 2500 CROPs",
      name: "notification_customer_cbtwo",
    },
    {
      title: "CROPs Balance On Milestone Achievement – 5000 CROPs",
      name: "notification_customer_cbthree",
    },
    {
      title: "CROPs Balance On Milestone Achievement – 10,000 CROPs",
      name: "notification_customer_cbfour",
    },
    {
      title: "CROPs Balance On Milestone Achievement – 50,000 CROPs",
      name: "notification_customer_cbfive",
    },
    {
      title: "CROPs Balance On Milestone Achievement – 100,000 CROPs",
      name: "notification_customer_cbsix",
    },
    {
      title: "Login Password Change",
      name: "notification_customer_lpc",
    },
    {
      title: "Mobile Number Change",
      name: "notification_customer_mnc",
    },
    {
      title: "Email ID Change",
      name: "notification_customer_eic",
    },
    {
      title: "Address Change",
      name: "notification_customer_ac",
    },
    {
      title: "Statement Generation",
      name: "notification_customer_sg",
    },
    {
      title: "Account Pre-Suspension",
      name: "notification_customer_aps",
    },
    {
      title: "Account Restoration",
      name: "notification_customer_ar",
    },
    {
      title: "Get a Mate Invite - Referrer",
      name: "notification_gam_ir",
    },
    {
      title: "Get a Mate - Referee Sign Up",
      name: "notification_gam_rfes",
    },
    {
      title: "Get a Mate - Referrer upon Referee Sign Up",
      name: "notification_gam_rfrs",
    },
    {
      title: "Get a Mate – Referrer Reminder Notification ",
      name: "notification_Customer_gamrr",
    },
    {
      title: "First Login Through Mobile App (Existing Account) ",
      name: "notification_Customer_fltma",
    },
    {
      title: "First Login Through Website (Existing Account) ",
      name: "notification_Customer_fltw",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}

      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="UPDATE"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const CustomerPurchaseNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "Order Placed",
      name: "notification_customer_poo",
    },
    // {
    //   title: "PO Purchase of CROPs",
    //   name: "notification_customer_poc",
    // },
    // {
    //   title: "PO Purchase of PROPs",
    //   name: "notification_customer_pop",
    // },
    // {
    //   title: "Redemption Order - CROPs or PROPs",
    //   name: "notification_customer_ro",
    // },
    {
      title: "Order Dispatched",
      name: "notification_customer_ordispatch",
    },
    {
      title: "Order Delivered",
      name: "notification_customer_ordelivery",
    },
    {
      title: "Order Ready for Pickup",
      name: "notification_customer_orpready",
    },
    {
      title: "Order Collected",
      name: "notification_customer_orcollected",
    },
    {
      title: "Order Cancellation Request",
      name: "notification_customer_orcrsub",
    },
    {
      title: "On Cancellation Approval",
      name: "notification_customer_orcrapp",
    },
    {
      title: "On Cancellation Disapproval",
      name: "notification_customer_orcrrej",
    },
    {
      title: "Order Cancelled - Refunded",
      name: "notification_customer_orcrefund",
    },
    {
      title: "Order Return - Requested",
      name: "notification_customer_orretsub",
    },
    {
      title: "Order Return - Approved",
      name: "notification_customer_orretapp",
    },
    {
      title: "Order Return - Processed",
      name: "notification_customer_orretproc",
    },
    {
      title: "Order Return - Refunded",
      name: "notification_customer_orretref",
    },
    {
      title: "Order Return Processed - Rejected",
      name: "notification_customer_orretrej",
    },
    {
      title: "Order Confirmed but not paid",
      name: "notification_customer_ocbnp",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="UPDATE"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const CustomerRequestNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "Request - Missing CROPs - Claim",
      name: "notification_customer_mcc",
    },
    {
      title: "Missing CROPs - Approved",
      name: "notification_customer_mca",
    },
    {
      title: "Missing CROPs - Rejected",
      name: "notification_customer_mcr",
    },
    {
      title: "Complaint-Unable to login using CROPs number - lodgement ",
      name: "notification_customer_ulcnl",
    },
    {
      title: "Complaint-Unable to login using CROPs number - resolution",
      name: "notification_customer_ulcnr",
    },
    {
      title: "Complaint – Unable to login using mobile number -lodgement",
      name: "notification_customer_cutlumnl",
    },
    {
      title: "Complaint – Unable to login using mobile number -resolution",
      name: "notification_customer_cutlumnr",
    },
    {
      title: "Unable to login using Email ID  - lodgement",
      name: "notification_customer_uleil",
    },
    {
      title: "Unable to login using Email ID   - resolution",
      name: "notification_customer_uleir",
    },
    {
      title: "Promo code not received    - lodgement",
      name: "notification_customer_pcnrl",
    },
    {
      title: "Promo code not received   - resolution",
      name: "notification_customer_pcnrr",
    },
    {
      title: "Unable to redeem   - lodgement",
      name: "notification_customer_url",
    },
    {
      title: "Unable to redeem  - resolution",
      name: "notification_customer_urr",
    },
    {
      title: "Poor Service Experience - lodgement",
      name: "notification_customer_psel",
    },
    {
      title: "Poor Service Experience - resolution",
      name: "notification_customer_pser",
    },
    {
      title: "Poor Product Experience - lodgement",
      name: "notification_customer_ppel",
    },
    {
      title: "Poor Product Experience - resolution",
      name: "notification_customer_pper",
    },
    {
      title: "Order placed but not delivered - lodgement",
      name: "notification_customer_opbndl",
    },
    {
      title: "Order placed but not delivered - resolution",
      name: "notification_customer_opbndr",
    },
    {
      title: "Delay in delivery - lodgement",
      name: "notification_customer_ddl",
    },
    {
      title: "Delay in delivery - resolution",
      name: "notification_customer_ddr",
    },
    {
      title: "Wrong delivery - lodgement",
      name: "notification_customer_wdl",
    },
    {
      title: "Complaint – Wrong delivery- resolution",
      name: "notification_customer_cwdr",
    },
    {
      title: "Complaint–General - lodgement",
      name: "notification_customer_ggl",
    },
    // {
    //   title: "Complaint -  On complaint ",
    //   name: "notification_customer_coc",
    // },
    {
      title: "General -  resolution ",
      name: "notification_customer_gr",
    },
    {
      title: "Offer Rating-   ",
      name: "notification_customer_or",
    },
    // {
    //   title: "Request – Discontinuation of Membership",
    //   name: "notification_customer_oros",
    // },
    // {
    //   title: "Processed-Approved - On approval of request ",
    //   name: "notification_customer_paar",
    // },
    // {
    //   title: "Processed–Transaction reversed - On closure of request ",
    //   name: "notification_customer_ptrcr",
    // },
    // {
    //   title: "Processed-Rejected - On rejection of request ",
    //   name: "notification_customer_prrr",
    // },
    {
      title: "Discontinuation of Membership- On submission ",
      name: "notification_customer_dms",
    },
    {
      title: "Request–General- lodgement ",
      name: "notification_customer_rgl",
    },
    {
      title: "Request–General- resolution ",
      name: "notification_customer_rgr",
    },
    {
      title: "Complaint – Order confirmed but unable to pay - lodgement  ",
      name: "notification_customer_ocbutp",
    },
    {
      title: "Complaint – Order confirmed but unable to pay - resolution ",
      name: "notification_customer_ocbutpr",
    },
    {
      title: "Complaint – CROPs not credited - Order - lodgement ",
      name: "notification_customer_cncooc",
    },
    {
      title: "Complaint – CROPs not credited - Order - resolution ",
      name: "notification_customer_cncoocr",
    },
    {
      title: "Complaint – CROPs not credited – Get a Mate - lodgement ",
      name: "notification_customer_cncgm",
    },
    {
      title: "Complaint – CROPs not credited – Get a Mate - resolution ",
      name: "notification_customer_cncgmr",
    },
    {
      title: "Complaint – CROPs credit does not match order value  - lodgement ",
      name: "notification_customer_cncov",
    },
    {
      title: "Complaint – CROPs credit does not match order value - resolution ",
      name: "notification_customer_cncovr",
    },
    {
      title: "Complaint – CROPs wrongly credited - lodgement ",
      name: "notification_customer_cwc",
    },
    {
      title: "Complaint – CROPs wrongly credited - resolution ",
      name: "notification_customer_cwcr",
    },
    {
      title: "Complaint – CROPs wrongly debited - lodgement ",
      name: "notification_customer_cwd",
    },
    {
      title: "Complaint – CROPs wrongly debited - resolution ",
      name: "notification_customer_cwdrr",
    },
    {
      title: "Complaint – CROPs debit does not match redemption value - lodgement ",
      name: "notification_customer_cddmrv",
    },
    {
      title: "Complaint – CROPs debit does not match redemption value  - resolution ",
      name: "notification_customer_cddmrvr",
    },
    {
      title: "Complaint – Invoice not received - lodgement ",
      name: "notification_customer_inr",
    },
    {
      title: "Complaint – Invoice not received - resolution ",
      name: "notification_customer_inrr",
    },
    {
      title: "Complaint – Verification code not received - lodgement ",
      name: "notification_customer_vcnr",
    },
    {
      title: "Complaint – Verification code not received -  resolution ",
      name: "notification_customer_vcnrr",
    },
    {
      title: "Complaint – Complaint lodged but not resolved - lodgement ",
      name: "notification_customer_clbnr",
    },
    {
      title: "Complaint – Complaint lodged but not resolved - resolution ",
      name: "notification_customer_clbnrr",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="UPDATE"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

//business
const BusinessNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "First Time Registration",
      name: "notification_Business_ftr",
    },
    {
      title: "Profile Update",
      name: "notification_Business_pu",
    },
    {
      title: "CROPs Offered",
      name: "notification_Business_co",
    },
    {
      title: "CROPs Redeemed",
      name: "notification_Business_cr",
    },
    {
      title: "Offer Upload Confirmation",
      name: "notification_Business_ouc",
    },
    {
      title: "Offer Removal Confirmation",
      name: "notification_Business_orc",
    },
    {
      title: "Offer Value amendment",
      name: "notification_Business_ova",
    },
    {
      title: "Low Redemption Rate",
      name: "notification_Business_lrr",
    },
    {
      title: "High Redemption Rate",
      name: "notification_Business_hrr",
    },
    {
      title: "Login Password Change",
      name: "notification_Business_lpc",
    },
    {
      title: "Mobile Number Change",
      name: "notification_Business_mnc",
    },
    {
      title: "Email ID Change",
      name: "notification_Business_eic",
    },
    {
      title: "Address Change",
      name: "notification_Business_ac",
    },
    {
      title: "Statement Generation",
      name: "notification_Business_sg",
    },
    {
      title: "Account Pre-Suspension",
      name: "notification_Business_aps",
    },
    {
      title: "Account Restoration",
      name: "notification_Business_ar",
    },
    {
      title: "First Login Through Mobile App (Existing Account) ",
      name: "notification_Business_fltma",
    },
    {
      title: "First Login Through Website (Existing Account) ",
      name: "notification_Business_fltw",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="UPDATE"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
const BusinessPaymentNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "Customer Payment - On Payment",
      name: "notification_Business_cpop",
    },
    {
      title: "Customer Order Cancellation Request",
      name: "notification_Business_cocr",
    },
    {
      title: "Request – Order Return - Processing",
      name: "notification_Business_rorp",
    },
    // {
    //   title: "Customer Redemption-CROPs or PROPs debited",
    //   name: "notification_Business_crcpd",
    // },
    {
      title: "Bid Submission Acknowledgement–Top Ranking",
      name: "notification_Business_bsatrbs",
    },
    {
      title: "Bid Submission Acknowledgement – Promo",
      name: "notification_Business_bsabs",
    },
    {
      title: "Bid Submission Acknowledgement – Top Ranking & Promo",
      name: "notification_Business_bsatrp",
    },
    {
      title: "Bid Payment Request–Top Ranking - On Closure of Auction",
      name: "notification_Business_bprtrca",
    },
    // {
    //   title: "Bid Payment Request – Top Ranking and Promo",
    //   name: "notification_Business_bprtrap",
    // },
    {
      title: "Bid Payment Request – On Closure of Auction",
      name: "notification_Business_bprca",
    },
    {
      title: "Bid Payment Request – Top Ranking and Promo",
      name: "notification_Business_bprtrap",
    },
    {
      title: "Bid Payment Acknowledgement–Top Ranking – On Payment",
      name: "notification_Business_bpatrp",
    },
    {
      title: "Bid Payment Acknowledgement – Promo – On Payment",
      name: "notification_Business_bpapp",
    },
    {
      title: "Bid Payment Acknowledgement – Top Ranking and Promo",
      name: "notification_Business_bpatrap",
    },
    {
      title: "Bid Payment Reminder – Top Ranking  – Bid Payment Request",
      name: "notification_Business_bprtrbpr",
    },
    {
      title: "Bid Payment Reminder – Promo – Bid Payment Request",
      name: "notification_Business_bprpbpr",
    },
    {
      title: "Bid Payment Reminder – Top Ranking and Promo",
      name: "notification_Business_bprtraps",
    },
    {
      title: "Bid Cancellation – Top Ranking – Bid Payment Reminder",
      name: "notification_Business_bctrbpr",
    },
    {
      title: "Bid Cancellation – Promo –  Bid Payment Reminder",
      name: "notification_Business_bcpbpr",
    },
    {
      title: "Bid Cancellation – Top Ranking and Promo",
      name: "notification_Business_bctrap",
    },
    {
      title: "Bid Deletion - Voluntary-On Voluntary deletion  by business",
      name: "notification_Business_onvcbb",
    },
    {
      title: "Bid Unsuccessful – Top Ranking –  One day prior to target date",
      name: "notification_Business_butrodptd",
    },
    {
      title: "Bid Unsuccessful – Promo –  One day prior to target date",
      name: "notification_Business_bupodptd",
    },
    {
      title: "Bid Unsuccessful – Top Ranking and Promo",
      name: "notification_Business_butrap",
    },
    {
      title: "Bid Active – Top Ranking",
      name: "notification_Business_batr",
    },
    {
      title: "Bid Active – Promo",
      name: "notification_Business_bap",
    },
    {
      title: "Bid Active – Top Ranking and Promotion",
      name: "notification_Business_batrap",
    },

    {
      title: "Daily Sales Report –  Next Day",
      name: "notification_Business_dsrn",
    },
    {
      title: "Top Rank - Complimentary - Upon offer addition",
      name: "notification_Business_trc",
    },
    {
      title: "Promo - Complimentary - Upon offer addition",
      name: "notification_Business_pcuoa",
    },
    {
      title: "Top Rank & Promo - Complimentary - Upon offer addition",
      name: "notification_Business_tapcuoa",
    },
    {
      title: "Bid Cancellation - Post payment Within 7 days of publication",
      name: "notification_Business_bcppdop",
    },
    {
      title: "Bid Cancellation - Within 48 hours of publication",
      name: "notification_Business_bcwhop",
    },
    {
      title: "Bid Cancellation - Refund - On refund",
      name: "notification_Business_bcror",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="UPDATE"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const BusinessRequestNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "Request - Missing CROPs - Processing -On customer submission",
      name: "notification_Business_rmcpcs",
    },
    {
      title: " Unable to login using CROPs number -lodgement ",
      name: "notification_Business_ulucnl",
    },
    {
      title: "Unable to login using CROPs number  - resolution",
      name: "notification_Business_ulucnr",
    },
    {
      title: "Unable to login using mobile number - lodgement ",
      name: "notification_Business_ulumnl",
    },
    {
      title: "Unable to login using mobile number  - resolution",
      name: "notification_Business_ulumnr",
    },
    {
      title: "Unable to login using Email ID   - lodgement",
      name: "notification_Business_ulueil",
    },
    {
      title: "Unable to login using Email ID    - resolution",
      name: "notification_Business_ulueir",
    },
    {
      title: "Unable to upload offer    - lodgement",
      name: "notification_Business_uuol",
    },
    {
      title: "Unable to upload offer  - resolution",
      name: "notification_Business_uuor",
    },
    {
      title: "Unable to bid for Top Ranking   - lodgement",
      name: "notification_Business_ubtrl",
    },
    {
      title: "Unable to bid for Top Ranking - resolution",
      name: "notification_Business_ubtrr",
    },
    {
      title: "Unable to bid for Promo - lodgement",
      name: "notification_Business_ubpl",
    },
    {
      title: "Unable to bid for Promo - resolution",
      name: "notification_Business_ubfpr",
    },
    {
      title: "Unable to pay for bid  - lodgement",
      name: "notification_Business_upbl",
    },
    {
      title: "Unable to pay for bid - resolution",
      name: "notification_Business_upfbr",
    },
    {
      title: "Promo not applied despite payment  - lodgement",
      name: "notification_Business_pnadpl",
    },
    {
      title: "Promo not applied despite payment - resolution",
      name: "notification_Business_pnaddpr",
    },
    {
      title: "Payment not received - lodgement",
      name: "notification_Business_pnr",
    },
    {
      title: "Payment not received- resolution",
      name: "notification_Business_pnrr",
    },
    {
      title: "Bid Cancellation Fee  - lodgement",
      name: "notification_Business_bcfl",
    },
    {
      title: "Bid Cancellation Fee- resolution",
      name: "notification_Business_bcfr",
    },
    {
      title: "Complaint–General - lodgement",
      name: "notification_Business_cgl",
    },
    {
      title: "General -  resolution ",
      name: "notification_Business_gr",
    },
    {
      title: "Request – Discontinuation of Membership ",
      name: "notification_Business_orps",
    },
    {
      title: "Request – General- lodgement ",
      name: "notification_Business_rgl",
    },
    {
      title: "Request – General- resolution ",
      name: "notification_Business_rgr",
    },
    {
      title: "Complaint – CROPs not redeemed - lodgement ",
      name: "notification_Business_cnrl",
    },
    {
      title: "Complaint – CROPs not redeemed - resolution ",
      name: "notification_Business_cnrr",
    },
    {
      title: "Complaint – CROPs not offered - Order- lodgement ",
      name: "notification_Business_cnool",
    },
    {
      title: "Complaint – CROPs not offered - Order - resolution ",
      name: "notification_Business_cnoor",
    },
    {
      title: "Complaint – No dates available for marketing offer - lodgement ",
      name: "notification_Business_ndafmol",
    },
    {
      title: "Complaint – No dates available for marketing offer - resolution ",
      name: "notification_Business_ndafmor",
    },
    {
      title: "Complaint – CROPs offered does not match order value  - lodgement ",
      name: "notification_Business_codnmovl",
    },
    {
      title: "Complaint – CROPs offered does not match order value - resolution ",
      name: "notification_Business_codnmovr",
    },
    {
      title: "Complaint – CROPs wrongly offered - lodgement ",
      name: "notification_Business_cwol",
    },
    {
      title: "Complaint – CROPs wrongly offered - resolution ",
      name: "notification_Business_cwor",
    },
    {
      title: "Complaint – CROPs wrongly redeemed - lodgement ",
      name: "notification_Business_cwrl",
    },
    {
      title: "Complaint – CROPs wrongly redeemed - resolution",
      name: "notification_Business_cwrr",
    },
    {
      title: "Complaint – CROPs redeemed does not match redemption value - lodgement ",
      name: "notification_Business_crdnmrvl",
    },
    {
      title: "Complaint – CROPs redeemed does not match redemption value - resolution",
      name: "notification_Business_crdnmrvr",
    },
    {
      title: "Complaint – Invoice not received  - lodgement ",
      name: "notification_Business_inrl",
    },
    {
      title: "Complaint – Invoice not received - resolution",
      name: "notification_Business_inrr",
    },
    {
      title: "Complaint – Verification code not received - lodgement ",
      name: "notification_Business_vcnrl",
    },
    {
      title: "Complaint – Verification code not received - resolution ",
      name: "notification_Business_vcnrr",
    },
     {
      title: "Complaint – Complaint lodged but not resolved - lodgement ",
      name: "notification_Business_clbnrl",
    },
    {
      title: "Complaint – Complaint lodged but not resolved - resolution ",
      name: "notification_Business_clbnrr",
    },
    {
      title: "Complaint – Offer not top listed in redemption - lodgement",
      name: "notification_Business_ontlirl",
    },
    {
      title: "Complaint – Offer not top listed in redemption - resolution ",
      name: "notification_Business_ontlirr",
    },
    {
      title: "Complaint – Notification of customer order not received  - lodgement ",
      name: "notification_Business_noconrl",
    },
    {
      title: "Complaint – Notification of customer order not received - resolution ",
      name: "notification_Business_noconrr",
    },
    {
      title: "Complaint – Bid submitted but payment notification not received  - lodgement",
      name: "notification_Business_bsbpnnrl",
    },
    {
      title: "Complaint – Bid submitted but payment notification not received- resolution ",
      name: "notification_Business_bsbpnnrr",
    },
    {
      title: "Complaint – Daily sales report not received - lodgement",
      name: "notification_Business_dsrnrl",
    },
    {
      title: "Complaint – Daily sales report not received - resolution ",
      name: "notification_Business_dsrnrr",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="UPDATE"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const AdminNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const Admin_settings = [
    {
      title: "Account Pre-Suspension - On Pre-Suspension",
      name: "notification_admin_aps",
    },
    {
      title: "Account Suspension - Post 24 hours of Pre-Suspension ",
      name: "notification_admin_asph",
    },
    {
      title: "Account Restoration - On Restoration",
      name: "notification_admin_aror",
    },
    {
      title: "Account Pre-Suspension - Business -	On Pre-Suspension",
      name: "notification_admin_apsbops",
    },
    {
      title: "Account Suspension - Business - Post 24 hours of Pre-Suspension ",
      name: "notification_admin_asphops",
    },
    {
      title: "Account Pre-Suspension - Business -	On Restoration",
      name: "notification_admin_apsbor",
    },
    {
      title: "Bid Cancellation - Post payment Within 7 days of publication",
      name: "notification_admin_bcppw",
    },
    {
      title: "Business Bid Cancellation – Post Payment - On Bid Cancellation within 48 hours of publication",
      name: "notification_admin_obcwhop",
    },
    {
      title: "Order Cancellation Approval by Business	On approval of cancellation ",
      name: "notification_admin_ocabboaoc",
    },
    {
      title: "Order Return Processed by Business	On completion of order return process",
      name: "notification_admin_orpbbocoorp",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(Admin_settings)}

      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="UPDATE"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
const AdminPaymentNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const Admin_payment_settings = [
    {
      title: "Customer Order Payment- On Order Payment",
      name: "notification_admin_bcppwhop",
    },
    {
      title: "Business Bid Payment - On Bid Payment",
      name: "notification_admin_bcror",
    },
    {
      title: "Business Offer Design Payment - On Offer Design Payment",
      name: "notification_admin_trcuoa",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(Admin_payment_settings)}

      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="UPDATE"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const AdminComplaintNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const Admin_payment_settings = [
    {
      title: "Customer Complaint	On Complaint Lodgement",
      name: "notification_admin_ccocl",
    },
    {
      title: "Customer Request	On Request Lodgement",
      name: "notification_admin_crorl",
    },
    {
      title: "Business Complaint	On Complaint Lodgement",
      name: "notification_admin_bcocl",
    },
    {
      title: "Business Request	On Request Lodgement",
      name: "notification_admin_brorl",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(Admin_payment_settings)}

      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="UPDATE"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};


// export default CustomerNotification;
export {
  BusinessNotification,
  BusinessPaymentNotification,
  BusinessRequestNotification, CustomerNotification,
  CustomerPurchaseNotification,
  CustomerRequestNotification, 
  AdminNotification, 
  AdminPaymentNotification,
  AdminComplaintNotification
};

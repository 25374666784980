import { useEffect, useState } from 'react';
import { SmartSoftCheckRadioSwitch } from "soft_digi";
import { ADMIN_SETTINGS } from '../../../api/Admin/OperationsUrl';
import SmartAccordion from '../../../components/site/FormElements/SmartAccordion';
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from '../../../services/notifyService';
import { getSessionStorage, setSessionStorage } from '../../../services/sessionService';
import { get, post } from '../../../services/smartApiService';
import {
  AdminComplaintNotification,
  AdminNotification,
  AdminPaymentNotification,
  BusinessNotification, BusinessPaymentNotification, BusinessRequestNotification,
  CustomerNotification, CustomerPurchaseNotification,
  CustomerRequestNotification
} from './CustomerNotification';

const NotificationConfig = () => {
  const { setLoading,  } = useSiteContext();
  const [category, setCategory] = useState(null);
  const [data, setTabData] = useState([]);
  const [formData, setFormData] = useState({});
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


 

  // useEffect(() => {
  //   loadTableData();
  // }, [category]);

  useEffect(() => {
    let categeory_session = getSessionStorage("crop_admin_n_cat");
    if (!categeory_session) {
      categeory_session = "1";
    }
    setCategory(categeory_session);
  }, []);

  useEffect(() => {
    if (category) {
    //  loadTableData();
      setSessionStorage("crop_admin_n_cat", category);

    }
  }, [category]);



  const handleSubmit = () => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Details Submitting....Please Wait");
    let url = ADMIN_SETTINGS.INSERT;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Data Saved Successfully", "success");
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const loadSettingsData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = get(
      ADMIN_SETTINGS.GET_ALL,
      handleError
    ).subscribe((response) => {
      setFormData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadSettingsData();
  }, []);

  // 
  const getFormElements = (settings) => {
    let formElements = [];
    for (let i = 0; i < settings.length; i++) {
      formElements.push({
        type: "TEXTAREA",
        width: "6",
        name: settings[i].name,
        element: {
          label: settings[i].title,       
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          rows:4
        },
      })
    }
    return formElements;
  }

  const MyOfferSwitch = () => {
    const Customer_Data = [{ value: "1", label: "Customer Notification" }];
    const Business_Data = [{ value: "2", label: "Business Notification" }];
    const Admin_Data = [{ value: "3", label: "Admin Notification" }];

    return (
      <>
        <div className="is-flex crop-admin-table-title pl-6 py-4">
          <div className="my-offer-month-switc mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_Data}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true, }}
              name="switchone"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_Data}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true, }}
              name="switch"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
          <div className="my-offer-year-switch ml-5">
            <SmartSoftCheckRadioSwitch
              options={Admin_Data}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true, }}
              name="switchtwo"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
        </div>
      </>
    );
  };
  const data_one = [
    {
      title: "Account Notification",
      body: <CustomerNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
      // body:<BusinessNotification />
    },
    {
      title: "Purchase and Redemption Notification",
      body: <CustomerPurchaseNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    },
    {
      title: "Request and Complaint Notification",
      body: <CustomerRequestNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    },

  ]

  const business_notification = [
    {
      title: "Account Notification",
      body: <BusinessNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    },
    {
      title: "Invoice and Payment Notifications",
      body: <BusinessPaymentNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    },
    {
      title: "Request and Complaint Notifications",
      body: <BusinessRequestNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    },
  ]

  const admin_notification = [
  {
    title: "Request & Complaint Notifications - Admin",
    body: <AdminComplaintNotification getFormElements={getFormElements}
      formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
  },
  {
    title: "Payment Notifications - Business",
    body: <AdminPaymentNotification getFormElements={getFormElements}
      formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
  },
     {
      title: "Other Notifications - Admin",
      body: <AdminNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
  } ,
]

  return (
    <div className='smart-crop-admin-container '>
    
      {MyOfferSwitch()}
    
    
      {category === "1" && <SmartAccordion key="customer" data={data_one}
        class_name="crop-admin-accord-card mb-5" />}
      {category === "2" && <SmartAccordion key="business" data={business_notification}
        class_name="crop-admin-accord-card mb-5" />
      }
      {category === "3" && <SmartAccordion key="business" data={admin_notification}
        class_name="crop-admin-accord-card mb-5" />
      }
    </div>
  )
}

export default NotificationConfig
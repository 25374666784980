import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftCheckRadioSwitch, SmartSoftTable } from "soft_digi";
import { SERVICES_COMPLAINT_URLS } from "../../../../api/Services/ServiceUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import ComplaintForm from "./ComplaintForm";
import UserProfile from "../../Database/UserProfile";
import {
  encrypt_data,
  getSessionStorage,
  setSessionStorage,
} from "../../../../services/sessionService";

const CompaintTable = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [tabData, setTabData] = useState([]);

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const MyOfferSwitch = () => {
    // const [formData, setFormData] = useState({});
    // const [formSubmit, setFormSubmit] = useState(false);
    // const [formErrors, setFormErrors] = useState(false);
    const Customer_complaint = [
      { value: "1", label: "Customer Request & Complaint" },
    ];
    const Business_complaint = [
      { value: "2", label: "Business Request & Complaint" },
    ];

    // const handleInputChange = (name, value) => {
    //   setFormData((prev) => ({ ...prev, [name]: value }));
    // };
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_complaint}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true }}
              name="switchone"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_complaint}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true }}
              name="switch"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
        </div>
      </>
    );
  };

  const { setLoading, openModal } = useSiteContext();

  const viewEditForm = (id, action_name) => {
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = post(
      SERVICES_COMPLAINT_URLS.GET_ONE,
      { id: id },
      handleError
    ).subscribe((response) => {
      // console.log("response ", response);
      if (response.data.complaint_status != "CLOSED") {
        response.data.complaint_status =
          response.data.complaint_status == "OPEN"
            ? { value: "OPEN", label: "Open" }
            : { value: "PROGRESS", label: "In Progress" };
      }
      openNewForm(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const openNewForm = (data = {}) => {
    let modalObject = {
      body: <ComplaintForm loadTableData={loadTableData} dataIn={data} />,
      modalClass: "crop-admin-modal smart-modal-50",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const openProfileForm = (data = {}) => {
    // console.log(data);
    let dataIn = { ID: data.complaint_created_by };
    let modalObject = {
      body: <UserProfile dataIn={dataIn} />,
      modalClass: "crop-admin-modal smart-modal-80",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const buttons_profile = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-user-circle-o ",
      classList: ["smart-edit-icon"],
      onClick: openProfileForm,
    },
  ];

  // const nameFunction = (row) => {
  //   return (
  //     <>
  //       <div
  //         className="is-flex is-clickable has-text-info"
  //         onClick={() => viewEditForm(row["ID"])}
  //       >
  //         <figure>{row["complaint_id"] || ""}</figure>
  //       </div>
  //     </>
  //   );
  // };

  const nameFunction = (row) => {
    const isClosed = row["complaint_status"] === "CLOSED";

    return (
      <>
        <div
          className={`is-flex ${
            isClosed ? "has-text-black" : "is-clickable has-text-info"
          }`}
          style={{ cursor: isClosed ? "default" : "pointer" }}
          onClick={() => {
            if (!isClosed) {
              viewEditForm(row["ID"]);
            }
          }}
        >
          <figure>{row["complaint_id"] || ""}</figure>
        </div>
      </>
    );
  };

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setTabData([]);
      setLoading(false);
    };
    let url = SERVICES_COMPLAINT_URLS.GET_ALL_ADMIN;
    let post_data = {
      complaint_mode: parseInt(category) === 1 ? "CUSTOMER" : "BUSINESS",
    };
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        // console.table("testing", response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    let categeory_session = getSessionStorage("crop_admin_complaint_cat");
    if (!categeory_session) {
      categeory_session = "1";
    }
    setCategory(categeory_session);
  }, []);
  useEffect(() => {
    if (category) {
      loadTableData();
      setSessionStorage("crop_admin_complaint_cat", category);
    }
  }, [category]);

  const dateValue = (row) => {
    // console.log(row)
    // console.log(row.complaint_action_date)
    let value = row.complaint_action_date;
    let formatValue =
      row.complaint_action_date != null
        ? moment(value).format("DD-MM-YYYY")
        : "-";
    return <div>{formatValue}</div>;
  };

  const showStatus = (item) => {
    return (
      <>
        <div>
          {item.complaint_status == "PROGRESS"
            ? "IN PROGRESS"
            : item.complaint_status}
        </div>
      </>
    );
  };

  const profile_function = (data) => {
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <>
        <div
          className="is-flex is-clickable has-text-info"
          onClick={() => openProfileForm(data)}
        >
          {data["first_name"] || ""}
        </div>
      </>
    );
  };

  const business_profile_function = (data) => {
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <>
        <div
          className="is-flex is-clickable has-text-info"
          onClick={() => openProfileForm(data)}
        >
          {data["business_name"] || ""}
        </div>
      </>
    );
  };

  const get_table_columns = () => {
    switch (category) {
      case "1":
        return columnsOne;
      default:
        return columnsTwo;
    }
  };

  const columnsOne = [
    { title: "CROP ID", index: "crop_id", width: "10" },
    {
      title: "CUSTOMER NAME",
      index: "first_name",
      valueFunction: profile_function,
      width: "15",
    },
    // {
    //   title: "PROFILE",
    //   index: "profileline",
    //   type: "buttons",
    //   buttons: buttons_profile,
    //   width: "5"
    // },

    //  { title: "TYPE", index: "comp_type" ,width: ""},
    { title: "CATEGORY", index: "comp_type", width: "" },
    { title: "DESCRIPTION", index: "complaint_description", width: "" },
    {
      title: "NUMBER",
      index: "complaint_id",
      valueFunction: nameFunction,
      width: "10",
    },
    {
      title: "DATE RAISED",
      index: "complaint_date",
      type: "date",
      dateFormat: "DD-MM-YYYY",
      class: ["m-0"],
      width: "10",
    },

    { title: "PCM", index: "pcm", width: "" },
    {
      title: "ACTIONED",
      index: "complaint_action_date",
      // type: "date",
      // dateFormat: "DD/MM/YYYY",
      valueFunction: dateValue,
      width: "10",
    },
    {
      title: "STATUS",
      index: "complaint_status",
      valueFunction: showStatus,
      width: "10",
    },
    { title: "RESPONSE", index: "complaint_resolution" },
  ];

  const columnsTwo = [
    { title: "CROP ID", index: "crop_id", width: "10" },
    {
      title: "BUSINESS NAME",
      index: "business_name",
      valueFunction: business_profile_function,
      width: "15",
    },
    // {
    //   title: "PROFILE",
    //   index: "profileline",
    //   type: "buttons",
    //   buttons: buttons_profile,
    //   width: "5"
    // },

    //  { title: "TYPE", index: "comp_type" ,width: ""},
    { title: "CATEGORY", index: "comp_type", width: "" },
    { title: "DESCRIPTION", index: "complaint_description", width: "" },
    {
      title: "NUMBER",
      index: "complaint_id",
      valueFunction: nameFunction,
      width: "10",
    },
    {
      title: "DATE RAISED",
      index: "complaint_date",
      type: "date",
      dateFormat: "DD-MM-YYYY",
      class: ["m-0"],
      width: "10",
    },

    // { title: "PCM", index: "" },
    { title: "PCM", index: "pcm", width: "" },
    {
      title: "ACTIONED",
      index: "complaint_action_date",
      // type: "date",
      // dateFormat: "DD/MM/YYYY",
      valueFunction: dateValue,
      width: "10",
    },
    {
      title: "STATUS",
      index: "complaint_status",
      valueFunction: showStatus,
      width: "10",
    },
    { title: "RESPONSE", index: "complaint_resolution" },
  ];

  // const CustomerComplaintData = [
  //   {
  //     number: "R-8070707",
  //     dateraised: "2023/09/26",
  //     type: "Appliction Is Very Slow",
  //     details: "Performance",
  //     pcm: "App",
  //     actioned: "2023/09/26",
  //     status: "Closed",
  //     response: "Application Number is closed",
  //   },
  //   {
  //     number: "R-8070707",
  //     dateraised: "2023/09/26",
  //     type: "Asd",
  //     details: "Complaint",
  //     pcm: "9807654321",
  //     actioned: "2023/09/26",
  //     status: "Progress",
  //     response: "",
  //   },
  //   {
  //     number: "R-9279456",
  //     dateraised: "2023/09/26",
  //     type: "Asdfg",
  //     details: "Complaint",
  //     pcm: "9807654321",
  //     actioned: "2023/09/26",
  //     status: "Open",
  //     response: "",
  //   },
  // ];

  // const BusinessComplaintData = [
  //   {
  //     number: "R-8070707",
  //     dateraised: "2023/09/26",
  //     type: "Appliction Is Very Slow",
  //     details: "Performance",
  //     pcm: "App",
  //     actioned: "2023/09/26",
  //     status: "received",
  //     response: "Thank You",
  //   },
  // ];
  return (
    <>
      <div className="smart-crop-admin-container">
        <div key={"CompaintTablecolumnsone"} className="columns">
          <div key={"CompaintTablecolumnone"} className="column">
            <div key={"CompaintTablecardone"} className="card">
              <div
                key={"CompaintTablecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"CompaintTablecolumntwo"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"CompaintTablecolumnthree"}
                  className="column is-8 crop-admin-table-title"
                >
                  {MyOfferSwitch()}
                </div>

                <div
                  key={"CompaintTablecolumnfour"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={get_table_columns()}
                data={tabData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompaintTable;

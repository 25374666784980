const MANAGE_OFFER_URLS = {
    INSERT:"/business/offers/insert",
    GET_ALL:"/business/offers/get_all_business",
    UPDATE:"/business/offers/get_one",
    GET_ONE:"/business/offers/get_one",
    DELETE:"/business/offers/delete_one",
    DELETE_PRODUCT:"/business/offers/soft_delete",
    INSERT_BID:"/business/market_offers/insert",
    GET_ALL_BID:"/business/market_offers/get_all",
    GET_ALL_SELECT_WITH_USERID : "/business/offers/get_all_select_with_userid"
   
}

const MANAGE_OFFER_IMAGE_URLS = {
    INSERT:"/business/offers_images/insert",
    GET_ALL:"/business/offers_images/get_all",
    UPDATE:"/business/offers_images/update",
    GET_ONE:"/business/offers_images/get_one",
    DELETE:"/business/offers_images/delete_one"
}


const OFFER_LIKES_URLS={
    INSERT:"/business/likes/insert",
    GET_ALL_USER:"/business/likes/get_all_user",
    GET_ALL_OFFER:"/business/likes/get_all_offer",
}

const OFFER_RATINGS_URLS={
    INSERT:"/business/ratings/insert",
    GET_ALL_USER:"/business/ratings/get_all_user",
    GET_ALL_OFFER:"/business/ratings/get_all_offer",
}




export {
    MANAGE_OFFER_IMAGE_URLS,
    MANAGE_OFFER_URLS,
    OFFER_LIKES_URLS,
    OFFER_RATINGS_URLS
}
// import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AdminSideNav = ({ page }) => {
  // const [sidekey, setSideKey] = useState("1");


  const navigate = useNavigate();
  const location = useLocation();

  // const setKey = (index) => {
  //   //console.log("side index " , index)
  //   setSideKey(index);
  // };




  const navs = [
    {
      id: 1,
      label: "Dashboard",
      icon: <i className="fa fa-home" aria-hidden="true"></i>,
      link: "/dashboard",
    },
    // {
    //   id: 2,
    //   label: "Account Settings",
    //   icon: <i className="fa fa-user-circle" aria-hidden="true"></i>,
    //   link: "/account-settings/account",
    // },
  ];

  const second_navs = [
    {
      id: 1,
      label: "Database Management",
      icon: <i className="fa fa-database" aria-hidden="true"></i>,
      link: "/database/database_management-list",
    },
    // {
    //   id: 2,
    //   label: "Account Management",
    //   icon: <i className="fa fa-pie-chart" aria-hidden="true"></i>,
    //   link: "/account-management/transaction-list",
    // },
    {
      id: 3,
      label: "Operations Management",
      icon: <i className="fa fa-american-sign-language-interpreting" aria-hidden="true"></i>,
      link: "/operations/home",
    },
    {
      id: 4,
      label: "Notifications",
      icon: <i className="fa fa-bell" aria-hidden="true"></i>,
      link: "/notification",
    },
    
    {
      id: 5,
      label: "Commercials",
      icon: <i className="fa fa-users" aria-hidden="true"></i>,
      link: "/commercial",
    },
    // {
    //   id: 6,
    //   label: "Marcom",
    //   icon: <i className="fa fa-commenting" aria-hidden="true"></i>,
    //   link: "/marcom",
    // },
  
  ];

  const handleNavigation = (link) => {
    navigate(link);
  };

  const mainNavs=()=>{
    return (
      <>
           <div className="crop-admin-side-nav-menu first-menu mt-5">
        <ul className="menu-list">
          {navs.map((item, key) => (
            <li
              key={key}
              onClick={() => handleNavigation(item?.link)}
              className={
                location.pathname === item.link
                  ? "list-item-active"
                  : "list-items "
              }
            >
              <span className="is-flex">
                <span className="icon is-size-6">{item.icon}</span>
                <span className="is-size-7 mt-1">{item.label}</span>
              </span>
            </li>
          ))}
        </ul>
      </div>
      </>
    )
  }

  const secondNavs=()=>{
    return (
      <>
           <div className="crop-admin-side-nav-menu mt-5">
        <ul className="menu-list">
          {second_navs.map((item, key) => (
            <li
              key={key}
              onClick={() => handleNavigation(item?.link)}
              className={
                location.pathname === item.link
                  ? "list-item-active"
                  : "list-items "
              }
            >
              <span className="is-flex">
                <span className="icon is-size-6">{item.icon}</span>
                <span className="is-size-7 mt-1">{item.label}</span>
              </span>
            </li>
          ))}
        </ul>
      </div>
      </>
    )
  }

  return (
    <>
      {mainNavs()}
      <div className="crop-admin-divider">
          <div className="side-nav-page-text">Pages</div>
      </div>
      {secondNavs()}
    </>
  );
};

export default AdminSideNav;


// import { } from "react";
import { SmartSoftTable } from "soft_digi";




const RatingsTable = () => {






    const buttons = [
        { label: "Balaji", type: "icon", leftIcon: "fa fa-star has-text-warning-dark" },
        { label: "Balaji", type: "icon", leftIcon: "fa fa-star has-text-warning-dark" },
        { label: "Balaji", type: "icon", leftIcon: "fa fa-star has-text-warning-dark" },
        { label: "Balaji", type: "icon", leftIcon: "fa fa-star has-text-warning-dark" },
        { label: "Balaji", type: "icon", leftIcon: "fa fa-star has-text-warning-dark" },
    ];

    const columns = [
        { title: "SECTOR", index: "sectorline", },
        { title: "OFFER", index: "prodectline", },
        { title: "TITLE", index: "titleline", },
        { title: "RATED", index: "ratedline", type: "buttons", buttons: buttons },

    ];

    const data = [
        { sectorline: 'Shops', prodectline: " ", titleline: "1000", },
        { sectorline: 'Shops', prodectline: " ", titleline: "1000", },
        { sectorline: 'Shops', prodectline: " ", titleline: "1000", },
    ]
    return (
        <>
            <div className="RatingsTable">
                <div  key={"RatingTableFirstColumns"}className="columns">
                    <div  key={"RatingTableFirstColumn"} className="column ">
                        <div className="card">
                            <div className="smart-crop-admin-container">
                                <div key={"RatingTableSecondColumns"} className="columns is-vcentered">
                                    <div key={"RatingTableSecondColumn"}className="column is-1 crop-admin-table-back">
                                        {/* <span className="icon" onClick={() => navigateLink("")}>
                                            <i className="fa fa-undo" aria-hidden="true"></i>
                                        </span> */}
                                    </div>
                                    <div key={"RatingTableThirdColumn"}className="column is-8 crop-admin-table-title">
                                        Ratings
                                    </div>
                                    <div key={"RatingTableFourthColumn"} className="column is-3 has-text-right crop-table-top-pagination">
                                        <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <SmartSoftTable columns={columns} data={data} tableProps={{ className: "crop-table-layout" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RatingsTable
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { DATABASE_URLS } from "../../../api/Admin/AurhUrls";
import NoResultComponents from "../../../components/site/NoResultComponents";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { decrypt_data } from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";

const CropsTable = () => {
  const { id } = useParams();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  const [tabData, setTabData] = useState([]);
  const { setLoading } = useSiteContext();
  const navigate = useNavigate();

  const baseNav = "/database";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };
  const loadTableData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = {
      id: decrypted_id,
    };
    let url = DATABASE_URLS.GET_ALL_CROPS;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        // console.log(response.data);
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const debitValue = (row) => {
    return row.sd_transaction_type === "DEBIT" ? row.sd_crop_points : "";
    // st_transaction_type
  };
  const creditValue = (row) => {
    return row.sd_transaction_type === "CREDIT" ? row.sd_crop_points : "";
  };
  const columns = [
    {
      title: "", index: "", width:"5",
    },
    {
      title: "DATE",
      index: "sd_last_modified_time",
      type: "date",
      dateFormat: "DD-MM-YYYY",
    },
    { title: "ORDER NUMBER", index: "order_id" },
    { title: "AMOUNT", index: "offer_total" },
    { title: "DESCRIPTION", index: "sd_crop_type" },
    //{ title: "DESCRIPTION", index: "descriptionline" },
    { title: "REDEEMED", index: "debit", valueFunction: debitValue },
    { title: "OFFERED", index: "credit", valueFunction: creditValue },
  ];

  return (
    <div className="smart-crop-admin-container">
      <div key={"CropsTablecolumnsone"} className="columns">
        <div key={"CropsTablecolumnone"} className="column">
          <div key={"CropsTablecardone"} className="card">
            <div key={"CropsTablecolumnstwo"} className="columns is-vcentered">
              <div
                key={"CropsTablecolumntwo"}
                className="column is-1 crop-admin-table-back"
              >
                <span
                  className="icon"
                  onClick={() => navigateLink("database_management-list")}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i>
                </span>
              </div>
              <div
                key={"CropsTablecolumnthree"}
                className="column is-8 crop-admin-table-title"
              >
                 CROP Details
              </div>
              <div
                key={"CropsTablecolumnfour"}
                className="column is-3 has-text-right crop-table-top-pagination"
              >
                {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                  <i className="fa fa-caret-right" aria-hidden="true"></i> */}
              </div>
            </div>
            <SmartSoftTable
              columns={columns}
              data={tabData}
              tableProps={{ className: "crop-table-layout" }}
            />
            {tabData&&tabData.length<1&&( <NoResultComponents title={"No Results Found"}  />)} 
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropsTable;

import { useEffect, useState } from "react";
import { useSiteContext } from "../../../contexts/SiteProvider";

import { DATABASE_URLS } from "../../../api/Admin/AurhUrls";
import SmartImageDisplay from "../../../components/site/FormElements/SmartImageDisplay";
import {
  ADMIN_USER_LOGO,
  // ADMIN_USER_LOGO,
  CROP_ADMIN_PROFILE_IMAGE,
} from "../../../services/ImageService";
import { changeDateTimeZoneFormat } from "../../../services/core/CommonService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
// import SmartImageDisplay from "../../../components/site/FormElements/SmartImageDisplay";

const UserProfile = ({ dataIn }) => {
  const [formData, setFormData] = useState(null);
  // const [formSubmit, setFormSubmit] = useState(false);
  // const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  // const navigate = useNavigate();

  // const handleInputChange = (name, value) => {
  //   //console.log(" name " ,name , "   value " , value)
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };
  // const handleErrorChange = (name, value) => {
  //   setFormErrors((prev) => {
  //     // Create a copy of the previous state
  //     const updatedFormData = { ...prev };
  //     // Check if the value is null or empty
  //     if (value === null || value === "") {
  //       // If true, remove the property from the updated state
  //       delete updatedFormData[name];
  //     } else {
  //       // Otherwise, update or add the property in the updated state
  //       updatedFormData[name] = value;
  //     }

  //     return updatedFormData;
  //   });
  // };
  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { id: dataIn.ID };
    let url = DATABASE_URLS.GET_ALL_PROFILE;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        console.log(response.data);
        setFormData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const getValue = (index) => {
    // console.log("form data " , formData);
    // console.log("index = " , index , "   " , formData[index]);
    let output = formData[index] !== undefined ? formData[index] : "";
    if (index === "age") {
      output = changeDateTimeZoneFormat(output, "DD/MM");
    }
    if (index == "age_group") {
      output = output.label !== undefined ? output.label : "";
    }
    //console.log(output)
    return output instanceof Object || Array.isArray(output) ? "" : output;
  };

  const address_data = () => {
    let address = formData.addrData ? formData.addrData : {};
    let output = [];
    //console.log(address.state.value)
    output.push({
      label: address.addr_one ? "Line-1: " + address.addr_one : "",
    });
    output.push({
      label: address.addr_two ? "Line-2: " + address.addr_two : "",
    });
    output.push({
      label: address.city ? "Line-3: " + address.city : "",
    });
    output.push({
      label: address?.state?.value ? "State: " + address?.state?.value : "",
    });
    output.push({
      label: address.pincode ? "Pin: " + address.pincode : "",
    });
    return output;
  };

  const getArrayValue = (index) => {
    if (index === "address") {
      return address_data();
    }
    let output = formData[index] !== undefined ? formData[index] : "";
    return Array.isArray(output) ? output : [];
  };

  const smallBox = (item, index) => {
    return (
      <div key={index} className="column is-3 is-size-7">
        <div className="inputbox-type-header-UserProfile">
          <span className="inputbox-type-header-UserProfile-position ">
            {item.title}
          </span>
          <span className="has-text-black textposition-UserProfile">
            {getValue(item.index)}
          </span>
        </div>
      </div>
    );
  };

  const bigBox = (item, index) => {
    let value = getArrayValue(item.index);
    //console.log("index ", item.index , " value ", value);
    if (!Array.isArray(value)) {
      value = [];
    }
    return (
      <div
        key={"UserProfilecolumneight" + index}
        className="column is-3 mt-1  is-size-7"
      >
        <div className="textareatype-UserProfile-size">
          <span className="input-type-name-texterea">{item.title}</span>
          <span className="has-text-black pl-3">
            {item.index !== "address" && (
              <ol className="ml-5">
                {value.map((obj, index) => (
                  <li className="has-text-left" key={index}>
                    {obj.label}
                  </li>
                ))}
              </ol>
            )}
            {item.index == "address" &&
              value.map((obj, index) => (
                <p className="has-text-left ml-1" key={index}>
                  {obj.label}
                </p>
              ))}
          </span>
        </div>
      </div>
    );
  };

  const costomer = [
    { title: "Customer Name", index: "user_name", key: "1" },
    { title: "CROP ID", index: "crop_id", key: "2" },
    { title: "Gender", index: "gender", key: "3" },
    { title: "DOB", index: "age", key: "4" },
    { title: "Age Group", index: "age_group", key: "5" },
    { title: "Mobile Number", index: "mobile_no", key: "7" },
    { title: "Email ID", index: "email_id", key: "9" },
    { title: "Marketing Notification", index: "marketting", key: "6" },
    { title: "Newsletter Status", index: "newsletter", key: "8" },
    { title: "Loyalty", index: "loyalty", key: "6", box: true },
    { title: "Interest", index: "interest", key: "16", box: true },
    { title: "Address", index: "address", key: "17", box: true },
  ];

  const business = [
    { title: "Business Name", index: "business_name", key: "1" },
    { title: "Owner's Name", index: "user_name", key: "1" },
    { title: "CROP ID", index: "crop_id", key: "2" },
    { title: "Mobile Number", index: "mobile_no", key: "7" },
    { title: "Email ID", index: "email_id", key: "9" },
    { title: "Marketing Notification", index: "marketting", key: "6" },
    { title: "Newsletter Status", index: "newsletter", key: "8" },
    { title: "Loyalty", index: "loyalty", key: "6", box: true },
    { title: "Interest", index: "interest", key: "16", box: true },
    { title: "Address", index: "address", key: "17", box: true },
  ];

  const details_display = () => {
    let settings =
      formData.user_type && formData.user_type == 10 ? business : costomer;
    return (
      <>
        <div className="columns is-multiline mt-3">
          {settings.map((item, index) => {
            return item.box ? "" : smallBox(item, index);
          })}
        </div>
        <div className="columns is-multiline">
          {settings.map((item, index) => {
            return item.box ? bigBox(item, index) : "";
          })}
        </div>
      </>
    );
  };

  return (
    <div key={"UserProfilecolumnsthree"} className="columns">
      <div className="crop-admin-form  smart-admin-crop-container">
        <div
          key={"UserProfilecolumnfour"}
          className="column is-12 has-text-centered "
        >
          <div className="admin-database-profile-images">
            <div className=" image database-profile-image">
              <img
                className="database-profile "
                src={CROP_ADMIN_PROFILE_IMAGE}
                alt=""
              />
            </div>
            <div className="admin-database-user-image-display ">
              <SmartImageDisplay
                srcType="data"
                data={formData?.profile_image?.content}
                imageClass="is-rounded admin-database-user-image"
                default_img={ADMIN_USER_LOGO}
              />
              <figure className="image is-128x128 ">
                {/* <img
                      className="is-rounded admin-account-image database-user-image"
                     src={ADMIN_USER_LOGO}
                      // src={formData?.profile_image?getImageContent(formData?.profile_image):""}
                      alt=""
                    /> */}
              </figure>
            </div>
            <div className="is-flex is-justify-content-center  mt-6 is-size-4 has-text-weight-bold">
              Profile Details
            </div>
          </div>
          {formData && details_display()}
        </div>
      </div>

      <div key={"UserProfilecolumnten"} className="column">
        <div className="crop-admin-modal-close" onClick={closeModal}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  SmartSoftForm,
  // SmartSoftInput,
  // SmartSoftSelect,
  SmartValid,
} from "soft_digi";
import { useSiteContext } from "../../../../contexts/SiteProvider";
// import SmartSelect from "../../../../components/core/forms/SmartSelect";
import { COMPLAINT_FORM } from "../../../../services/ImageService";
// import { SERVICES_COMPLAINT_URLS } from "../../../../api/Services/ServiceUrls";
import { ADMIN_BIDSECTOR_SECTOR } from "../../../../api/Admin/OperationsUrl";
import { changeDateTimeZoneFormat } from "../../../../services/core/CommonService";
import {
  showAlertAutoClose,
} from "../../../../services/notifyService";
import {
  admin_business_offer_select,
  admin_business_select
} from "../../../../services/site/SelectBoxServices";
import { post } from "../../../../services/smartApiService";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";

const BidAdditionForm = ({ loadTableData, dataIn, sector }) => {
  const [formData, setFormData] = useState(dataIn ? dataIn : {});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  const [business, setBusiness] = useState([]);
  const [offers, setOffers] = useState([]);

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  //const [type, setType] = useState("password");

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "sd_mt_userdb_id") {
      admin_business_offer_select(value, (data) => setOffers(data));
    }
  };

  const handleSubmit = () => {
    setFormSubmit(true);
  
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setLoading(false);
    };
    setLoading(true, "Details Submitting....Please Wait");
    let _data = {...formData};
    _data["bid_date"] = changeDateTimeZoneFormat(_data.bid_date,"YYYY-MM-DD");
    const subscription = post(
      ADMIN_BIDSECTOR_SECTOR.INSERT_BID,
      _data,
      handleError
    ).subscribe((response) => {
      //console.log("response form ", response.data);
      loadTableData();
      showAlertAutoClose("Data Saved Successfully", "success");
      closeModal();
      // setUser(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const loadSelectOptions = () => {
    console.log(sector)
    let dataIn={
      sector:sector
    }
    admin_business_select((data) => setBusiness(data),dataIn);
  };

  useEffect(() => {
    loadSelectOptions();
  }, []);

  const BidAdditionFormValidations = {
    Business: [SmartValid.required("Business is Required")],
    Offer: [SmartValid.required("Offer is Required")],

    // SmartValid.minLength("Number should be minimum 10 digit", 9),
  };

  const formElements = [
    {
      type: "DATE",
      width: "6",
      name: "bid_date",
      element: {
        label: "Selected Date",
        inputProps: { disabled: true },
        inputType: "BORDER_LABEL",
      },
    },
    {
      type: "SELECT_BOX",
      width: "6",
      name: "sd_mt_userdb_id",
      element: {
        label: "Select Business",
        placeHolder: "Type",
        inputType: "BORDER_LABEL",
        options: business,
         validations: BidAdditionFormValidations.Business,
      },
    },
    {
      type: "SELECT_BOX",
      width: "12",
      name: "sd_offers_id",
      element: {
        label: "Select Offer",
        placeHolder: "Select Offer",
        inputType: "BORDER_LABEL",
        options: offers,
         validations: BidAdditionFormValidations.Offer,
      },
    },
  ];

  return (
    <>
      <div key={"BidAdditionFormFirstcolumns"} className="columns">
        <div
          key={"BidAdditionFormFirstcolumn"}
          className=" column is-10 card crop-admin-modal-content crop-admin-form"
        >
          <div
            key={"BidAdditionFormSecondcolumns"}
            className="columns is-multiline"
          >
            <div className="column is-12 has-text-centered" key="1">
              <p className="crop-admin-form-title">Bid Addition Form</p>
            </div>
            <div
              key={"BidAdditionFormSecondcolumn"}
              className=" column is-12 p-5"
            >
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
                errorEnable={formSubmit}
                errorUpdate={(value) => handleErrorChange("euserid", value)}
                validations={BidAdditionFormValidations}
              />
            </div>
            <div
              key={"BidAdditionFormThirdcolumn"}
              className="column is-6 is-offset-3 has-text-centered"
            >
              <SmartSoftButton
                key="save"
                label={"ADD BID"}
                // leftIcon="fa-save"
                classList={["is-rounded crop-admin-action-button"]}
                onClick={handleSubmit}
              />
            </div>
            <div
              key={"BidAdditionFormFourthcolumn"}
              className="column is-12 has-text-centered"
            >
              <img src={COMPLAINT_FORM} alt="" />
            </div>
          </div>
        </div>
        <div key={"BidAdditionFormFifthcolumn"} className="column is-2">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default BidAdditionForm;

import { Route, Routes } from "react-router-dom";
import { AccountSecurity, AccountSettings } from '../../pages/Admin';

const AccountSettingsRoute = () => {
  return (
    <Routes>
       <Route path="/security" element={<AccountSecurity />} />  
       <Route path="/account" element={<AccountSettings />} />  
     
    </Routes>  
  )
}

export default AccountSettingsRoute
import { CROP_ADMIN_LAYOUT_MOON } from "../../services/ImageService";

const Toggle = ({ handleChange, isChecked }) => {
  return (
    <div className=" ml-5 ">
      {/* <input
        type="checkbox"
        id="toggle"
        className=""
        onChange={()=>handleChange()}
        checked={isChecked}
      /> */}
      <label htmlFor="toggle  ">
        <div onClick={()=>handleChange()}>
        <img className="pointer mt-4" src={CROP_ADMIN_LAYOUT_MOON} alt="" />
        </div>
        
      </label>
    </div>
  );
};

export default Toggle;

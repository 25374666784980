import { useEffect, useRef, useState } from "react";
import { SmartSoftButton } from "soft_digi";
import { SERVICES_NOTIFICATION_URLS } from "../../api/Services/ServiceUrls";
import SmartAccordion from "../../components/site/FormElements/SmartAccordion";
import { useSiteContext } from "../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../services/notifyService";
import { post } from "../../services/smartApiService";
import SingleNotification from "./SingleNotification";


const AdminNotifications = ({ noteData,  }) => {
  const [active, setActive] = useState(false);
  const ref = useRef(null);
  const { setLoading,refreshUser } = useSiteContext();
  //console.log("item " , noteData);
  const handleFormSubmit = () => {
    //console.log("formdata", formData);
    // setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      // showNotification("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Updating....Please Wait");
    let url = SERVICES_NOTIFICATION_URLS.MARK_ALL_READ;
    const subscription = post(url, {}, handleError).subscribe((response) => {
      showAlertAutoClose(response.data.msg, "success");
      setLoading(false);
      setActive(false)
      //loadProfileData();
      refreshUser();
      // navigate("/");
    });
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
    //  console.log("evenet resgistered " , ref.current , " event ", event.target );
      if (ref.current && !ref.current.contains(event.target)) {
       // console.log("event out");
        setActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const card_body = (index) => {
    return <SingleNotification type={index} />;
  };

  const data = [
    {
      title: "Account Notification",
      bodyFunction: () => card_body("ACCOUNT"),
    },
    {
      title: "Payment Notification",
      bodyFunction: () => card_body("PURCHASE"),
    },
    {
      title: "Request & Complaint Notification",
      bodyFunction: () => card_body("REQUEST"),
    },
    {
      title: "Other Notification",
      bodyFunction: () => card_body("OTHERS"),
    },
  
  ];

  const notificationCard = () => {
    return (
      <div className="card p-0" >
        <header className="card-header">
          <p className="card-header-title admin-notification-title">
            <span className="is-size-5 customer-primary-font ">Notifications</span>{" "}
            <span className="tag customer-notification-icons is-rounded ml-2">
              {noteData?.total}
            </span>
          </p>
          {/* <button className="card-header-icon" aria-label="more options">
            <span
              className="customer-notification-text"
              onClick={() => handleFormSubmit()}
            >
              Mark all as read
            </span>
            <span className="icon ">
              <i className="fa fa-ellipsis-v gray-color" aria-hidden="true"></i>
            </span>
          </button> */}
        </header>
        <div className="card-content p-0">
          <div className="content customer-notification-body">
            <SmartAccordion data={data} />
          </div>
          <div className="is-flex is-justify-content-center">
          <SmartSoftButton
                  label="Read All Notification"
                  classList={[
                    "smart-crop-admin-action-button",
                    "px-6 ",
                    // "mt-3",
                    // "mb-5",
                  ]}
                  onClick={() => handleFormSubmit()}
                />
          </div>
        
        </div>
      </div>
    );
  };

  return (
    <div ref={ref}
      className={
        "" + active ? "dropdown is-right  is-active" : "dropdown is-right "
      }
      key="s1"
    >
      <span
        className="icon dropdown-trigger customer-header-icon is-clickable"
        onClick={() => setActive((active) => !active)}
      >
        <i
          className="fa fa-bell"
          aria-hidden="true"
          aria-controls="dropdown-menu"
        ></i>
        {noteData?.total > 0 && (
          <div className="user-bade ">
            {/* <div className="user-bade-num"> */}
            {noteData?.total}
            {/* </div>  */}
            </div>
        )}
      </span>
      {active && (
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">{notificationCard()}</div>
        </div>
      )}
    </div>
  );
};

export default AdminNotifications;

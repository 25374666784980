import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SmartSoftCheckRadioSwitch,
  SmartSoftInput,
  SmartSoftTable,
} from "soft_digi";
import { USER_URLS } from "../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";

import NoResultComponents from "../../../components/site/NoResultComponents";
import { RATE_IMAGE, TRAIL_IMAGE } from "../../../services/ImageService";
import { filterIncludes } from "../../../services/core/FilterService";
import {
  encrypt_data,
  getSessionStorage,
  setSessionStorage,
} from "../../../services/sessionService";
import UserProfile from "./UserProfile";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";

const CustomerDataBusinessData = () => {
  const [category, setCategory] = useState(null);
  const [data, setTabData] = useState([]);
  const { setLoading, openModal } = useSiteContext();
  // const [filter, setFilter] = useState('');
  const [formData, setFormData] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { category: category };
    let url = USER_URLS.GET_ALL_ADMIN;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    let categeory_session = getSessionStorage("crop_admin_db_cat");
    if (!categeory_session) {
      categeory_session = "1";
      setFormData((prev) => ({ ...prev, search_value: "C-" }));
    }
    setCategory(categeory_session);
  }, []);

  useEffect(() => {
    if (category) {
      loadTableData();
      setSessionStorage("crop_admin_db_cat", category);
      setFormData((prev) => ({
        ...prev,
        search_value: category == 1 ? "C-" : "B-",
      }));
    }
  }, [category]);

  const openNewForm = (data = {}) => {
    // console.log(data);
    let modalObject = {
      body: <UserProfile dataIn={data} />,
      modalClass: "crop-admin-modal smart-modal-80",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const navigate = useNavigate();
  const baseNav = "/database";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const MyOfferSwitch = () => {
    const Customer_Data = [{ value: "1", label: "Customer Data" }];
    const Business_Data = [{ value: "2", label: "Business Data" }];

    // const handleInputChange = (name, value) => {
    //     setFormData((prev) => ({ ...prev, [name]: value }));
    // };
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_Data}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true }}
              name="switchone"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_Data}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true }}
              name="switch"
              value={category}
              onChange={(value) => setCategory(value)}
            />
          </div>
        </div>
      </>
    );
  };

  const tagecolor = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
      class: "admin-crop-active-status  is-rounded ",
    },
    {
      label: "PRESUSPENDED",
      value: "PRESUSPENDED",
      class: "admin-crop-presuspended-status is-rounded",
    },
    {
      label: "SUSPENDED",
      value: "SUSPENDED",
      class: "admin-crop-suspended-status  is-rounded ",
    },
    {
      label: "DEACTIVATED",
      value: "DEACTIVATED",
      class: "admin-crop-deactivated-status is-rounded ",
    },
  ];
  // const tageblue = [
  //     {
  //         label: '1085.20',
  //         value: '1085.20',
  //         modalClass: "admin-crop-button-display",
  //         onClick: (data) => {
  //             console.log('data', data);
  //             navigateLink('crops-table')
  //         }
  //     },
  // ]
  const tageblue = (row) => {
    return (
      <div
        className="is-size-7 pointer"
        onClick={() => {
          let id =
            row.ID !== undefined
              ? encodeURIComponent(encrypt_data("" + row.ID))
              : "";
          navigateLink("crops-table/" + id);
        }}
      >
        {row["crop_balance"]}
      </div>
    );
  };
  // const tagecolorbluelight = (row) => {
  //   return (
  //     <div
  //       className="admin-crop-presuspended-status is-size-7 "
  //       onClick={() => navigateLink("active-offers-table")}
  //     >
  //       Active offers
  //     </div>
  //   );
  // };

  // const darkblue = (row) => {
  //   return (
  //     <div
  //       className="admin-prop-button-display "
  //       onClick={() => navigateLink("props-table")}
  //     >
  //       {row["propsline"] || ""}
  //     </div>
  //   );
  // };
  // const darkblue = [
  //     { label: '4400', value: '4400', class: 'has-background-link is-rounded has-text-white' }
  // ]

  const buttons_profile = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-user-circle-o ",
      classList: ["smart-edit-icon is-centered"],
      onClick: openNewForm,
    },
  ];
  const buttons_invoice = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-cart-arrow-down",
      classList: ["smart-delete-icon"],
      onClick: (data) => {
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("invoice-table/" + id);
      },
    },
  ];
  const buttons_like = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-thumbs-up ",
      classList: ["smart-like-icon"],
      onClick: (data) => {
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("likes-list/" + id);
      },
    },
  ];
  // const tagecolorbluelight = [
  //   {
  //     label: "Active Offers",
  //     value: "1",
  //     class: "admin-crop-presuspended-status  is-rounded ",
  //   },
  // ];
  const buttons_rating = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-trophy ",
      classList: ["smart-rating-icon"],
      onClick: (data) => {
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("rate-product-list/" + id);
      },
    },
  ];
  const StatementButton = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-file-text",
      classList: ["admin-crop-presuspended-status"],
      onClick: (data) => {
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("statement/" + id);
      },
    },
  ];

  const Trail = (data) => {
    return (
      <>
        <div
          className="is-flex  smart-rating-icon"
          onClick={() => {
            let id =
              data.ID !== undefined
                ? encodeURIComponent(encrypt_data("" + data.ID))
                : "";
            navigateLink("customer-audit-trail-list/" + id);
          }}
        >
          <img className="image trail-image" src={TRAIL_IMAGE} alt="" />
        </div>
      </>
    );
  };
  const Rating = (data) => {
    return (
      <>
        <div
          className="is-flex  smart-rating-icon"
          onClick={() => {
            let id =
              data.ID !== undefined
                ? encodeURIComponent(encrypt_data("" + data.ID))
                : "";
            navigateLink("rate-product-list/" + id);
          }}
        >
          <img className="image is-16X16" src={RATE_IMAGE} alt="" />
        </div>
      </>
    );
  };
  const buttons_audit_taril = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-battery-full",
      classList: ["smart-audit-icon"],
      onClick: (data) => {
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("customer-audit-trail-list/" + id);
      },
    },
  ];
  const get_table_columns = () => {
    switch (category) {
      case "1":
        return columnsone;
      default:
        return columnstwo;
    }
  };
  const columnsone = [
    { title: "", width: "" },
    { title: "NAME", index: "user_name", width: "" },
    { title: "CROP ID", index: "crop_id" },
    { title: "STATUS", index: "active_status", type: "tags", tags: tagecolor },
    //   { title: "TIRE", index: "titleline" },
    {
      title: "PROFILE",
      index: "profileline",
      type: "buttons",
      buttons: buttons_profile, 
    },
    {
      title:
        // "INVOICES",
        "ORDERS",
      index: "invoiceline",
      type: "buttons",
      buttons: buttons_invoice,
    },
    {
      title:
        // "INVOICES",
        "STATEMENTS",
      index: "invoiceline",
      type: "buttons",
      buttons: StatementButton,
    },
    { title: "CROPs", index: "crop_balance", valueFunction: tageblue },
    // { title: "PROPs", index: "propsline", valueFunction: darkblue },
    {
      title: "LIKES",
      index: "likeline",
      type: "buttons",
      buttons: buttons_like,
    },
    {
      title: "RATINGS",
      index: "ratingsline",
      // type: "buttons",
      // buttons: buttons_rating,
      valueFunction: Rating,
    },
    {
      title: "AUDIT TRAIL",
      index: "audittrailline",
      // type: "buttons",
      // buttons: buttons_audit_taril,
      valueFunction: Trail,
    },
  ];

  //   {
  //     nameline: "Aishu Home Kumer",
  //     cropidline: "100004",
  //     statusline: "1",
  //     titleline: "A",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  //   {
  //     nameline: " Kumer",
  //     cropidline: "100004",
  //     statusline: "2",
  //     titleline: "B",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  //   {
  //     nameline: "Veer ahema Aishu Kumar",
  //     cropidline: "100004",
  //     statusline: "3",
  //     titleline: "C",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  //   {
  //     nameline: "Gopinath jaya kumar",
  //     cropidline: "100004",
  //     statusline: "6",
  //     titleline: "D",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  //   {
  //     nameline: "Hema kumar",
  //     cropidline: "100004",
  //     statusline: "5",
  //     titleline: "E",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  //   {
  //     nameline: "Gopinath",
  //     cropidline: "100004",
  //     statusline: "6",
  //     titleline: "F",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  // ];
  const searchElement = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "holiday_name",
      element: {
        label: "Search CROP ID",
        inputType: "BORDER_LABEL",
        rightIcon: "fa fa-search",
        inputProps: { isFocussed: true },
      },
    },
  ];
  // const filteredData = data.filter((item) => {
  //   return item.name.toLowerCase().includes(filter.toLowerCase()); // Adjust to your data structure
  // });
  // const handleFilterChange = (e) => {
  //   setFilter(e.target.value);
  // };

  const filterFunction = (item) => {
    let output;
    output = filterIncludes(
      item,
      ["crop_id", "first_name"],
      formData.search_value
    );
    return output;
  };

  const searchData = () => {
    const out_data = data.filter(filterFunction);
    setFilteredData(out_data);
  };

  useEffect(() => {
    // Filter data based on the search term and set it to 'filteredData' state
    if (data) {
      if (formData.search_value && formData.search_value.length > 1) {
        searchData();
      } else {
        setFilteredData(data); // console.log("data Details",data)
      }
      if (formData?.search_value?.length < 2) {
        setFormData((prev) => ({
          ...prev,
          search_value: category == 1 ? "C-" : "B-",
        }));
      }
    }
  }, [data, formData]);

  const search_data = (search_value) => {
    // let search_value = formData.search_value;
    if (!search_value || search_value.length < 3) {
      return data;
    }
    const filteredArray = data.filter((item) =>
      item.crop_id.toLowerCase().includes(search_value.toLowerCase())
    );
    return filteredArray;
    //setData(filteredArray);
  };

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    search_data(value);
  };
  const Sesrchbtn = () => {
    return (
      <>
        <div className="holiday-update-form crop-admin-form">
          {/* <SmartSoftInput
            label="Search CROP ID"
            value={formData?.holiday_name || ""}
            rightIcon="fa fa-search"
            inputType="BORDER_LABEL"
            onChange={(value) => handleInputChange("holiday_name", value)}
            errorEnable={formSubmit}
          /> */}
          <SmartSoftInput
            key="text-4"
            value={formData?.search_value || ""}
            onChange={(value) => handleInputChange("search_value", value)}
            label="Search CROP ID"
            inputType="BORDER_LABEL"
            rightIcon="fa fa-search"
            // inputProps= { isFocussed= true }
          />
        </div>
      </>
    );
  };

  const columnstwo = [
    { title: "", width: "" },
    { title: "NAME", index: "first_name" },
    { title: "CROP ID", index: "crop_id" },
    { title: "STATUS", index: "active_status", type: "tags", tags: tagecolor },
    // { title: "TIRE", index: "titleline" },
    {
      title: "PROFILE",
      index: "profileline",
      type: "buttons",
      buttons: buttons_profile,
    },
    {
      title:
        // "INVOICES",
        "ORDERS",
      index: "invoiceline",
      type: "buttons",
      buttons: buttons_invoice,
    },
    {
      title:
        // "INVOICES",
        "STATEMENTS",
      index: "invoiceline",
      type: "buttons",
      buttons: StatementButton,
    },

    { title: "CROPs", index: "crop_balance", valueFunction: tageblue },

    // {
    //   title: "ACTIVE OFFERS",
    //   index: "activeofferline",
    //   valueFunction: tagecolorbluelight,
    // },
    {
      title: "RATINGS",
      index: "ratingsline",
      // type: "buttons",
      buttons: buttons_rating,
      valueFunction: Rating,
    },
    {
      title: "AUDIT TRAIL",
      index: "audittrailline",
      // type: "buttons",
      // buttons: buttons_audit_taril,
      valueFunction: Trail,
    },
  ];

  return (
    <div className="smart-crop-admin-container">
      <div key={"CustomerDataBusinessDataone"} className="columns">
        <div key={"CustomerDataBusinessDatatwo"} className="column">
          <div key={"CustomerDataBusinessDatacardone"} className="card">
            <div className="">
              <div
                key={"CustomerDataBusinessDatathree"}
                className="columns is-vcentered "
              >
                <div
                  key={"CustomerDataBusinessDatacolumnone"}
                  className="column ml-6 is-8 crop-admin-table-title"
                >
                  {MyOfferSwitch()}
                </div>

                <div
                  key={"CustomerDataBusinessDatatwo"}
                  className="column is-flex  "
                >
                  <div className="crop-admin-form">{Sesrchbtn()}</div>
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                                <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>

              <SmartSoftTable
                columns={get_table_columns()}
                data={filteredData}
                tableProps={{ className: "crop-table-layout" }}
                paginationProps={{ pageSize: 10 }}
              />

              {filteredData && filteredData.length < 1 && (
                <NoResultComponents title={"No Results Found"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDataBusinessData;

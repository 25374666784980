import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { DATABASE_URLS, } from "../../../api/Admin/AurhUrls";
import NoResultComponents from "../../../components/site/NoResultComponents";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { decrypt_data } from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";

const LikesTable = () => {
  const { id } = useParams();
 
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  // console.log("offer is",decrypted_id)
  // console.log("decrypted_id =" , decrypted_id);
  const [tabData, setTabData] = useState([]);
  const { setLoading, } = useSiteContext();
  const navigate = useNavigate();

  const loadTableData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { sd_mt_userdb_id: decrypted_id };
    let url = DATABASE_URLS.GET_ALL_LIKES;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const baseNav = "/database";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const columns = [
    { title: "", index: "", width: "10" },
    { title: "SECTOR", index: "sector", width: "35" },
    { title: "OFFER", index: "product", width: "35" },
    // { title: "TITLE", index: "title" },
  ];
  // const data = [{ sector: "Shops", productline: "", titleline: "OnEar Phone" }];

  return (
    <div className="smart-crop-admin-container">
      <div key={"LikesTablecolumnsone"} className="columns">
        <div key={"LikesTablecolumnone"} className="column">
          <div key={"LikesTablecardone"} className="card">
            <div key={"LikesTablecolumnstwo"} className="columns is-vcentered">
              <div
                key={"LikesTablecolumntwo"}
                className="column is-1 crop-admin-table-back"
              >
                <span
                  className="icon"
                  onClick={() => navigateLink("database_management-list")}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i>
                </span>
              </div>
              <div
                key={"LikesTablecolumnthree"}
                className="column is-8 crop-admin-table-title"
              >
                Likes
              </div>
              <div
                key={"LikesTablecolumnfour"}
                className="column is-3 has-text-right crop-table-top-pagination"
              >
                {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                  <i className="fa fa-caret-right" aria-hidden="true"></i> */}
              </div>
            </div>
            <SmartSoftTable
              columns={columns}
              data={tabData}
              tableProps={{ className: "crop-table-layout" }}
            />
            {tabData&&tabData.length<1&&( <NoResultComponents title={"No Results Found"}  />)} 
          </div>
        </div>
      </div>
    </div>
  );
};

export default LikesTable;

import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  SmartSoftForm,
  // SmartSoftInput,
  SmartValid
} from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { ADMIN_OPERATIONS_SERVICE } from "../../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { CROP_ADMIN_LOYALITY } from "../../../../services/ImageService";
import { ALLOW_FLOAT } from "../../../../services/PatternSerivce";
import {
  showAlertAutoClose,
} from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";

const ServiceChargesForm = ({ loadTableData, dataIn }) => {
  // console.log("Data popup:" + dataIn);
  const [formData, setFormData] = useState(dataIn ? dataIn : {});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  const [business, setBusiness] = useState([]);
  // const [bus, setBus] = useState([]);
  //const [type, setType] = useState("password");
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, SericeformElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose("Service Charge Already Exists","error");
      setLoading(false);
    };
    setLoading(true, "Details Submitting....Please Wait");
    let url = ADMIN_OPERATIONS_SERVICE.INSERT;
    if (formData.ID !== undefined) {
      formData["id"] = formData.ID;
      url = ADMIN_OPERATIONS_SERVICE.UPDATE;
    }

    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        //console.log("response form ", response.data);
        loadTableData();
        showAlertAutoClose("Data Saved Successfully", "success");
        closeModal();
        // setUser(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadDropDownData();
  }, []);

  const loadDropDownData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error");
      setLoading(false);
    };
    const subscription = post(
      ADMIN_OPERATIONS_SERVICE.GET_ALL_SELECT,
      handleError
    ).subscribe((response) => {
      console.log(response.data);
      setBusiness(response.data);
      // setTabData(response.data);
      //console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  const UpdatepopupFormValidations = {
    Loyaltyname: [SmartValid.required("Service Charges is Required")],
    service_drop: [SmartValid.required("Business is Required")],
  };

  
  // const state =[
  //   {value:'1', label:"Empty"},
  //   {value:'2', label:"Empty"},
  //   {value:'3', label:"Empty"}]
  const SericeformElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "service_value",
      element: {
        label: "Service Charges (%)",
        isRequired: true,
        placeHolder: "Enter Service charge (%)",
        validations: UpdatepopupFormValidations.Loyaltyname,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
        max: 5,
        pattern: ALLOW_FLOAT,
      },
    },
  ];
  const SericeformElementsone = [
    {
      type: "SELECT_BOX",
      width: "12",
      name: "sd_mt_business_id",
      element: {
        label: "Select Business ",
        isRequired: true,
        validations: UpdatepopupFormValidations.service_drop,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
        options: business,
        // inputProps:{isFocussed: true }
        inputProps: {
          disabled: dataIn && dataIn.ID !== undefined ? true : false,
        },
      },
    },
  ];
  return (
    <>
      <div key={"LoyalityFormFirstcolumns"} className="columns">
        <div
          key={"LoyalityFormFirstcolumn"}
          className=" column is-10 card crop-admin-modal-content"
        >
          <div
            key={"LoyalityFormSecondcolumns"}
            className="columns is-multiline"
          >
            <div className="column is-12 has-text-centered" key="1">
              <p className="crop-admin-form-title">
                {" "}
                {dataIn && dataIn.ID !== undefined
                  ? "Update Service"
                  : "Apply Service Charge"}{" "}
              </p>
            </div>
            <div
              className="column is-12 crop-admin-form smart-soft-lable-color"
              key="2"
            >
              {/* <SmartSoftSelect
                label="Select "
                placeHolder="Business Name"
                inputType="BORDER_LABEL"
                options={business}
                value={formData?.sd_mt_business_id || ""}
                validations={UpdatepopupFormValidations.service_drop}
                onChange={(value) =>
                  handleInputChange("sd_mt_business_id", value)
                }
                inputProps={{ isFocussed: true }}
              /> */}
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={SericeformElementsone}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
                onChange={(value) =>
                  handleInputChange("sd_mt_business_id", value)
                }
              />
              <div className="mt-5">
                {/* <SmartSoftInput
                  label="Service Charges(%)"
                  value={formData?.service_value || ""}
                  inputType="BORDER_LABEL"
                  placeHolder="Enter Service charge(%)"
                  onChange={(value) =>
                    handleInputChange("service_value", value)
                  }
                  errorEnable={formSubmit}
                  pattern={ALLOW_FLOAT}
                  errorUpdate={(value) => handleErrorChange("euserid", value)}
                  validations={UpdatepopupFormValidations.service_value}
                /> */}
                <SmartSoftForm
                  formData={formData}
                  setFormData={handleInputChange}
                  elements={SericeformElements}
                  formSubmit={formSubmit}
                  handleErrorChange={handleErrorChange}
                />
              </div>
            </div>
            <div
              key={"LoyalityFormSecondcolumn"}
              className="column is-8 is-offset-2 has-text-centered"
            >
              <SmartSoftButton
                key="save"
                label={dataIn && dataIn.ID !== undefined ? "UPDATE" : "ADD"}
                // leftIcon="fa-save"
                classList={["is-rounded crop-admin-action-button"]}
                onClick={handleSubmit}
              />
            </div>
            <div
              key={"LoyalityFormThirdcolumn"}
              className="column is-12 has-text-centered"
            >
              <img src={CROP_ADMIN_LOYALITY} alt="" />
            </div>
          </div>
        </div>
        <div key={"LoyalityFormFourthcolumn"} className="column is-2">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceChargesForm;

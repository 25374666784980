import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SmartSoftForm,
  SmartValid,
} from "soft_digi";
import SmartCheckRadio from "soft_digi/dist/forms/SmartCheckRadio";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { ADMIN_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import {
  CROP_ADMIN_LOGIN_IMAGE,
  CROP_LOGO,
} from "../../../services/ImageService";
import {
  showAlertAutoClose,
} from "../../../services/notifyService";
import { getRemeberMe, removeRemeberMe, setRemeberMe } from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";
import "./AdminLogin.css";

const AdminLogin = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, setUser, startSessionAct,refreshUser } = useSiteContext();
  const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const handleRemeberMe=()=>{
    let checkbox_remember_me = formData.checkbox_remember_me !==undefined ?  formData.checkbox_remember_me : "0";
   // console.log("check box rmeber me " , checkbox_remember_me, formData);
    if(checkbox_remember_me==="1"){
        setRemeberMe(formData);
    }else{
        removeRemeberMe()
    }
 }
 const handleRemeberMeLoad=()=>{
  // let remember_data = getRemeberMe();
  let remember_data_original = getRemeberMe();
  const remember_data = { ...remember_data_original };
  delete remember_data.epassword;
  if(remember_data){
      let checkbox_remember_me = remember_data.checkbox_remember_me !==undefined ? remember_data.checkbox_remember_me : "0";
      if(checkbox_remember_me==="1"){
        setFormData(remember_data);
      }
  }
}
useEffect(() => {
handleRemeberMeLoad()
}, []);

  const handleSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData,formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      //showNotification("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Logging in Please Wait....");
    let url = ADMIN_LOGIN_URLS.LOGIN;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Logged in Successfully", "success");
        //showNotification("success", "Logged in Successfully");
        setLoading(false);
        setUser(response.data);
        startSessionAct();
        refreshUser();
        handleRemeberMe()
        navigate("/dashboard");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const AdminLoginFormValidations = {
    Email: [
      SmartValid.required("Please Enter a Valid Email Address"),
       SmartValid.email("Please Enter a Valid Email Address"),
    ],
    password: [SmartValid.required("Password is Required")],
  };
  const passWordPin = () => {
    return (
      <div className="is-flex is-justify-content-space-between ">
        <span>
          <SmartCheckRadio
            options={options_remember_me}
            name="checkbox_remember_me"
            // classList={"admin-login-remember_me"}
            value={formData?.checkbox_remember_me}
            onChange={(value) =>
              handleInputChange("checkbox_remember_me", value)
            }
            isRight={false}
          />
        </span>
        <span>
          <span
            className=" admin-forgot-password"
            href="#"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Password?
          </span>
        </span>
      </div>
    );
  };

  // const EmailValidation = {
  //   email: [SmartValid.email("Please enter a valid Email address")],
  // };
  // const options = [{ value: "1", label: "" }];
  const options_remember_me = [
    {
      value: "1",
      label: "Remember me",
      classList: ["admin-login-remember_me"],
    },
  ];
  const formElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",

      validations: AdminLoginFormValidations.Email,
      element: {
        label: "Email",
        inputType: "BORDER_LABEL",
        leftIcon:
          formData?.login_type === "1" ? "fa fa-user-o" : "fa fa-envelope-o",
          validations:AdminLoginFormValidations.Email,
          autoComplete: "new-password",
        // validations:
        //   formData?.login_type === "1"? AdminLoginFormValidations.cropId : AdminLoginFormValidations.Email,
        disablePaste: true,
      },
      hideFunction: (data) => {
        // return formData?.login_type === "2" ? true : false;
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "epassword",
      element: {
        // placeHolder: "Password",
        label: "Password",
        inputType: "BORDER_LABEL",
        leftIcon: "fa fa-key",
        validations: AdminLoginFormValidations.password,
        disablePaste: true,
        autoComplete: "new-password",
        //  passwordValidator:true
      },
    },
    {
      type: "LABEL",
      labelFunction: passWordPin,
    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "LOG IN",
        // classList: ["is-rounded", "customer-login-action-button"],
        classList: ["admin-login-button"],
        onClick: () => handleSubmit(),
      },
    },
  ];
  return (
    <>
      <div className="admin-main-container">
        <div className="admin-login-main-container">
          <div className="admin-login-container ">
            <div className="columns">
              <div className="column admin-login-main-card ">
                <div className="card  admin-login-card">
                  <div className="card-image has-text-centered">
                    <figure className="image is-flex is-justify-content-center">
                      <img
                        className="image admin-login-image "
                        src={CROP_LOGO}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="admin-login-input">
                    <div className="admin-login-input-details mt-3 ">
                      {/* <SmartSoftInput
                        key="text-4"
                        label="Email"
                        value={formData?.euserid || ""}
                        onChange={(value) =>
                          handleInputChange("euserid", value)
                        }
                        inputType="BORDER_LABEL"
                        leftIcon="fa fa-envelope-o"
                        inputProps={{ isFocussed: true }}
                        errorEnable={formSubmit}
                        errorUpdate={(value) =>
                          handleErrorChange("euserid", value)
                        }
                        validations={AdminLoginFormValidations.email}
                      /> */}
                      <SmartSoftForm
                        formData={formData}
                        setFormData={handleInputChange}
                        elements={formElements}
                        formSubmit={formSubmit}
                        handleErrorChange={handleErrorChange}
                      />
                    </div>
                    {/* <div className="admin-login-input-details mt-4"> */}
                    {/* <SmartSoftInput
                        key="text-4"
                        label="Password"
                        type="password"
                        value={formData?.epassword || ""}
                        onChange={(value) =>
                          handleInputChange("epassword", value)
                        }
                        inputType="BORDER_LABEL"
                        leftIcon="fa fa-key"
                        inputProps={{ isFocussed: true }}
                        errorEnable={formSubmit}
                        errorUpdate={(value) =>
                          handleErrorChange("euserid", value)
                        }
                        validations={AdminLoginFormValidations.password}
                       
                      /> */}
                    {/* </div> */}
                    {/* <div className="admin-login-remember is-flex is-justify-content-space-around mt-3">
                      <div className="remember is-flex  ">
                        <SmartSoftCheckRadioSwitch
                          classList={["smart-admin-login-input"]}
                          options={options}
                          name="checkboxtwo"
                          value={formData?.checkboxtwo || null}
                          onChange={(value) =>
                            handleInputChange("checkboxtwo", value)
                          }
                          isRight={true}
                        />
                        <div className="ml-2">Remember Me</div>
                      </div>
                      <div
                        onClick={() => navigate("forgot-password")}
                        className="admin-forgot-password"
                      >
                        Forgot Password ?
                      </div>
                    </div> */}
                    {/* <div className="admin-button">
                      <SmartSoftButton
                        label="LOG IN"
                        classList={["admin-login-button"]}
                        onClick={handleSubmit}
                      />
                    </div> */}
                  </div>
                </div>

                <div className=" admin-login-image-view is-hidden-mobile">
                  <div className="login-image-view">
                    <div className="admin-login-images ">
                      <img
                        className="admin-image-box is-hidden-mobile"
                        src={CROP_ADMIN_LOGIN_IMAGE}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;

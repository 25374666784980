import { useEffect, useState } from "react";
import { SmartSoftButton, SmartSoftForm } from "soft_digi";
import { USER_URLS } from "../../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";

const Status = ({ loadTableData, dataIn,refreshUser }) => {
  const { ID, active_status } = dataIn;
  const { setLoading, closeModal } = useSiteContext();
  const [selectedStatus, setSelectedStatus] = useState(active_status);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formData, setFormData] = useState(dataIn ? dataIn : {});
  const [formErrors, setFormErrors] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const changeStatus = (status) => {
    if (status === active_status) {
      return false;
    }
    setSelectedStatus(status);
  }
  const handleClick = () => {
    // debugger
    //  if (!formSubmitted) {
    //   setFormSubmitted(true);
      // setSelectedStatus(status);
      setFormSubmit(true);
      setLoading(true, "Details Submitting....Please Wait");
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage,"error" );
        setLoading(false);
      };
      let url = USER_URLS.UPDATE_STATUS;
      let post_data = {
        id: ID,
        active_status: selectedStatus,
        remarks:formData.remarks
      };
      // console.log(post_data)
      // debugger
      const subscription = post(url, post_data, handleError).subscribe(
        (response) => {
          loadTableData();
          showAlertAutoClose("Data Saved Successfully" ,"success" );
          closeModal();
          setLoading(false);
          refreshUser()
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    // }
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const status_arr = [
    {
      label: "Suspended",
      index: "SUSPENDED",
      class: "admin-crop-suspended-status-two",
    },
    {
      label: "Deactivated",
      index: "DEACTIVATED",
      class: "admin-crop-deactivated-status-two",
    },
    {
      label: "Presuspended",
      index: "PRESUSPENDED",
      class: "admin-crop-presuspended-status-two",
    },
    {
      label: "Active",
      index: "ACTIVE",
      class: "admin-crop-active-status-two",
    },
  ];

  const displaySingleStatus = (item) => {
    let class_list = ["admin-status-button"];
    if (item.class) {
      class_list.push(item.class);
    }
    if (item.index === active_status) {
      class_list.push("smart-button-active");
    } else if (item.index === selectedStatus) {
      class_list.push("smart-button-selected"); 
    }
    return (
      <div key={item.index} className="column is-6">
        <div
          className={class_list.join(" ")}
          onClick={() => changeStatus(item.index)}
        >
          <div>{item.label}</div>
        </div>
      </div>
    );
  };

  const formElements = [
    {
      type: "TEXTAREA",
      width: "12",
      name: "remarks",
      element: {
        label: "Remarks",
        // placeHolder: "",
        inputProps: { isFocussed: true },
        inputType: "BORDER_LABEL",
        // isRequired:true,
        // max: 255,
      },
    },
  ]

  return (
    <>
      <div className="is-flex">
        <div
          key={"handleClickcolumnsone"}
          className="columns has-background-white is-multiline"
        >
          {status_arr.map((item) => displaySingleStatus(item))}
          <div className="column is-12  crop-admin-form" key="3">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElements}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
            />
          </div>
  
          <div className="column is-4  is-offset-4 has-text-centered">
          <SmartSoftButton
                label="Update"
                classList={["is-rounded crop-admin-action-button"]}
                onClick={handleClick}
              />
          </div>
        </div>

        <div key={"handleClickcolumnfive"} className="column is-2 ml-4 mb-6">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Status;

import { SmartSoftButton, SmartSoftForm } from "soft_digi";


const CommercialNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  // const [formSubmit, setFormSubmit] = useState(false);
  // const [formErrors, setFormErrors] = useState(false);

  // const CommercialFormValidations = {
  //   BasePrices: [SmartValid.required("Base Prices is Required")],

  //   // numers: [SmartValid.minLength("Number should be minimum 10 digit", 9)],
  // };

  const customer_settings = [
    {
      title: "Base Price for Top Ranking of Offers-Week Days (AUD)",
      name: "crop_base_price_to_wd",
      // validations: CommercialFormValidations.BasePrices,
    },
    {
      title: "Base Price for Top Ranking of Promos-Week Days (AUD)",
      name: "crop_base_price_promo_wd",
    },
    {
      title: "Base Price for Top Ranking of Offers-Weekends (AUD)",
      name: "crop_base_price_to_we",
    },
    {
      title: "Base Price for Top Ranking of Promos-Weekends (AUD)",
      name: "crop_base_price_promo_we",
    },
    {
      title: "Base Price for Top Ranking of Offers-Holidays (AUD)",
      name: "crop_base_price_to_h",
    },   
    
    {
      title: "Base Price for Top Ranking of Promos-Holidays (AUD)",
      name: "crop_base_price_promo_h",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="UPDATE"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

// export default CustomerNotification;
export { CommercialNotification };

import { useNavigate } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";

const BusinessCROPsDetails = () => {
  const navigate = useNavigate();
  const baseNav = "/account-management";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };
  const columns = [{ title: "", index: "" }];

  const data = [{}];

  return (
    <div className="BusinessCROPsDetails">
      <div className="InvoiceDetails">
        <div key={"CropDetailsFirstcolumns"} className="columns">
          <div key={"CropDetailsFirstcolumn"} className="column">
            <div className="card">
              <div
                key={"CropDetailsSecondcolumns"}
                className="columns is-vcentered"
              >
                <div
                  key={"CropDetailsSecondcolumn"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span
                    className="icon"
                    onClick={() => navigateLink("transaction-list")}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"CropDetailsThirdcolumn"}
                  className="column is-8 crop-admin-table-title"
                >
                  Business CROP Details
                </div>

                <div
                  key={"CropDetailsFourthcolumn"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={data}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCROPsDetails;

import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  // SmartSoftCheckRadioSwitch,
  SmartSoftInput,
} from "soft_digi";
import { SERVICES_REQUEST_URLS } from "../../../../api/Services/ServiceUrls";
import SmartSelect from "../../../../components/core/forms/SmartSelect";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { REQUEST_FORM } from "../../../../services/ImageService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";

const ManagementRequestForm = ({ loadTableData, dataIn }) => {
  const [formData, setFormData] = useState(dataIn ? dataIn : {});
  const [formSubmit, setFormSubmit] = useState(false);
  // const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  // const [roles, setRoles] = useState([]);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error", );
      setLoading(false);
    };
    setLoading(true, "Details Submitting....Please Wait");
    let url = SERVICES_REQUEST_URLS.INSERT;
    if (formData.ID !== undefined) {
      formData["id"] = formData.ID;
      formData["request_status"] = formData.request_status.value;
      url = SERVICES_REQUEST_URLS.UPDATE;
    }

    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        loadTableData();
        showAlertAutoClose("success", "Data Saved Successfully");
        closeModal();
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {}, []);

  const options = [
    { value: "OPEN", label: "OPEN" },
    { value: "PROGRESS", label: "IN PROGRESS" },
    { value: "CLOSED", label: "CLOSED" },
  ];

  return (
    <>
      <div className="columns">
        <div className=" column is-10 card crop-admin-modal-content">
          <div className="columns is-multiline">
            <div className="column is-12 has-text-centered" key="1">
              <p className="crop-admin-form-title">
                {" "}
                {dataIn && dataIn.ID !== undefined ? "" : "Update"}{" "}
              </p>
            </div>
            <div
              className="column is-12 is-flex is-multiline crop-admin-form"
              key="2"
            >
              <div className="column is-6">
                <SmartSoftInput
                  label="Request Number"
                  value={formData?.request_id || ""}
                  inputType="BORDER_LABEL"
                  onChange={(value) => handleInputChange("request", value)}
                  errorEnable={formSubmit}
                />
              </div>

              <div className="column is-6">
                <SmartSelect
                  key="text-1"
                  placeHolder="Status"
                  options={options}
                  value={formData?.request_status || ""}
                  onChange={(value) =>
                    handleInputChange("request_status", value)
                  }
                  errorEnable={true}
                />
              </div>
            </div>

            <div className="column is-12  crop-admin-form" key="3">
              <SmartSoftInput
                label="Response"
                value={formData?.request_description || ""}
                inputType="BORDER_LABEL"
                onChange={(value) => handleInputChange("response", value)}
                errorEnable={formSubmit}
              />
            </div>

            <div className="column is-6 is-offset-3 has-text-centered">
              <SmartSoftButton
                key="save"
                label={dataIn && dataIn.ID !== undefined ? "UPDATE" : "UPDATE"}
                // leftIcon="fa-save"
                classList={["is-rounded crop-admin-action-button"]}
                onClick={handleSubmit}
              />
            </div>
            <div className="column is-12 has-text-centered">
              <img src={REQUEST_FORM} alt=""/>
            </div>
          </div>
        </div>
        <div className="column is-2">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagementRequestForm;

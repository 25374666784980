import { useState } from "react";
import {
  SmartSoftButton,
  // SmartSoftInput,
  SmartSoftForm,
  SmartValid,
} from "soft_digi";
import {
  CROP_ADMIN_FORGOT_IMAGE,
  // CROP_ADMIN_LOGIN_IMAGE,
  CROP_LOGO,
} from "../../../services/ImageService";
import "./AdminLogin.css";

import { useNavigate } from "react-router-dom";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { BUSINESS_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";


const AdminForgot = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading } = useSiteContext();
  const navigate = useNavigate();
  const [stage, setStage] = useState("FIRST");

  const goToLogin =()=>{
   return navigate("/");
  }

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const handleLogin = (e) => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Sending OTP....Please Wait");
    let url = BUSINESS_LOGIN_URLS.SEND_ADMIN_OTP;
    const subscription = post(url, formData, handleError, false).subscribe(
      (response) => {
        handleInputChange("otp_id", response.data.otp_id);
        handleInputChange("uid", response.data.uid);
        showAlertAutoClose("OTP Sent Successfully ", "success");
        setLoading(false);
        setStage("SECOND");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleVerify = () => {

    setFormSubmit(true);
    if (!ValidateFormNew(formData, stage2_elements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Verifying the Details....Please Wait");
    // console.log("fdata ", formData);
    let url = BUSINESS_LOGIN_URLS.RESET_PASSWORD_OTP;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        let msg = response?.data?.msg || "Password Reset Succesfully";
        showAlertAutoClose(msg, "success");
        setFormSubmit(false);
        setLoading(false);
        navigate("/login");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
 
  const verifyValidations = {
    otp: [
      SmartValid.required("Otp Required"),
      SmartValid.minLength("Minimum 6 Digit", 6),
    ],
    password: [
      SmartValid.required("Password is Required"),
      SmartValid.minLength("Minimum 4 Digit", 4),
      SmartValid.maxLength("Maximum 15 Digit", 15),
    ],
    cnf_password: [
      SmartValid.required("Password is Required"),
      SmartValid.minLength("Minimum 4 Digit", 4),
      SmartValid.maxLength("Maximum 15 Digit", 15),
      SmartValid.custom((value) => {
        return formData.password !== value
          ? "Password & Confirm Password are not Same"
          : "";
      }),
    ],
  };
  const AdminForgotFormValidations = {
    Email: [
      SmartValid.required("Email Id is Required"),
      SmartValid.email("Please Enter a Valid Email Address"),
    ],
  };
  const forgotPinValidations = {
    otp: [
      SmartValid.required("Otp Required"),
      SmartValid.minLength("Minimum 6 Digit", 6),
    ],
    email: [SmartValid.required("Email Id is Required")],
    cropid: [SmartValid.required("Crop Id is Required")],
    mobile_no: [
      SmartValid.required("Mobile Number is Required"),
      SmartValid.minLength("Number should be minimum 10 digit", 9),
    ],
  };
  const formElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        label: "Enter Your Email",
        inputType: "BORDER_LABEL_FOCUS",
        leftIcon:
          formData?.login_type === "1" ? "fa fa-user-o" : "fa fa-envelope-o",
        validations: AdminForgotFormValidations.Email,
        disablePaste: true,
      },
    },
  ];
  const stage2_elements = [
    {
      type: "OTP",
      width: "12",
      name: "otp_value",
      element: {
        digits: 6,
        rightIcon: "fa-envelope-o",
        inputType: "BORDER_LABEL",
        label: "Enter OTP Received",
        // pattern: ALLOW_NUMERIC,
        validations: forgotPinValidations.otp,
        max: 6,
        autoComplete: "new-password",
        resendOtpFunction: (callback) => {
          handleLogin(null);
          callback();
        },
        validations: [
          SmartValid.required("OTP Required"),
          SmartValid.minLength("Minimum 6 Digit", 6),
        ],
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "password",

      element: {
        placeHolder: "Password",
        validations: verifyValidations.password,
        autoComplete: "new-password",
        // passwordValidator: true,
      },
    },
    {
      type: "PASSWORD",
      width: "12",

      name: "confirm_password",
      element: {
        placeHolder: "Confirm Password",
        validations: verifyValidations.cnf_password,
        autoComplete: "new-password",
        // passwordValidator: true,
      },
    },
    {
      type: "BUTTON",
      width: "12",

      element: {
        label: "Reset",
        classList: ["is-rounded", "admin-login-button"],
        onClick: () => handleVerify(),
      },
    },
  ];

  const stage1 = () => {
    return (
      <>
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
        <div className="admin-button">
          <SmartSoftButton
            label="Submit"
            classList={["admin-login-button"]}
            onClick={() => handleLogin()}
          />
        </div>
      </>
    );
  };
  const stage2 = () => {
    return (
      <>
        <div className="columns">
          <div className="column ">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={stage2_elements}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="admin-login-main-container">
        <div className="admin-login-container">
          <div className="columns">
            <div className="column admin-login-image-view ">
              <div className="login-image-view">
                <div className="admin-forgot-image is-hidden-mobile ">
                  <img
                    className="admin-forgot-images m-0 "
                    src={CROP_ADMIN_FORGOT_IMAGE}
                    alt=""
                  />
                </div>

                <div className="admin-login-form-view">
                  <div className=" admin-forgot-main-card ">
                    <div className="card  admin-forgot-card ">
                      <div className="card-image has-text-centered">
                        <figure class="imageimage-Admin-Forgot is-flex is-justify-content-center mb-4">
                          <img
                            className="image-Admin-Forgot "
                            src={CROP_LOGO}
                            alt="Placeholder"
                          />
                        </figure>
                      </div>
                      <div className="admin-forgot-text">Forgot Password</div>
                      <div className="admin-login-input">
                        <div className="admin-login-input-details mt-2">
                          {/* {stage1()} */}
                          {/* {stage2()} */}
                          {stage === "FIRST" && stage1()}
                          {stage === "SECOND" && stage2()}
                          <div onClick={()=>goToLogin()} className="go-to-login-text ">
                            Go to Login
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminForgot;

import { useEffect, useState } from "react";
import { SmartSoftDate, SmartSoftForm, SmartSoftSelect } from "soft_digi";
// import { ADMIN_CHART } from "../../../services/ImageService";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    LinearScale,
    // Title,
    Tooltip,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { DASHBOARD_URLS } from "../../../api/Admin/Dashboard";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
import { addDays } from "date-fns";
import { changeDateTimeZone,formatDate,changeDateTimeZoneFormat } from "../../../services/core/CommonService";




const RevenueChart = () => {
    const [formData, setFormData] = useState({});
    const [data, setData] = useState([]);
    const { setLoading } = useSiteContext();
    const [cat, setCat] = useState({value:"1",label:"last 7 Days"});
    const [formErrors, setFormErrors] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const currentDate = new Date();
    const [minEndDate, setMinEndDate] = useState();
  
    const handleInputChange = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value })); 
        if (name === "from_date") {
            setMinEndDate(value);
          }
    };

    const loadTableData = (flag=false) => {
        
        setLoading(true, "Loading....Please Wait");
        const handleError = (errorMessage) => {
            showAlertAutoClose(errorMessage, "error");
            setData({});
            setLoading(false);
        };
        let post_data={}
        if(flag){
            post_data={
                from_date:changeDateTimeZoneFormat( formData.from_date,"YYYY-MM-DD"),
                to_date:changeDateTimeZoneFormat( formData.to_date,"YYYY-MM-DD")
            }
        }else{
            post_data={
                from_date:subtractDays(currentDate,7),
                to_date:changeDateTimeZoneFormat( currentDate,"YYYY-MM-DD")
            }
            setFormData(post_data)
        }
        const subscription = post(
            DASHBOARD_URLS.GET_REVENUE_CHAT,
            post_data,
            handleError
        ).subscribe((response) => {
            setData(response.data);
            // console.table("testing", response.data);
            setLoading(false);
        });
        return () => {
            subscription.unsubscribe();
        };
    };
    useEffect(() => {
        loadTableData()
    }, []);
    useEffect(() => {
        loadTableData(true)
    }, [formData]);
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        // Title,
        Tooltip
        // Legend
    );

    function subtractDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() - days); // Subtract days
        let res = changeDateTimeZoneFormat(result,"YYYY-MM-DD")
        return res;
      }

    const handleErrorChange = (name, value) => {
        setFormErrors((prev) => {
          // Create a copy of the previous state
          const updatedFormData = { ...prev };
          // Check if the value is null or empty
          if (value === null || value === "") {
            // If true, remove the property from the updated state
            delete updatedFormData[name];
          } else {
            // Otherwise, update or add the property in the updated state
            updatedFormData[name] = value;
          }
    
          return updatedFormData;
        });
      };
    

    const _data = {
        labels:data.labels,
        datasets: [
            {
                label: "Offers - Sold",
                data: data?.earn,
                backgroundColor: "rgba(0,83,223)",
                borderColor: "rgba(0,83,223)",
                borderWidth: 1,
                
            },
            {
                label: "Offers - Redeemed",
                data: data.redeem,
                backgroundColor: "rgba(88,209,252)",
                borderColor: "rgba(88,209,252)",
                borderWidth: 1,
               
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                // text:'Bar chart with Data and Options'
              
            },

            filler: {
                propagate: true,
            },
        },
        scales: {
            x: {
                ticks: {
                    color: 'red',  // Change this to the desired color for the bottom labels
                    font: {
                        size: 14,        // Optional: Adjust font size
                    }
                }
            },
            y: {
                ticks: {
                    beginAtZero: true,
                    stepSize: 20,
                    min: 0,
                    max: 100,
                     color:'red',
                   //  className:'market-slot-text',
                    callback: function (value, index, values) {
                        return value + " ";
                    },
                },
            },
        },
    };

    const cats = [
        {value:"1",label:"last 7 Days"},
        {value:"2",label:"last Week"},
    ]

    const handleSelect=(value)=>{
        console.log("Value " , value);
        setCat(value);
    }
 const FormElements = [
    {
        type: "DATE",
        width: "6",
        name: "from_date",
        element: {
          label: "From Date",
          classList: ["smart-input-label-width-50"],
          inputProps: { isFocussed: true },
          inputType: "BORDER_LABEL",
        
          dateFormat: "dd/MM/yyyy",
          maxDate:currentDate,
          //isHorizontal: true
        },
      },
      {
        type: "DATE",
        width: "6",
        name: "to_date",
        element: {
          label: "To Date",
  
          classList: ["smart-input-label-width-50"],
          inputProps: { isFocussed: true },
          dateFormat: "dd/MM/yyyy",
          inputType: "BORDER_LABEL",
        
          minDate:minEndDate,
          maxDate:currentDate,
         // isHorizontal: true
        },
      },
 ]
    return (
        <>
            <div
                key={"RevenueOverviewSecondcard"}
                className="smart-crop-dashboard-container card pl-4 pr-5 pt-4 pb-4"
            >

                <div className="columns ">
                    <div className="columns is-flex is-justify-content-space-between is-multiline">
                        <div className="column is-12 admin-dashboard-header ml-4">Revenue Overview</div>
                        <div className="column crop-admin-form">
                            {/* <SmartSoftSelect
                                options={cats}
                                onChange={(value) => handleSelect(value)}
                                value={cat}                               
                                inputType="BORDER_LABEL"
                            /> */}
                    <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={FormElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
          value={formData?.input_Applied || null}
          onChange={(value) => handleInputChange("input_Applied", value)}
        />
                        </div>
                    </div>
                </div>

               { <div className="my-admin-chart"><Bar data={_data} options={options} /></div>}

            </div>
        </>
    );
};

export default RevenueChart;

import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  SmartSoftForm,
  // SmartSoftCheckRadioSwitch,
  // SmartSoftInput,
  // SmartSoftSelect,
  SmartValid
} from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { SERVICES_COMPLAINT_URLS } from "../../../../api/Services/ServiceUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { COMPLAINT_FORM } from "../../../../services/ImageService";
import {
  showAlertAutoClose,
} from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";

const ComplaintForm = ({ loadTableData, dataIn }) => {

  console.log("data in ",dataIn)
  const [formData, setFormData] = useState(dataIn ? dataIn : {});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  // const [roles, setRoles] = useState([]);
  //const [type, setType] = useState("password");

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const handleSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData,formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Details Submitting....Please Wait");
    let url = SERVICES_COMPLAINT_URLS.INSERT;
    formData.complaint_status = formData.complaint_status.value;
    if (formData.ID !== undefined) {
      formData["id"] = formData.ID;
      url = SERVICES_COMPLAINT_URLS.UPDATE;
    }

    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        //console.log("response form ", response.data);
        loadTableData();
        showAlertAutoClose("Data Saved Successfully", "success");
        closeModal();
        // setUser(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {}, []);

  const options = [
    { value: "OPEN", label: "Open" },
    { value: "PROGRESS", label: "In Progress" },
    { value: "CLOSED", label: "Closed" },
  ];
  const popupFormValidations = {
    Response: [SmartValid.required("Response is Required")],
    Status: [SmartValid.required("Status is Required")],
  };
  const complaintElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "complaint_id",
      element: {
        label: "Complaint Number",
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
      },
    },
  ];
  const formElements = [
    {
      type: "TEXT_BOX",
      width: "6",
      name: "complaint_id",
      element: {
        label: "Complaint Number",
        inputType: "BORDER_LABEL",
        inputProps: { disabled: true },
      },
    },
    {
      type: "SELECT_BOX",
      width: "6",
      name: "complaint_status",
      element: {
        options: options,
        inputType: "BORDER_LABEL",
        label:"Status",
        validations:popupFormValidations.Status,
        isRequired:true,
      },
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "complaint_resolution",
      element: {
        label: "Response",
        placeHolder: "Response",
        // validations: popupFormValidations.Response,
        // inputProps: { isFocussed: true },
        inputProps: { isFocussed: true },
        inputType: "BORDER_LABEL",
        // isRequired:true,
        // max: 255,
      },
    },
  ];

  return (
    <>
      <div key={"ComplaintFormcolumnsone"} className="columns">
       
        <div
          key={"ComplaintFormcolumnone"}
          className=" column is-10 card crop-admin-modal-content"
        >
         <div className=" has-text-centered is-size-3 has-text-weight-medium">Update</div>
          <div key={"ComplaintFormcolumnstwo"} className="columns is-multiline">
            <div className="column is-12 has-text-centered" key="1">
              <p className="crop-admin-form-title">
                {" "}
                {dataIn && dataIn.ID !== undefined ? "" : "Update"}{" "}
              </p>
            </div>
            <div
              className="column is-12 is-flex is-multiline crop-admin-form"
              key="2"
            >
              <div key={"ComplaintFormcolumnone"} className="column is-6">
                {/* <SmartSoftInput
                  label="Complaint Number"
                  value={formData?.complaint_id || ""}
                  inputType="BORDER_LABEL"
                  onChange={(value) => handleInputChange("complaint_id", value)}
                /> */}
                {/* <SmartSoftForm
                  formData={formData}
                  setFormData={handleInputChange}
                  elements={complaintElements}
                  formSubmit={formSubmit}
                  handleErrorChange={handleErrorChange}
                /> */}
              </div>

              {/* <div key={"ComplaintFormcolumntwo"} className="column is-6">
                <SmartSelect
                  key="text-1"
                  placeHolder="Status"
                  options={options}
                  value={formData?.complaint_status || ""}
                  onChange={(value) =>
                    handleInputChange("complaint_status", value)
                  }
                  // inputProps= {isFocussed: true }
                  errorEnable={formSubmit}
                  errorUpdate={(value) => handleErrorChange("euserid", value)}
                  validations={popupFormValidations.Status}
                  // inputType= "BORDER_LABEL"
                  // label="Status"
                />
              </div> */}
            </div>

            <div className="column is-12  crop-admin-form" key="3">
           
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
            </div>

            <div
              key={"ComplaintFormcolumnthree"}
              className="column is-6 is-offset-3 has-text-centered"
            >
              <SmartSoftButton
                key="save"
                label={dataIn && dataIn.ID !== undefined ? "UPDATE" : "UPDATE"}
                // leftIcon="fa-save"
                classList={["is-rounded crop-admin-action-button"]}
                onClick={handleSubmit}
              />
            </div>
            <div
              key={"ComplaintFormcolumnfour"}
              className="column is-12 has-text-centered"
            >
              <img src={COMPLAINT_FORM} alt="" />
            </div>
          </div>
        </div>
        <div key={"ComplaintFormcolumnfive"} className="column is-2">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplaintForm;
